<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
      <span data-feather="info"></span>
    </span><button class="close-button close" type="button" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}"
        (click)="onCloseButtonClick()" placement="right">&times;</button>
    </h5>
    <div class="card-body card-body-dialog">
      <form id="frmPastime" [formGroup]="frmPastime" (ngSubmit)="onSubmit(false)">
        <!-- <form >    -->
        <section id="basic-tabs-components">
          <div class="row">
            <div class="col">
              <div class="card-body">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-pill-primary">
                  <li [ngbNavItem]="1" (click)="onTabActive(1)">
                    <a class="font" ngbNavLink>{{'MYPASTIME.TAB_HEADING_PASTIME_DETAILS' | translate}}</a>
                    <ng-template ngbNavContent>
                      <div class="scroll-area"> 
                        <div class="row">
                          <div class="form-group mb-0 col-lg-4 col-sm-12">
                            <app-text-box [type]="'text'" [label]="'MYPASTIME.CONTROL_LABEL_NAME_OF_PASTIME' | translate"
                              [showRequiredSign]="true" [maxlength]="50" #pastime [showInfoIcon]="true" [maxChars]="50"
                              [tooltipInfo]="'MYPASTIME.TOOLTIP_TEXT_PASTIME_NAME'|translate" [position]="'right'"
                              [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="PastimeName"
                              for="PastimeName" formControlName="PastimeName"
                              [classes]="{ 'is-invalid': submitted && f.PastimeName.errors }">
                            </app-text-box>
                            <span *ngIf="submitted && f.PastimeName.errors">
                              <small *ngIf="f.PastimeName.errors.required"
                                class="text-danger">{{'MYPASTIME.ERROR_MESSAGE_PASTIME_REQUIRED'|translate}}</small>
                              <small *ngIf="f.PastimeName.errors.maxlength"
                                class="text-danger">{{'MYPASTIME.ERROR_MESSAGE_MAX_50'|translate}}</small>
                            </span>
                          </div>
                          <div class="col-lg-4 pt-1 col-sm">
                            <div class="form-group">
                              <label class="form-label">{{'MYPASTIME.CONTROL_LABEL_FIELD_DATE_FROM' | translate}}
                                <span ngbTooltip="{{'MYPASTIME.TOOLTIP_TEXT_PASTIME_DATE_FROM'|translate}}" placement="right">
                                  <span data-feather="help-circle"
                                    class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              </label>
                              <div class="input-group" style="width: 100%;">
                                <input id="" name="PolicyStartDate"
                                  [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                                  formControlName="DateFrom" [placeholder]="dateformat"
                                  [classes]="{ 'is-invalid': submitted && f.DateFrom.errors }" required
                                  bsDatepicker #dpstartdate="bsDatepicker" 
                                  class="form-control">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-primary feather icon-calendar"
                                    (click)="dpstartdate.toggle()" type="button" rippleEffect placement="top"
                                    ngbTooltip="{{'MYPASTIME.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
                                </div>
                              </div>
                              <!-- <span *ngIf="submitted && f.PolicyStartDate.invalid">
                                <small class="text-danger"
                                  *ngIf="f.PolicyStartDate.errors.required">{{'MYPASTIME.ERROR_MESSAGE_REQUIRED_POLICY_START_DATE'
                                  | translate}}</small>
                              </span> -->
                            </div>
                          </div>
                          <div class="col-lg-4 pt-1 col-sm">
                            <div class="form-group">
                              <label class="form-label">{{'MYPASTIME.CONTROL_LABEL_FIELD_DATE_TO' | translate}}
                                <span ngbTooltip="{{'MYPASTIME.TOOLTIP_TEXT_PASTIME_DATE_TO'|translate}}" placement="right">
                                  <span data-feather="help-circle"
                                    class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              </label>
                              <div class="input-group" style="width: 100%;">
                                <input id="DateTo" name="DateTo" formControlName="DateTo"
                                  [classes]="{ 'is-invalid': submitted && f.DateTo.errors }" [placeholder]="dateformat"
                                  [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                                  required bsDatepicker #dpenddate="bsDatepicker" 
                                  class="form-control" >
                                <div class="input-group-append">
                                  <button class="btn btn-outline-primary feather icon-calendar"
                                    (click)="dpenddate.toggle()" type="button" rippleEffect placement="top"
                                    ngbTooltip="{{'MYPASTIME.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
                                </div>
                              </div>
                              <!-- <span *ngIf="submitted && f.PolicyEndDate.invalid">
                                <small class="text-danger"
                                  *ngIf="f.PolicyEndDate.errors.required">{{'MYPASTIME.ERROR_MESSAGE_REQUIRED_POLICY_EXPIRATION_DATE'
                                  | translate}}</small>
                              </span> -->
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <label style="color: #323091;">{{'MYPASTIME.CONTROL_LABEL_FIELD_SHORT_DESCRIPTION'|translate}}
                              <span ngbTooltip="{{'MYPASTIME.TOOLTIP_TEXT_PASTIME_SHORT_DESCRIPTION'|translate}}" placement="right">
                                <span data-feather="help-circle"
                                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                            </label>
                        <fieldset class="form-group">
                          <textarea style="width: 100%;" rows="1" [maxlength]="120" class="form-control" formControlName="ShortDescription"  (input)="updateCharacterCount1($event.target.value)"
                            placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                        </fieldset>
                        <p class="text-right mb-0">Character count: {{ characterCount1 }} / {{ maxChars1 }}</p>
                          </div>
                          <div class="col-sm-12">
                            <label style="color: #323091;">{{'MYPASTIME.CONTROL_LABEL_FIELD_LONG_DESCRIPTION'|translate}}
                              <span ngbTooltip="{{'MYPASTIME.TOOLTIP_TEXT_PASTIME_LONG_DESCRIPTION'|translate}}" placement="right">
                                <span data-feather="help-circle"
                                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                            </label>
                        <fieldset class="form-group">
                          <textarea style="width: 100%;" rows="7" [maxlength]="1500" class="form-control" formControlName="LongDescription"  (input)="updateCharacterCount2($event.target.value)"
                            placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                        </fieldset>
                        <p class="text-right ">Character count: {{ characterCount2 }} / {{ maxChars2 }}</p>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
  
                  <li [ngbNavItem]="2" [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(2)" ngbNavLink>{{'MYPASTIME.TAB_HEADING_NOTES' | translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3 pl-3">
                        <div class="col-sm">
                          <i>{{'MYPASTIME.SECTION_TEXT3'|translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <label style="color: #323091;">{{'MYPASTIME.CONTROL_LABEL_NOTES'|translate}}</label>
                        <fieldset class="form-group">
                          <textarea style="width: 100%;" rows="14" [maxlength]="5000" class="form-control" formControlName="Notes"
                          #notes  placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                        </fieldset>
                      </div>
                    </ng-template>
                  </li>
  
                  <li [ngbNavItem]="3"  [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(3)" ngbNavLink>{{'MYPASTIME.TAB_HEADING_UPLOADS' | translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3 pl-3">
                        <div class="col-sm">
                          <i>{{'MYPASTIME.SECTION_TEXT1'|translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <div class="row">
                          <div class="col">
                            <div class="row ml-0">
                            <h4 class="font pt-4" style="width: fit-content;">{{'MYPASTIME.HEADING_TEXT_SELECT_IMAGES' |
                              translate}}</h4>
                              <!-- <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                              <div class="custom-file">
                                <input type="file" id="MultiFileUploaderPastime" (change)="onFileSelected($event)" class="custom-file-input" type="file"
                                ng2FileSelect [uploader]="uploader" multiple id="file-upload-multiple" />
                                <button class="custom-file-label font btn btn-primary custom-file-upload-btn"
                                  for="customFile">Upload</button>
                              </div>
                            </fieldset> -->
                            <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                              <div ngbDropdown class="input-group-append">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  ngbDropdownToggle
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  rippleEffect
                                >
                                  Browse
                                </button>
                                <div ngbDropdownMenu class="dropdown-menu-rightnew" >
                                  <a ngbDropdownItem (click)="openFilemanager()">From uploaded Noosom Images</a>
                                  <a ngbDropdownItem (click)="triggerFileUpload()">From Device File Manager</a>
                                </div>
                              <!-- </div> -->
                            </div>
                                <!-- Hidden File Input -->
                                <div class="custom-file" style="display: none;">
                                  <input type="file" id="MultiFileUploaderPastime" class="custom-file-input" (change)="onFileSelected($event)"
                                    ng2FileSelect [uploader]="uploader" multiple #fileInput />
                                  <label class="custom-file-label font" for="file-upload-multiple">{{'UPLOAD.PLACEHOLDER_CHOOSE_FILES' | translate}}</label>
                                </div>
                              </fieldset>
                          </div>
                            <h5 style="color: #323091;">{{'MYPASTIME.TEXT_FILE_TYPES' | translate}} 
                              image, tiff, bmp, png, jpg, jpeg, pdf, mp4, mpeg, mov, avi, ogv, webm, 3gp, mpg, wmv, flv.
                            </h5>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col overflow-auto">
                            <!-- <div class="d-flex justify-content-between mb-1">
                              <h4 class="font">{{'MYPASTIME.HEADING_TEXT_UPLOAD_QUEUE' | translate}}</h4>
                              <p>{{'MYPASTIME.TEXT_QUEUE_LENGTH' | translate}} {{ uploader?.queue?.length }}</p>
                            </div> -->
  
                            <!-- <table class="table">
                              <thead style="color: #323091;">
                                <tr>
                                  <th width="50%" text-align="left">{{'MYPASTIME.COLUMN_HEADING_NAME' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_SIZE' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_PROGRESS' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_STATUS' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_ACTIONS' | translate}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of uploader.queue">
                                  <td>
                                    {{ item?.file?.name }}
                                  </td>
                                  <td *ngIf="uploader.options.isHTML5" nowrap>
                                    {{ item?.file?.size / 1024 / 1024 | number: '.2' }} {{'MYPASTIME.TEXT_MB' |
                                    translate}}
                                  </td>
                                  <td *ngIf="uploader.options.isHTML5">
                                    <ngb-progressbar type="primary" striped="true" animated="true"
                                      [value]="item.progress"></ngb-progressbar>
                                  </td>
                                  <td class="text-center">
                                    <span *ngIf="item.isSuccess"><i data-feather="check-circle"></i></span>
                                    <span *ngIf="item.isCancel"><i data-feather="slash"></i></span>
                                    <span *ngIf="item.isError"><i data-feather="x-circle"></i></span>
                                  </td>
                                  <td nowrap>
                                    <button type="button" class="btn btn-raised btn-success btn-sm mr-50"
                                      (click)="item.upload()"
                                      [disabled]="item.isReady || item.isUploading || item.isSuccess" rippleEffect>
                                      <span data-feather="upload"></span>
                                    </button>
                                    <button type="button" class="btn btn-raised btn-danger btn-sm" (click)="item.remove();checkQueueLength(uploader?.queue?.length)"
                                      rippleEffect>
                                      <i data-feather="trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
  
                            <div id="">
                              <p class="mt-1 font">{{'MYPASTIME.HEADING_TEXT_QUEUE_PROGRESS' | translate}}</p>
                              <ngb-progressbar class="mb-1" type="primary" [striped]="true" [animated]="true"
                                [value]="uploader.progress"></ngb-progressbar>
                              <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                                (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length"
                                rippleEffect ngbTooltip="Upload All">
                                <span data-feather="upload" class="mr-25"></span> {{'MYPASTIME.BUTTON_TEXT_UPLOAD_ALL' |
                                translate}}
                              </button>
                              
                              <button type="button" class="btn btn-danger mb-1 mb-sm-0" (click)="uploader.clearQueue();checkQueueLength(uploader?.queue?.length)"
                                [disabled]="!uploader.queue.length" rippleEffect>
                                <span data-feather="trash" class="mr-25"></span> {{'MYPASTIME.BUTTON_TEXT_REMOVE_ALL' |
                                translate}}
                              </button>
                            </div> -->
                            <div *ngIf="GalleryItems1.length==0 && DocumentList1.length==0"><img style="padding-left: 34%;" src="assets/images/pages/noimageuploaded.png" height="300px"></div>
                            <div id="animated-thumbnails-gallery">
                              <lightgallery id="AddressGallery" [settings]="settings" [onInit]="onInitGallery1" [onBeforeClose]="onBeforeClose">
                                <a *ngFor="let item of GalleryItems1"
                                [attr.data-video]="item.type === 'Videos' ? item.data : null"
                                [attr.href]="item.type === 'Images' ? item.src : null"
                                [data-poster]="item.type === 'Videos' ? item.thumb :null"
                                 className="gallery-item"
                                data-sub-html="<p>{{item.id}}</p>"
                            >
                                <img *ngIf="item.type=='Images'"
                                    class="img-responsive justify-gallery" loading="lazy"
                                    src="{{item.src}}" alt="{{item.id}}"
                                />
                                <img *ngIf="item.type=='Videos'"
                                    class="img-responsive justify-gallery" loading="lazy"
                                    [src]="item.thumb" alt="{{item.id}}"
                                />
                            </a>
                              </lightgallery>
                              <div>
                                <a  *ngFor="let item of DocumentList1" href={{item.DocumentPath}} target="_blank"  className="gallery-item">
                                <img
                                class="img-responsive justify-gallery" loading="lazy"
                                src="assets/images/icons/document-icon.png" 
                            /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>

                  <li [ngbNavItem]="4" [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(4)" ngbNavLink>{{'MYPASTIME.TAB_HEADING_GALLERY'|translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3">
                        <div class="col-sm" id="addressInfoText">
                          <i>{{'ADDRESS.INFO_TEXT2' | translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <div class="row justify-content-center">
                          <div class="col">
                            <div *ngIf="GalleryItems.length==0"><img style="padding-left: 34%;padding-right: 50%;" src="assets/images/pages/noimageuploaded.png" height="350px"></div>
                            <div id="animated-thumbnails-gallery">
                              <lightgallery id="AddressGallery" [settings]="settings" [onInit]="onInitGallery" [onBeforeClose]="onBeforeClose">
                                <a *ngFor="let item of GalleryItems"
                                [attr.data-video]="item.type === 'Videos' ? item.data : null"
                                [attr.href]="item.type === 'Images' ? item.src : null"
                                [data-poster]="item.type === 'Videos' ? item.thumb :null"
                                 className="gallery-item"
                                data-sub-html="<p>{{item.id}}</p>"
                            >
                                <img *ngIf="item.type=='Images'" [ngbTooltip]="tooltipTemplate" container="body" tooltipClass="my-custom-class"
                                    class="img-responsive justify-gallery" loading="lazy"
                                    src="{{item.src}}" alt="{{item.id}}"
                                />
                                <img *ngIf="item.type=='Videos'" [ngbTooltip]="tooltipTemplate" container="body" tooltipClass="my-custom-class"
                                    class="img-responsive justify-gallery" loading="lazy"
                                    [src]="item.thumb" alt="{{item.id}}"
                                />
                                <ng-template #tooltipTemplate >
                                  <div class="newpop-up">
                                    <p style="font-weight: normal; margin: 0px; padding: 0px">Document Name: <span
                                        style="font-weight: bold;">{{ item?.name }}</span></p>
                                    <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location: <span style="font-weight: bold;">{{ folder?.Location }}</span></p> -->
                                    <p style="font-weight: normal; margin: 0px; padding: 0px">Document Type: <span
                                        style="font-weight: bold;">{{ item?.type}}</span></p>
                                    <p style="font-weight: normal; margin: 0px; padding: 0px">Document Size: <span
                                        style="font-weight: bold;">{{ item?.size}}</span></p>
                                    <p style="font-weight: normal; margin: 0px; padding: 0px">Created Date: <span
                                          style="font-weight: bold;">{{ item?.createdDate|date:dateformat1}}</span></p>    
                                    <p style="font-weight: normal; margin: 0px; padding: 0px">Created Time: <span
                                       style="font-weight: bold;">{{ item?.createdTime|date:timeformat}}</span></p>   
                                    <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location Count: <span style="font-weight: bold;">{{ indexi }}/{{ totalLocations }}</span></p> -->
                                  </div>
                                </ng-template> 
                            </a>
                              </lightgallery>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ngb-pagination #pagination  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="floatingloadmore-button " *ngIf="GalleryItems.length>0" [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Images')"  [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true"
                        [boundaryLinks]="true">
                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                            [class]="'font-weight-bolder'"></span>Prev
                        </ng-template>
                        <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                            [class]="'font-weight-bolder'"></span>
                        </ng-template>
                      </ngb-pagination>
                    </ng-template>
                  </li>
  
                  <li [ngbNavItem]="5" [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(5)" ngbNavLink>{{'MYPASTIME.TAB_HEADING_DOCUMENTS' | translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3 pl-3">
                        <div class="col-sm">
                          <i>{{'MYPASTIME.SECTION_TEXT2'|translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <div class="col">
                          <div class="d-flex justify-content-between mb-1"  style="max-height: 350px;overflow-x: auto;">
                            <table class="table">
                              <thead style="color: #323091;">
                                <tr>
                                  <th text-align="left" width="40%">{{'MYPASTIME.COLUMN_HEADING_NAME' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_SIZE' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_DATE_CREATED' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_TYPE' | translate}}</th>
                                  <th>{{'MYPASTIME.COLUMN_HEADING_ACTIONS' | translate}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let document of DocumentList">
                                  <td>
                                    {{document.DocumentOldName}}
                                  </td>
                                  <td>
                                    {{ document.Size }}
                                  </td>
                                  <td>
                                    {{document.FileCreatedDate | date: 'dd/MM/yyyy' }}
                                  </td>
                                  <td>
                                    {{ document.FileType }}
                                  </td>
                                  <td nowrap>
                                    <button type="button" class="btn btn-raised btn-success btn-sm mr-50" ngbTooltip="{{'MYPASTIME.TOOLTIP_TEXT_BUTTON_DOWNLOAD'|translate}}"
                                      (click)="onDownloadDocument(document)" rippleEffect>
                                      <i data-feather="download"></i>
                                    </button>
                                    <button type="button" class="btn btn-raised btn-danger btn-sm mr-50" ngbTooltip="{{'MYPASTIME.TOOLTIP_TEXT_BUTTON_DELETE'|translate}}"
                                      (click)="onDeleteDocument(document.UserPastimeDocumentId)" rippleEffect>
                                      <i data-feather="trash-2"></i>
                                    </button>
                                    <a href={{document.DocumentPath}} target="_blank"><button type="button" ngbTooltip="{{'MYPASTIME.TOOLTIP_TEXT_BUTTON_VIEW'|translate}}"
                                      class="btn btn-raised btn-warning btn-sm" rippleEffect>
                                      <i data-feather="eye"></i>
                                  </button></a>
                                  </td>
                                </tr>
                              </tbody>
  
                            </table>
                          </div>
                          <ngb-pagination #pagination cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
                            class="floatingloadmore-button" style="top: 85%;" *ngIf="DocumentList.length>0"
                            [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Documents')"
                            [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
                            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                [class]="'font-weight-bolder'"></span>Prev
                            </ng-template>
                            <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                [class]="'font-weight-bolder'"></span>
                            </ng-template>
                          </ngb-pagination>
                          <div id="">
                            <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                              (click)="onDownloadAllDocument() || async"
                              ngbTooltip="{{'MYPASTIME.BUTTON_TEXT_DOWNLOAD_ALL' | translate}}" rippleEffect>
                              <span data-feather="download" class="mr-25"></span> {{'MYPASTIME.BUTTON_TEXT_DOWNLOAD_ALL' |
                              translate}}
                            </button>
                            <button type="button" class="btn btn-danger mb-1 mb-sm-0"
                              (click)="onDeleteAllDocument() || async"
                              ngbTooltip="{{'MYPASTIME.BUTTON_TEXT_REMOVE_ALL' | translate}}" rippleEffect>
                              <span data-feather="trash-2" class="mr-25"></span> {{'MYPASTIME.BUTTON_TEXT_REMOVE_ALL' |
                              translate}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-1"></div>
              </div>
            </div>
          </div>
        </section>
      </form>
  
    </div>
  </div>
  <div class="card-footer d-flex">
    <div class="align">
      <button type="submit" *ngIf="!IsForEdit&&tab<4" class="btn btn-primary mr-1" [disabled]="loading" placement="top"
          ngbTooltip="{{'MYPASTIME.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}" (click)="(onSubmit(false))" ><span
          *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'MYPASTIME.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}
        </button>
  
      <button type="submit" *ngIf="!IsForEdit" (click)="onSubmit(true)" class="btn btn-outline-primary" placement="top"
        ngbTooltip="{{'MYPASTIME.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}">{{'MYPASTIME.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}</button>
  
      <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
        ngbTooltip="{{'MYPASTIME.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit(true)"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>{{'MYPASTIME.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>
  
      <button type="button" *ngIf="(tab<2||IsForEdit)&&!IsForAbandon" class="btn btn-outline-secondary ml-2" placement="top" (click)="onCloseButtonClick()"
        ngbTooltip="{{'MYPASTIME.BUTTON_TEXT_CANCEL'|translate}}">{{'MYPASTIME.BUTTON_TEXT_CANCEL'|translate}}
      </button>
  
        <button type="button" *ngIf="!IsForEdit&&tab>=2 || IsForAbandon" (click)="OnAchievementDeleteClick()" class="btn btn-outline-danger ml-1" placement="top"
          ngbTooltip="{{'MYPASTIME.BUTTON_TEXT_ABANDON'|translate}}">{{'MYPASTIME.BUTTON_TEXT_ABANDON'|translate}}
        </button>
    </div>
    <div class="align" *ngIf="uploaderWarning">{{'MYPASTIME.ERROR_MESSAGE_ATTACHMENT_UPLOADED'|translate}}:{{uploadCount}}/{{uploader?.queue?.length}}</div>
  </div>