import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    INSURANCE: {
      BUTTON_TEXT_SAVE_AND_CLOSE: 'सहेजें और बंद करें',
      BUTTON_TEXT_SAVE_AND_NEXT: 'सहेजें और अगला',
      BUTTON_TEXT_ABANDON: 'छोड़ देना',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      BUTTON_TEXT_UPLOAD_ALL: 'सभी अपलोड करें',
      BUTTON_TEXT_PAUSE_ALL: 'सभी को रोकें',
      BUTTON_TEXT_DOWNLOAD_ALL: 'सभी डाउनलोड',
      BUTTON_TEXT_REMOVE_ALL: 'सभी हटाएं',
      CONTROL_LABEL_INSURANCE_TYPE: 'बीमा प्रकार',
      CONTROL_LABEL_INSURANCE_NAME: 'बीमा का नाम',
      CONTROL_LABEL_POLICY_NUMBER: 'पॉलिसी संख्या',
      CONTROL_LABEL_CARD_NUMBER: 'कार्ड नंबर',
      CONTROL_LABEL_INSURANCE_CONTACT_NUMBER: 'बीमा संपर्क नंबर',
      CONTROL_LABEL_POLICY_START_DATE: 'पॉलिसी प्रारंभ तिथि',
      CONTROL_LABEL_POLICY_EXPIRATION_DATE: 'पॉलिसी समाप्ति तिथि',
      CONTROL_LABEL_MULTIPLE: 'विभिन्न',
      CONTROL_LABEL_NOTES: 'टिप्पणियाँ',
      COLUMN_HEADING_NAME: 'नाम',
      COLUMN_HEADING_SIZE: 'आकार',
      COLUMN_HEADING_PROGRESS: 'प्रगति',
      COLUMN_HEADING_STATUS: 'स्थति',
      COLUMN_HEADING_DATE_CREATED: 'निर्माण की तिथि',
      COLUMN_HEADING_TYPE: 'प्रकार',
      COLUMN_HEADING_ACTIONS: 'कार्रवाई',
      DIALOG_TITLE_ADD_INSURANCE: 'एक बीमा जोड़ें',
      DIALOG_TITLE_EDIT_INSURANCE: 'एक बीमा संपादित करें',
      ERROR_MESSAGE_REQUIRED_INSURANCE_TYPE: 'बीमा प्रकार आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_INSURANCE_NAME: 'बीमा नाम आवश्यक है!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 50 है',
      ERROR_MESSAGE_MAX_LENGTH_100: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 100 है',
      ERROR_MESSAGE_MAX_LENGTH_12: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 12 है',
      ERROR_MESSAGE_REQUIRED_POLICY_NUMBER: 'पॉलिसी संख्या आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_CARD_NUMBER: 'कार्ड संख्या आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_CONTACT_NUMBER: 'संपर्क नंबर आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_POLICY_START_DATE: 'पॉलिसी प्रारंभ दिनांक आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_POLICY_EXPIRATION_DATE: 'पॉलिसी समाप्ति दिनांक आवश्यक है!',
      ERROR_MESSAGE_INVALID_CONTACT_NUMBER: 'अमान्य संपर्क नंबर!',
      HEADING_TEXT_SELECT_IMAGES: 'दस्तावेज़ का चयन करें',
      HEADING_TEXT_UPLOAD_QUEUE: 'अपलोड कतार',
      HEADING_TEXT_QUEUE_PROGRESS: 'कतार प्रगति',
      PLACEHOLDER_SELECT_INSURANCE_TYPE: 'बीमा प्रकार का चयन करें',
      PLACEHOLDER_SELECT_INSURANCE_NAME: 'बीमा नाम का चयन करें',
      PLACEHOLDER_CHOOSE_FILES: 'फ़ाइलों का चयन करें',
      SECTION_TEXT: 'अपने किसी बीमा के बारे में कुछ जानकारी जोड़ें',
      SECTION_TEXT1: 'कुछ दस्तावेज़ का चयन करें। आप उन्हें एक-एक करके अपलोड कर सकते हैं या सभी को एक साथ अपलोड करने का चयन कर सकते हैं।',
      SECTION_TEXT2: 'आपके सभी दस्तावेज़ यहाँ सूची में प्रदर्शित किए जाएंगे। आप उन्हें व्यक्तिगत रूप से डाउनलोड या हटा सकते हैं।',
      SECTION_TEXT3: 'इस बीमा के लिए जो भी विशिष्ट टिप्पणियाँ हैं, वो जोड़ें।',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'क्या आप सुनिश्चित हैं, आप इस बीमा दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'क्या आप सुनिश्चित हैं, आप इन सभी बीमा दस्तावेज़ों को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'क्या आप सुनिश्चित हैं कि आप इस बीमा और इससे जुड़े सभी अनुलग्नकों को हटाना चाहते हैं?',
      SWEET_ALERT_TEXT: "आप इसे पूर्ववत नहीं कर पाएंगे!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'हाँ, मिटा दो!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'रद्द करना',
      SWEET_ALERT_TEXT_DELETED: 'हटा दिया!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'ओके',
      TAB_HEADING_INSURANCE_DETAILS: 'बीमा विवरण',
      TAB_HEADING_UPLOADS: 'अपलोड करें',
      TAB_HEADING_DOCUMENTS: 'दस्तावेज़',
      TAB_HEADING_NOTES: 'टिप्पणियाँ',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर टॉगल करें',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'डाउनलोड',
      TOOLTIP_TEXT_BUTTON_VIEW: 'देखें',
      TOOLTIP_TEXT_BUTTON_DELETE: 'हटाएँ',
      TOOLTIP_TEXT_INSURANCE_TYPE: 'इंश्योरेंस प्रकार चुनें',
      TOOLTIP_TEXT_INSURANCE_NAME: 'अपने दस्तावेज़ों से बीमा का नाम दर्ज करें',
      TOOLTIP_TEXT_POLICY_NUMBER: 'अपने दस्तावेज़ों से नीति संख्या दर्ज करें',
      TOOLTIP_TEXT_CARD_NUMBER: 'अपने दस्तावेज़ों से कार्ड संख्या दर्ज करें',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'बंद करना',
      TOOLTIP_TEXT_INSURANCE_CONTACT_NUMBER: 'अपने दस्तावेज़ों से बीमा संपर्क नंबर दर्ज करें',
      TOOLTIP_TEXT_POLICY_START_DATE: 'अपने दस्तावेज़ों से नीति प्रारंभ तिथि दर्ज करें',
      TOOLTIP_TEXT_POLICY_EXPIRATION_DATE: 'अपने दस्तावेज़ों से नीति समाप्ति तिथि दर्ज करें',
      TOAST_MESSAGE_INSURANCE_ADDED_SUCCESSFULLY: 'बीमा सफलतापूर्वक जोड़ा गया है।',
      TOAST_MESSAGE_INSURANCE_UPDATED_SUCCESSFULLY: 'बीमा सफलतापूर्वक अपडेट किया गया है।',
      TEXT_FILE_TYPES: 'अनुमत फ़ाइल प्रकार:',
      TEXT_QUEUE_LENGTH: 'कतार की लंबाई:',
      TEXT_MB: 'MB',

    },
  }
};
