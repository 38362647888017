import { Role } from './role';

export class User {
  Id: number;
  UserDetailsId: number;
  CountryId: number;
  SexAtBirth: number;
  baseURL:string;
  LifeExpectancyOfFemales: number;
  LifeExpectancyOfMales: number;
  LifeExpectancyAverage: number;
  CurrencyId: number;
  CurrencyName: string;
  CurrencyShortCode: string;
  CurrencySymbol: string;
  CoverImagePath:string;
  Email: string;
  Password: string;
  CreatedDate: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  FullName: string;
  Livesin: string;
  Avatar: string;
  Role: Role;
  NoosomWebURL:string;
  Token?: string;
  TotalMoneySpentOnNoosom:number;
  RoleTitle: string;
  RemainingAttempts: number;
  NoosomTypeId: number;
  Birthdate: Date;
  CdnFolderName: string;
  CountryName: string;
  CountryShortCode: string;
  ProfileImagePath: string;
  Parameter: TblParameters;
  CurrentNoosomPlanId: number;
  CurrentNoosomPricingPlanId: number;
  NoosomCategoryEnumId: number;
  UserSettings: UserSettingsModel;
  TrialExpiredYn?: boolean;
  PublicImageYN:boolean;
  OtpYN:boolean;
}

export class UserSettingsModel {
  UserSettingId: number;
  UserDetailsId: number;
  Theme: string;
  RouteTransition: string;
  MenuLayout: string;
  MenuCollapsedYn: boolean;
  MenuHiddenYn: boolean;
  NavbarColor: string;
  NavbarType: string;
  FooterType: string;
}
export class TblParameters {
  ParameterId: number;
  EmailUserName: string;
  EmailPassword: string;
  EmailHost: string;
  EmailSsl?: boolean;
  EmailPort?: number;
  Salt: string;
  SmsConfigId?: number;
  CurrentWebVersionNumber: string;
  LastWebVersionUpdateDate?: Date;
  CurrentAppVersionNumber: string;
  LastAppVersionUpdateDate?: Date;
  GoogleMapJavascriptKey: string;
  Gstin: string;
  Cin: string;
  KotakMerchantId: string;
  KotakAccessCode: string;
  KotakWorkingKey: string;
  AddressHouseName: string;
  Address1: string;
  Address2: string;
  Address3: string;
  AddressTown: string;
  AddressPostCode: string;
  AddressStateName: string;
  AddressCountryName: string;
  Latitude?: number;
  Longitude?: number;
  CreatedDate: Date;
  CreatedTime: Date;
  CreatedBy: number;
  ChangedDate: Date;
  ChangedTime: Date;
  ChangedBy: number;
  NoosomPaymentGatewayId: number;
  PaginationPageSize: number;
  PaginationPage: number;
  LightGalleryLicenseKey: string;
}


export class storage{
  DiaryOrJournal:any;
  userCategory:any;
  NoosomPlan:any;
}