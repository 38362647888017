import { RecordsType } from "app/enums";
import { QuickPostTypeListOutputModel } from "app/Models/quickpostlist-model.model"
export class QuickPostModel {
    UserQuickPostId: number;
    UserDetailsId: number;
    QuickPostTypeId: number;
    MoodId: number;
    MoodRatingId: number;
    Title: string;
    DiaryDate: Date;
    DiaryTimeFrom: Date;
    DiaryTimeTo: Date;
    PostContent: string;
    Location: string;
    TagCategory: string;
    Latitude: number;
    Longitude: number;
    ViewsCounter: number;
    LikesCounter: number;
    CommentsCounter: number;
    ShareCounter: number;
    LikedByMe:boolean;
}

export class MoodRatingListOutputModel
{
    MoodRatingId: number;
    MoodRatingName: string;
    IconPath: string;
    OrderSequence: number;
}

export class MoodListOutputModel
{
    MoodId:number;
    MoodName:string;
    EmotionPath:string;
    Rating:number;
    OrderSequence:number;
}

export class QuickPostCreateUpdateInputModel {
    UserQuickPostId: number;
    UserDetailsId: number;
    QuickPostTypeId: number;
    MoodId: number;
    MoodRatingId: number;
    Title: string;
    DiaryDate: string;
    DiaryTimeFrom: string;
    DiaryTimeTo:string;
    PostContent: string;
    Location: string;
    TagCategory: string;
    Latitude: number;
    Longitude: number;
    ViewsCounter: number;
    LikesCounter: number;
    CommentsCounter: number;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class TblUserQuickPosts {
    UserQuickPostId: number;
    UserDetailsId: number;
    QuickPostTypeId: number;
    MoodId: number;
    MoodRatingId: number;
    Title: string;
    DiaryDate: string;
    DiaryTimeFrom: string;
    DiaryTimeTo: string;
    PostContent: string;
    Location: string;
    TagCategory: string;
    Latitude: number;
    Longitude: number;
    ViewsCounter: number;
    LikesCounter: number;
    CommentsCounter: number;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class QuickPostLoadDataOutputModel {
    UserQuickPost: TblUserQuickPosts;
    QuickPostTypeList: Array<QuickPostTypeListOutputModel>;
    MoodList: Array<MoodListOutputModel>;
    MoodRatingList: Array<MoodRatingListOutputModel>;
    QuickPostDocumentList: Array<QuickPostDocumentListOutputModel>;
    QuickPostViewList: Array<UserCustomViewModel>;
    QuickPostsCommentsList: Array<UserCustomCommentModel>;

}

export class UserCustomCommentModel
{
    UserQuickPostsCommentId: number;
    ProfileImagePath: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    UserQuickPostId: number;
    Content: string;
    LikesCounter: number;
    UserDetailsId: number;
    CreatedDate: string;
    CreatedTime: string;
}

export class UserCustomViewModel
{
   UserQuickPostsViewId: number;
   ProfileImagePath: string;
   FirstName: string;
   MiddleName: string;
   LastName: string;
   UserQuickPostId: number;
   UserDetailsId: number;
   CreatedDate: string;
   CreatedTime: string;
}

export class QuickPostDocumentListOutputModel {
    DocumentId: number;
    UserQuickPostDocumentId: number;
    UserDetailsId: number;
    UserQuickPostId: number;
    DocumentType: string;
    Size: string;
    DocumentSubType: string;
    DocumentName: string;
    DocumentOldName: string;
    DocumentPath: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class GetUserQuickPostsInputModel
{
    UserDetailsId:number;
    ShowAllYN?:boolean;
}

export class QuickPostCreateUpdateSharingInputModel
{
    UsersQuickPostSharingID: number;
    SharedFromUserDetailsID: number;
    SharedToUserDetailsIDs: number[];
    UserQuickPostID: number;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class DocumentLinkingInputModel
{
    UserDetailsId: number;
    DocumentIds: number[];
    RecordType: RecordsType;
    RecordId: number;
}