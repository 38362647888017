<div>
<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h5 cdkDragHandle class="card-header">{{dialogTitle}}<button class="close-button close" (click)="onCloseButtonClick()" tabindex="-1"
            placement="top" ngbTooltip="{{'ADDRESS.TOOLTIP_TEXT_BUTTON_CLOSE' | translate}}">&times;</button> 
            <button class="btn" [ngbTooltip]="isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'"  (click)="toggleFullscreen()">
                <!-- {{ isFullscreen ? 'Exit Fullscreen' : 'Fullscreen' }} -->  <i data-feather="maximize-2"  (click)="toggleFullscreen()"></i>
            </button>
    </h5>
    <div class="card-body">
        <div class="row">
            <div class="col scroll-area" [ngClass]="{'fullscreen-mode': isFullscreen}">
                <form [formGroup]="frmMapComponent">
                    <div *ngIf="IsSearchTextVisible" class="input-group mb-2">
                        <div class="input-group-prepend" (click)="fetchCurrentLocation()" ngbTooltip="My Current Location">
                          <span class="input-group-text"  id="basic-addon-search1"><i data-feather="map-pin" ngbTooltip="My Current Location"></i></span>
                        </div>
                        <input #SearchOnMapTextBox
                        (keydown.enter)="onSearchClick()"
                          type="text"
                          class="form-control"
                          placeholder="{{'MAP.PLACEHOLDER_SEARCH'|translate}}"
                          aria-label="Search..."
                          formControlName="SearchField"
                          aria-describedby="basic-addon-search1"
                        />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary feather icon-search" (click)="onSearchClick()" ngbTooltip="Search"
                               type="button" rippleEffect></button>
                          </div>  
                      </div>
                      </form>
                <google-map [options]="options" #map [center]="center" width="100%" height="100%">
                    <!-- <map-marker *ngFor="let marker of markers;let i=index" [options]="marker"
                        (mapDragend)="onDragEndMarker($event,i)">
                    </map-marker>
                        <map-polyline [options]="polylineOptions" [path]="polylinePaths"></map-polyline> -->
                </google-map>
            </div>
        </div>
    </div>
    </div>
        <div class="card-footer">
            <div class="align">
                <button *ngIf="IsSaveButtonVisible" type="button" class="btn btn-primary" [disabled]="loading" placement="top"
                    ngbTooltip="{{'ADDRESS.TOOLTIP_TEXT_BUTTON_SELECT' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
                        class="spinner-border spinner-border-sm mr-1"></span>{{'ADDRESS.BUTTON_TEXT_SELECT'|translate}}</button>
                <button type="button" (click)="onCancelClick()" class="btn btn-outline-secondary ml-1" placement="top"
                    ngbTooltip="{{'ADDRESS.TOOLTIP_TEXT_BUTTON_CANCEL' | translate}}">{{'ADDRESS.BUTTON_TEXT_CANCEL'|translate}}</button>
            </div>
        </div>
</div>