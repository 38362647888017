import { Injectable } from '@angular/core';
import { ChangeEmailAddressInputModel, ChangeMobileNumberInputModel, ChangePasswordInputModel, TblUserBillingAddress,UpdateProfileDetailsModel, UserBillingAddressLoadDataInputModel, VerifyMobileNumberInputModel,SubscriptionHistoryModel, DeleteMyAccountInputModel } from './profile/profile.modal'
import { GlobalService } from 'app/Services/global.service';
import { UpdateProfilePictureInputModel } from 'app/about-me/about-me-model.model';
import { VerifyEmailAddressInputModel } from 'app/Models/email-model.model';
import { GetStorageStatisticsInputModel } from 'app/home/home-model.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private globalService: GlobalService) { }

  ChangePassword(changePasswordInputModel: ChangePasswordInputModel) {
    const path = 'api/Account/ChangePassword';
    return this.globalService.PostDataById(changePasswordInputModel, path);
  }

  ResetProfile(updateProfilePictureInputModel: UpdateProfilePictureInputModel) {
    const path = 'api/AboutMe/ResetProfilePicture';
    return this.globalService.PostDataById(updateProfilePictureInputModel, path);
  }

  ResetCoverPicture(updateProfilePictureInputModel) {
    const path = 'api/AboutMe/ResetCoverPicture';
    return this.globalService.PostDataById(updateProfilePictureInputModel, path);
  }

  UpdateProfileDetails(updateProfileDetailsModel:UpdateProfileDetailsModel){
    const path = 'api/Account/UpdateProfileDetails';
    return this.globalService.PostDataById(updateProfileDetailsModel, path);
  }

  ChangeEmailAddress(changeEmailAddressInputModel:ChangeEmailAddressInputModel){
    const path = 'api/Account/ChangeEmailAddress';
    return this.globalService.PostDataById(changeEmailAddressInputModel, path);
  }

  VerifyNewEmailAddress(verifyEmailAddressInputModel :VerifyEmailAddressInputModel ){
    const path = 'api/Account/VerifyNewEmailAddress';
    return this.globalService.PostDataById(verifyEmailAddressInputModel , path);
  }

  ChangeMobileNumber(changeMobileNumberInputModel  :ChangeMobileNumberInputModel  ){ //get otp
    const path = 'api/Account/ChangeMobileNumber';
    return this.globalService.PostDataById(changeMobileNumberInputModel  , path);
  }
  VerifyNewMobileNumber(verifyMobileNumberInputModel  :VerifyMobileNumberInputModel  ){ //confirm otp
    const path = 'api/Account/VerifyNewMobileNumber';
    return this.globalService.PostDataById(verifyMobileNumberInputModel  , path);
  } 

  GetProfileDetailsLoadData(data:number){
    const path = 'api/Account/GetProfileDetailsLoadData'; 
    return this.globalService.PostDataById(data, path);
  }

  GetUserBillingAddressLoadData(userBillingAddressLoadDataInputModel:UserBillingAddressLoadDataInputModel){
    const path = 'api/Account/GetUserBillingAddressLoadData'; 
    return this.globalService.PostDataById(userBillingAddressLoadDataInputModel, path);
  }

  CreateUpdateUserBillingAddresses(tblUserBillingAddress:TblUserBillingAddress){
    const path = 'api/Account/CreateUpdateUserBillingAddresses'; 
    return this.globalService.PostDataById(tblUserBillingAddress, path);
  }

  GetSubscriptionHistory(data:number){
    const path = 'api/Subscription/GetSubscriptionHistory'; 
    return this.globalService.PostDataById(data, path);
  }

  GetStorageStatistics(_data: GetStorageStatisticsInputModel) {
    const path = 'api/Home/GetStorageStatistics'
    return this.globalService.PostDataById(_data, path);
  }

  GetNoosomStorageDetails(_UserDetailsId: number) {
    const path = 'api/Home/GetNoosomStorageDetails'
    return this.globalService.PostDataById(_UserDetailsId, path);
  }

  DeleteMyAccount(deleteMyAccountInputModel:DeleteMyAccountInputModel){
    const path = 'api/Account/DeleteMyAccount'
    return this.globalService.PostDataById(deleteMyAccountInputModel,path);
  }

  DownloadUserInvoicePdf(data:number){
    const path = 'api/Invoice/DownloadUserInvoicePdf'
    return this.globalService.PostDataById(data,path);
  }

  RememberMeForDays(data){
    const path = 'api/Account/RememberMeForDays'
    return this.globalService.PostDataById(data,path);
  }
}
