<li ngbDropdown class="nav-item dropdown-cart mr-25" #dropdown="ngbDropdown">
  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown" [ngbTooltip]="'MENU.TOOLTIP_TEXT_CART' | translate"
    ><span [data-feather]="'shopping-cart'" [class]="'ficon'"></span
    ><span class="badge badge-pill badge-primary badge-up cart-item-count">{{ products?.length }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarCartDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto" (click)="callData()" style="font-size: 1.286rem;">{{'MENU.CONTROL_LABEL_MY_CART' | translate}}</h4>
        <div class="badge badge-pill badge-light-primary"  style="padding: .5em .6em .4em .6em; font-size: 100%">{{ products.length }} {{'MENU.CONTROL_LABEL_ITEMS' | translate}}</div>
      </div>
    </li>
    <!--/ Cart header -->

    <!-- Cart content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <ng-container *ngIf="products.length">
        <ng-container *ngFor="let product of products let i = index">
          <div class="media align-items-center" style="padding: .4rem 0.4rem .4rem 1.28rem;" >
            <img class="d-block rounded mr-1" [src]="product?.NoosomPlanId == 1 ? 'assets/images/pages/cloudg.png' : 'https://sgstorage.noosom.com/NoosomAssets/Images/logo/Orange_logo.svg'" alt="donuts" width="45" />
            <div class="media-body" style="margin-right: 5px;">
              <!-- <button class="btn p-0" (click)="removeItemFromCart(product)">
                <i class="ficon cart-item-remove" data-feather="x"></i>
              </button> -->
              <div class="media-heading" style="width: unset;">
                <h4 class="cart-item-title mb-0">
                  <a class="text-body">{{
                    product.NoosomPricingPlanName
                  }}</a>
                </h4>
                <small class="cart-item-by">{{product?.NoosomPlanId == 1 ? 'STORAGE' : 'PLANS'}}</small>
              </div>
              <!-- <div class="cart-item-qty">
                <div class="input-group touchspin-cart">
                  <core-touchspin [numberValue]="1" [stepValue]="1" [minValue]="1" [maxValue]="10"></core-touchspin>
                </div>
              </div> -->
              <h4 class="cart-item-price" >{{currencySymbol}}{{ product.BasePricePerYear }}</h4>
            </div>
            <div>
             <button class="btn p-0" (click)="removeItemFromCart(product)">
                <i class="ficon " data-feather="x"></i>
              </button></div>
          </div>
        </ng-container>
      </ng-container>
    </li>
    <!--/ Cart content -->

    <!-- Cart footer -->
    <li  class="dropdown-menu-footer">
      <div *ngIf="totalAmount>0" class="d-flex justify-content-between mb-1">
        <h4 class="font-weight-bolder mb-0">Total:</h4>
        <h4 class="font-weight-bolder mb-0" style="color: rgba(50, 48, 145, 1); margin-right: 15px;">{{currencySymbol}}{{totalAmount}}</h4>
      </div>
      <button class="btn btn-primary btn-block" ngbTooltip="{{'MENU.CONTROL_LABEL_CHECKOUT' | translate}}" [disabled]="totalAmount <= 0" (click)="upgradePlan()">{{'MENU.CONTROL_LABEL_CHECKOUT' | translate}}</button>
    </li>
    <!--/ Cart footer -->
  </ul>
</li>
