<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
    <span data-feather="info"></span>
  </span><button class="close-button close" type="button"
      (click)="onCloseButtonClick()" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" placement="right">&times;</button>
  </h5>
  <div class="card-body card-body-dialog">
    <!-- <div class="row py-3 pl-3">
      <div class="col-sm">
        <i>{{'MY_VACCINATION_FORM.SECTION_TEXT'|translate}}</i>
      </div>
    </div> -->
    <form id="frmVaccination" [formGroup]="frmVaccination" (ngSubmit)="onSubmit()">
      <!-- <form >  -->
      <div class="row scroll-area">
        <div class="col-lg-6 col-sm-12">
          <label class="form-label" for="VaccineId" placement="right" container="body" ngbTooltip="{{'MY_VACCINATION_FORM.TOOLTIP_INFO_VACCINATION_NAME'|translate}}">{{'MY_VACCINATION_FORM.CONTROL_LABEL_VACCINATION_NAME' |
            translate}} <i data-feather="info" class="info-icon align-middle mr-sm-25 mr-0"></i><strong> *</strong>
          </label>
          <ng-select [items]="vaccinationLoadDataOutputModel ?. VaccineList" bindLabel="VaccineName" selectOnTab="true"
            bindValue="VaccineId" [ngClass]="{ 'is-invalid': submitted && f.VaccineId.errors }"
            placeholder="{{'MY_VACCINATION_FORM.PLACEHOLDER_SELECT_VACCINATION_NAME'|translate}}"
            (ngModelChange)="onOtherField(vaccinationLoadDataOutputModel ?. VaccineList)" formControlName="VaccineId"
            #Vaccine></ng-select>
          <span *ngIf="submitted && f.VaccineId.errors">
            <small *ngIf="f.VaccineId.errors.required"
              class="text-danger">{{'MY_VACCINATION_FORM.ERROR_MESSAGE_REQUIRED_VACCINATION_NAME'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-6 col-sm-12 form-group" *ngIf="FieldHide">
          <app-text-box [type]="'text'" [maxlength]="50" [label]="'MY_VACCINATION_FORM.CONTROL_LABEL_OTHER'| translate"
            ng-hide="true" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby=""
            formControlName="VaccinationName" for="VaccinationName">
          </app-text-box>
          <!-- <span *ngIf="submitted && f.VaccinationName.errors">
              <small *ngIf="f.VaccinationName.errors.required"
                class="text-danger">{{'MY_VACCINATION_FORM.ERROR_MESSAGE_REQUIRED_OTHER'|translate}}</small>
                <small *ngIf="f.VaccinationName.errors.maxlength"
                class="text-danger">{{'ABOUT_ME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
            </span> -->
        </div>
        <div class="col-lg-6 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" [classes]="{ 'is-invalid': submitted && f.VaccinationType.errors }" [position]="'bottom'" container="body"
            [label]="'MY_VACCINATION_FORM.CONTROL_LABEL_VACCINATION_TYPE'| translate" [showInfoIcon]="true" [tooltipInfo]="'MY_VACCINATION_FORM.TOOLTIP_INFO_VACCINATION_TYPE'|translate"
            [maxlength]="50" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby=""
            formControlName="VaccinationType" for="VaccinationType">
          </app-text-box>
          <span *ngIf="submitted && f.VaccinationType.errors">
            <small *ngIf="f.VaccinationType.errors.required"
              class="text-danger">{{'MY_VACCINATION_FORM.ERROR_MESSAGE_REQUIRED_VACCINATION_TYPE'|translate}}</small>
            <small *ngIf="f.VaccinationType.errors.maxlength"
              class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-sm-6">
          <div class="form-group" style="margin-bottom: 0px;">
            <label class="form-label" for="LastDosageDate" placement="right" ngbTooltip="{{'MY_VACCINATION_FORM.TOOLTIP_INFO_LAST_DOSAGE'|translate}}">{{'MY_VACCINATION_FORM.CONTROL_LABEL_LAST_DOSAGE_DATE' |
              translate}} <i data-feather="info" class="info-icon align-middle mr-sm-25 mr-0"></i>
              </label>
            <div class="input-group border-color">
              <input id="" name="" formControlName="LastDosageDate"
                [class]="{ 'is-invalid': submitted && f.LastDosageDate.errors }" [placeholder]="dateformat"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                [maxDate]="maxFromDate" required bsDatepicker #dpLast="bsDatepicker" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpLast.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'MY_VACCINATION_FORM.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.LastDosageDate.errors">
              <small class="text-danger">{{'MY_VACCINATION_FORM.ERROR_MESSAGE_REQUIRED_LAST_DOSAGE_DATE'
                | translate}}</small>
            </span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" style="margin-bottom: 0px;">
            <label class="form-label" for="NextDosageDate" placement="right" ngbTooltip="{{'MY_VACCINATION_FORM.TOOLTIP_INFO_NEXT_DOSAGE'|translate}}">{{'MY_VACCINATION_FORM.CONTROL_LABEL_NEXT_DOSAGE_DATE' |
              translate}} <i data-feather="info" class="info-icon align-middle mr-sm-25 mr-0"></i>
              </label>
            <div class="input-group">
              <input id="" name="" formControlName="NextDosageDate"
                [class]="{ 'is-invalid': submitted && f.NextDosageDate.errors }" [placeholder]="dateformat"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}" required
                bsDatepicker #dpNext="bsDatepicker" class="form-control"
                (ngModelChange)="OnNextDosageDateChange($event)">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpNext.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'MY_VACCINATION_FORM.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.NextDosageDate.errors">
              <small class="text-danger"
                *ngIf="f.NextDosageDate.errors.required">{{'MY_VACCINATION_FORM.ERROR_MESSAGE_REQUIRED_NEXT_DOSAGE_DATE'
                | translate}}</small>
            </span>
          </div>
        </div>

        <div class="col-sm-12 pt-2">
          <label class="form-label" for="Notes" placement="right" ngbTooltip="{{'MY_VACCINATION_FORM.TOOLTIP_INFO_VACCINATION_NOTES'|translate}}">{{'MY_VACCINATION_FORM.CONTROL_LABEL_NOTES' | translate}} <i data-feather="info" class="info-icon align-middle mr-sm-25 mr-0"></i></label>
          <fieldset>
            <textarea class="col-sm-12 form-control" rows="5" formControlName="Notes"
              placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
          </fieldset>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card-footer">
  <div class="align">
    <button type="submit" *ngIf="!IsForEdit" class="btn btn-primary" placement="top" (click)="onSubmit()"
      ngbTooltip="{{'MY_VACCINATION_FORM.BUTTON_TEXT_ADD_A_VACCINATION'|translate}}">{{'MY_VACCINATION_FORM.BUTTON_TEXT_ADD_A_VACCINATION'|translate}}</button>

    <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
      ngbTooltip="{{'COMMON.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>{{'COMMON.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>

    <button type="button" class="btn btn-outline-secondary ml-2" placement="top" (click)="onCloseButtonClick()"
      ngbTooltip="{{'MY_VACCINATION_FORM.BUTTON_TEXT_CANCEL'|translate}}">{{'MY_VACCINATION_FORM.BUTTON_TEXT_CANCEL'|translate}}</button>
  </div>
</div>