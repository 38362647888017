import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from 'app/about-me/professional/add-professional/i18n/en';
import { locale as french } from 'app/about-me/professional/add-professional/i18n/fr';
import { locale as german } from 'app/about-me/professional/add-professional/i18n/de';
import { locale as portuguese } from 'app/about-me/professional/add-professional/i18n/pt';
import { locale as gujarati } from 'app/about-me/professional/add-professional/i18n/gj';
import { locale as hindi } from 'app/about-me/professional/add-professional/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TblUserHealthProviders, HealthProviderModel, HealthProviderLoadDataOutputModel } from 'app/about-me/about-me-model.model';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { E } from 'assets/extensions/extensions';
import { AuthenticationService } from 'app/auth/service';
import { Status, Enums, MapModalType } from 'app/enums';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { Observable, Subject } from 'rxjs';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { GlobalService } from 'app/Services/global.service';
import { Constant } from 'app/Classes/constant';
import { AddProfessionalService } from 'app/about-me/professional/add-professional/add-professional.service'
import { CountryISO } from 'ngx-intl-tel-input';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import { MapAddressDataModel } from 'app/address/map-address/map-address-data-model.model';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
@Component({
  selector: 'app-add-professional',
  templateUrl: './add-professional.component.html',
  styleUrls: ['./add-professional.component.scss']
})
export class AddProfessionalComponent implements OnInit {
  @ViewChild('speciality') speciality: TextBoxComponent;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  public PlaceholderState;
  public PlaceholderCountry;
  public PlaceholderRegion;
  currentAppLanguage: any;
  private _unsubscribeAll: Subject<any>;
  public frmProfessional: FormGroup;
  public loading = false;
  public submitted = false;
  _data: number = 0;
  public maxlength = 320;
  public characterCount = 0;
  public error = '';
  SelectedCountryISO1: CountryISO;
  SelectedCountryISO2: CountryISO;
  userProfessional: TblUserHealthProviders;
  healthProviderLoadDataOutputModel: HealthProviderLoadDataOutputModel;
  infoTooltip: string;
  dateformat: string;
  constructor(
    private dialogRef: MatDialogRef<AddProfessionalComponent>,
    private addProfessional: AddProfessionalService,
    private _toastrService: CustomToastrService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }

  ngAfterViewInit(): void {
    this.speciality.textBox.nativeElement.focus();
  }
  ngOnInit(): void {
    this.userProfessional = new TblUserHealthProviders();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.PROFESSIONAL.DIALOG_TITLE_ADD_PROFESSIONAL;
    this.infoTooltip= this.currentAppLanguage.data.PROFESSIONAL.SECTION_TEXT;
    this.frmProfessional = this._formBuilder.group({
      Speciality: ['', Validators.required],
      ProfessionalName: ['', Validators.required],
      Address1: [''],
      Address2: [''],
      Address3: [''],
      AddressTown: [],
      StateId: [],
      AddressPostCode: [],
      EmailAddress: [''],
      CountryId: [],
      ContactNumber1: [],
      ContactNumber2: [],
      ContactNumber1CountryCode: [],
      ContactNumber2CountryCode: [],
      RegionId: [],
      LastExamDate: [],
      NextExamDate: [],
      Notes: []
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.PROFESSIONAL.DIALOG_TITLE_EDIT_PROFESSIONAL;
      }
      else {
        //Add Mode
        if (this.authenticationService.currentUserValue.CountryShortCode) {
          this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
          this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
        }
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        // console.log(this._data)
        let response: Observable<any>;
        this.addProfessional.GetUserHealthProviderLoadData(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              //console.log(data);
              if (data.status == Status.Success) {
                this.healthProviderLoadDataOutputModel = <HealthProviderLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));

                // console.log(this.healthProviderLoadDataOutputModel)
                if (this.healthProviderLoadDataOutputModel.UserHealthProvider) {
                  this.userProfessional = this.healthProviderLoadDataOutputModel.UserHealthProvider;
                  this.frmProfessional.patchValue({
                    Speciality: this.userProfessional.Speciality,
                    ProfessionalName: this.userProfessional.ProfessionalName,
                    Address1: this.userProfessional.Address1,
                    Address2: this.userProfessional.Address2,
                    Address3: this.userProfessional.Address3,
                    RegionId: this.userProfessional.RegionId,
                    CountryId: this.userProfessional.CountryId,
                    StateId: this.userProfessional.StateId,
                    AddressTown: this.userProfessional.AddressTown,
                    AddressPostCode: this.userProfessional.AddressPostCode,
                    EmailAddress: this.userProfessional.EmailAddress,
                    ContactNumber1: this.userProfessional.ContactNumber1,
                    ContactNumber2: this.userProfessional.ContactNumber2,
                    LastExamDate: E.ConvertToDate(this.userProfessional.LastExamDate),
                    NextExamDate: E.ConvertToDate(this.userProfessional.NextExamDate),
                    Notes: this.userProfessional.Notes,
                    // SelectedCountryISO :this.userInsurance.ContactNumberCountryCode.toLocaleUpperCase(),
                  });
                  if (this.userProfessional.ContactNumber1CountryCode)
                    this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.userProfessional.ContactNumber1CountryCode);
                  else{
                    this.SelectedCountryISO1 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
                  }
                  if (this.userProfessional.ContactNumber2CountryCode)
                    this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.userProfessional.ContactNumber2CountryCode);
                  else{
                    this.SelectedCountryISO2 = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
                  }
                }

              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );
      }, 500);
      //}
    }
    this.PlaceholderRegion = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_REGION;
    this.PlaceholderCountry = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_COUNTRY;
    this.PlaceholderState = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_STATE;
    this.frmProfessional.get('CountryId').disable();
    // console.log('country')
    this.frmProfessional.get('RegionId').disable();

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  updateCharacterCount(value: string) {
    // console.log(this.maxlength)
     this.characterCount = value ? value.length : 0;
   }

  OnFocusOutEmailAddress(){
    if (
      this.frmProfessional.get('EmailAddress').value !== undefined &&
      this.frmProfessional.get('EmailAddress').value > 0
    ) {
      var emailInput = this.frmProfessional.get('EmailAddress').value;
      var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
     console.log(!emailRegex.test(emailInput),'--------------------- output')
      if (!emailRegex.test(emailInput)) {
        // Invalid email format
        this.frmProfessional.get('EmailAddress').setErrors({ 'pattern': true });
      } else {
        // Valid email format
        this.frmProfessional.get('EmailAddress').setErrors(null); // Set errors to null to remove 'pattern' error
      }
    }
  }

   onSubmit() {
  //   if ( (this.frmProfessional.get('Address1').value !== ('' ) || this.frmProfessional.get('AddressTown').value !== ('' || null) || this.frmProfessional.get('AddressPostCode').value !== ('' || null)) && (typeof this.userProfessional.Latitude === 'undefined' || typeof this.userProfessional.Longitude === 'undefined' || this.userProfessional.Latitude == null || this.userProfessional.Longitude == null) )
  //   {
  //    this.onSearchOnMapClick();
  //    return;
  //  }
    this.frmProfessional.controls['LastExamDate'].setErrors(null);
    this.frmProfessional.controls['NextExamDate'].setErrors(null);
    this.submitted = true;
    if (this.frmProfessional.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userProfessional == null) {
      this.userProfessional = new TblUserHealthProviders();
    }
    this.userProfessional.Speciality = this.frmProfessional.get('Speciality').value;
    this.userProfessional.ProfessionalName = this.frmProfessional.get('ProfessionalName').value;
    this.userProfessional.Address1 = this.frmProfessional.get('Address1').value;
    this.userProfessional.Address2 = this.frmProfessional.get('Address2').value;
    this.userProfessional.Address3 = this.frmProfessional.get('Address3').value;
    this.userProfessional.AddressTown = this.frmProfessional.get('AddressTown').value;
    this.userProfessional.StateId = this.frmProfessional.get('StateId').value;
    this.userProfessional.AddressPostCode = this.frmProfessional.get('AddressPostCode').value;
    this.userProfessional.EmailAddress = this.frmProfessional.get('EmailAddress').value;
    this.userProfessional.CountryId = this.frmProfessional.get('CountryId').value;
    if(this.frmProfessional.get('ContactNumber1').value)
    this.userProfessional.ContactNumber1 = this.frmProfessional.get('ContactNumber1').value.number.replace(/\s/g, "");
    if(this.frmProfessional.get('ContactNumber1').value)
    this.userProfessional.ContactNumber1CountryCode = this.frmProfessional.get('ContactNumber1').value.countryCode.toLocaleLowerCase();
    if (this.frmProfessional.get('ContactNumber2').value) {
      this.userProfessional.ContactNumber2 = this.frmProfessional.get('ContactNumber2').value.number.replace(/\s/g, "");
      this.userProfessional.ContactNumber2CountryCode = this.frmProfessional.get('ContactNumber2').value.countryCode.toLocaleLowerCase();
    }
    else { this.userProfessional.ContactNumber2 = this.frmProfessional.get('ContactNumber2').value; }
    this.userProfessional.RegionId = this.frmProfessional.get('RegionId').value;
    this.userProfessional.LastExamDate = E.GetDateStringOnly(this.frmProfessional.get('LastExamDate').value);
    this.userProfessional.NextExamDate = E.GetDateStringOnly(this.frmProfessional.get('NextExamDate').value);
    this.userProfessional.Notes = this.frmProfessional.get('Notes').value;
    this.userProfessional.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;

    //this.userAddress.CurrentAddressYn = this.frmAddress.get('CurrentAddressYn').value;
    //console.log('this.userAddress');
    //console.log(this.userAddress);
    if (this.frmProfessional.get('CountryId').value) {
      // this.userAddress.AddressCounty = this.addressLoadDataOutputModel.CountryList.filter(c => c.CountryId == this.frmAddress.get('CountryId').value)[0].CountryName;
    }
    if (E.ConvertToNumber(this.userProfessional.UserHealthProviderId) == 0 && this.IsForEdit == false) {
      this.userProfessional.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userProfessional.CreatedDate = E.GetTodayDateStringOnly();
      this.userProfessional.CreatedTime = E.GetTodayTimeStringOnly();
      this.userProfessional.ChangedDate = E.GetTodayDateStringOnly();
      this.userProfessional.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userProfessional.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userProfessional.ChangedDate = E.GetTodayDateStringOnly();
      this.userProfessional.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
    // const path = 'api/UserHealthProvider/CreateUpdateUserHealthProvider';
    // let response: Observable<any>;
    // console.log(this.userProfessional)
    this.addProfessional.CreateUpdateUserHealthProvider(this.userProfessional)
      // response = this.globalService.PostDataById(this.userProfessional, path);
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.PROFESSIONAL.TOAST_MESSAGE_PROFESSIONAL_ADDED_SUCCESSFULLY;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.PROFESSIONAL.TOAST_MESSAGE_PROFESSIONAL_UPDATED_SUCCESSFULLY;
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            this.dialogRef.close();
            this.frmProfessional.reset();
            this.frmProfessional.patchValue({
              LastExamDate: new Date(),
              NextExamDate: new Date()
            });
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );

  }
  onStateChange(event) {
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetCountryRegionId';
      var list = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
           // console.log(JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data)))
            var countryRegion = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            this.frmProfessional.get('CountryId').setValue(countryRegion?.CountryId);
            this.frmProfessional.get('RegionId').setValue(countryRegion?.RegionId);
         //   this.familyMemberLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
          //  if (this.familyMemberLoadDataOutputModel.StateList != null) {
          //    this.frmFamily.get('StateId').enable();
          //  }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_STATE;
      this.frmProfessional.get('RegionId').setValue(null);
      this.PlaceholderRegion = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_REGION;
      this.frmProfessional.get('RegionId').disable();
      this.frmProfessional.get('CountryId').setValue(null);
      this.PlaceholderCountry = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_COUNTRY;
      this.frmProfessional.get('CountryId').disable();
    }
  }
  onCountryChange(event) {
    this.healthProviderLoadDataOutputModel.StateList = null;
    this.frmProfessional.get('StateId').setValue(null);
    this.PlaceholderState = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_STATE;
    this.frmProfessional.get('StateId').disable();
    if (event != null) {
      this.PlaceholderCountry = "";
      this.loadingScreenService.startLoading();
      const path = 'api/State/GetStateList';
      let response: Observable<any>;
      response = this.globalService.PostDataAnonymous(event, path);
      response.subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.healthProviderLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.healthProviderLoadDataOutputModel.StateList != null) {
              this.frmProfessional.get('StateId').enable();
            }
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_COUNTRY;
    }
  }
  sendEmail(){
    window.location.href = "mailto:" +  this.frmProfessional.get('EmailAddress').value;
}
  onRegionChange(event) {
    this.healthProviderLoadDataOutputModel.CountryList = null;
    this.frmProfessional.get('CountryId').setValue(null);
    this.PlaceholderCountry = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_COUNTRY;
    this.frmProfessional.get('CountryId').disable();
    if (event != null) {
      this.PlaceholderRegion = "";
      this.loadingScreenService.startLoading();
      const path = 'api/Country/GetCountryList';
      let response: Observable<any>;
      response = this.globalService.PostDataAnonymous(event, path);
      response.subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.healthProviderLoadDataOutputModel.CountryList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.healthProviderLoadDataOutputModel.CountryList != null) {
              this.frmProfessional.get('CountryId').enable();
            }
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderRegion = this.currentAppLanguage.data.PROFESSIONAL.PLACEHOLDER_REGION;
    }
  }
  OnNextExamDateChange(event) {
    let datefrom = this.frmProfessional.get('LastExamDate').value;
    let dateto = this.frmProfessional.get('NextExamDate').value;
    if (datefrom && dateto) {
      if (datefrom.getTime() > dateto.getTime()) {
        this.frmProfessional.get('LastExamDate').setValue(dateto);
      }
    }
    if (dateto)
      this.maxFromDate = dateto;
  }
   onSearchOnMapClick() {
    if (E.ConvertToString(this.frmProfessional.get('Address1').value) != '' && E.ConvertToString(this.frmProfessional.get('AddressTown').value) != ''
      && E.ConvertToString(this.frmProfessional.get('AddressPostCode').value) != '') {
      this.openDialogMapAddress();
    }
    else {
      this._toastrService.Error(E.ConvertToString( this.warningMessage()+' is required'), Constant.ErrorToastrTitle);
    }
  }
  warningMessage(){
    let emptyFields= '';
    if (this.frmProfessional.get('Address1').value == ('') || (null))
      emptyFields += " " +"Street Name,";
    if (this.frmProfessional.get('AddressTown').value == ('' || null))
      emptyFields += " " +"Town/City,";
    if (this.frmProfessional.get('AddressPostCode').value == ('' || null))
      emptyFields += " " +"Post/Zip Code";

    return emptyFields;
  }

  GetMapSearchString(): string {
    let result = '';
    if (this.frmProfessional.get('Address1').value)
      result += (this.frmProfessional.get('Address1').value + ', ');
    if (this.frmProfessional.get('AddressTown').value)
      result += (this.frmProfessional.get('AddressTown').value + ', ');
    if (this.frmProfessional.get('AddressPostCode').value)
      result += (this.frmProfessional.get('AddressPostCode').value);
    return result;
  }
  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%"
    dialogConfig.height = "55%";
    dialogConfig.data = new MapAddressDataModel(MapModalType.SearchString, this.GetMapSearchString());
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {
        //console.log('Map closed');
        //console.log(result);
        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {
            //console.log(this.userProfessional);
            //console.log(result.data);
            this.userProfessional.Latitude = result.data.lat;
            this.userProfessional.Longitude = result.data.long;
            //console.log(this.userProfessional.Latitude)
          }
        }
        // this.loadData();
      }
    );
  }
  onCloseButtonClick() {
    this.dialogRef.close();
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmProfessional.controls;
  }
}
