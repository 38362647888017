import { Component, ElementRef, Inject, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as english } from 'app/quick-post/quick-post/i18n/en';
import { locale as french } from 'app/quick-post/quick-post/i18n/fr';
import { locale as german } from 'app/quick-post/quick-post/i18n/de';
import { locale as portuguese } from 'app/quick-post/quick-post/i18n/pt';
import { locale as gujarati } from 'app/quick-post/quick-post/i18n/gj';
import { locale as hindi } from 'app/quick-post/quick-post/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbNail from 'lightgallery/plugins/thumbnail';
import { Observable, Subject, Subscription } from 'rxjs';
import pager from 'lightgallery/plugins/pager';
import share from 'lightgallery/plugins/share';
import { QuickPostCreateUpdateInputModel, QuickPostCreateUpdateSharingInputModel, QuickPostDocumentListOutputModel, QuickPostLoadDataOutputModel } from '../quick-post-model.model';
import autoPlay from 'lightgallery/plugins/autoplay';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import comment from 'lightgallery/plugins/comment';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import { ModalDismissReasons, NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { E } from 'assets/extensions/extensions';
import {TimezoneService } from 'app/servicetest/timezone.service'
import { AuthenticationService } from 'app/auth/service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { GlobalService } from 'app/Services/global.service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { Status, Enums, MapModalType, RecordsType, NoosomPlans, NoosomCategoryID } from 'app/enums';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Console } from 'console';
import { retry, takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '@core/services/config.service';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { MapAddressDataModel, MapAddressOutputModel } from 'app/address/map-address/map-address-data-model.model';
import Swal from 'sweetalert2';
import { TagCategoryItemModel } from './quick-post-model.model';
import { TrackersComponent } from 'app/quick-post/trackers/trackers.component'
import { QuickPostService } from './quick-post.service';
import { LightGallery } from 'lightgallery/lightgallery';
import { Highlight } from 'ngx-highlightjs';
import thumbnail from 'lightgallery/plugins/thumbnail';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import vimeoVideo from 'lightgallery/plugins/video';
import videoThumbnail from 'lightgallery/plugins/video';
import { DeleteAttachmentInputModel, DeleteInputModel, DownloadFileInputModel, DownloadFileOutputModel } from 'app/Models/output-model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GeneralService } from 'app/Services/general.service';
import { SelectSyncFileManagerComponent } from 'app/sync-file-manager/select-sync-file-manager/select-sync-file-manager.component';
@Component({
  selector: 'app-quick-post',
  templateUrl: './quick-post.component.html',
  styleUrls: ['./quick-post.component.scss'],
})
export class QuickPostComponent implements OnInit {
  @ViewChild('txtTitle') txtTitle: TextBoxComponent;
  @ViewChild('QuickPostGallery') lightGallery!: LightGallery;
  @ViewChild('nav') nav: NgbNav;
  @ViewChild('fileInput') fileInput;
  @ViewChild('uploaderContent') uploaderContent: TemplateRef<any>;
  _ImageUrlAPI: string = Constant.API + 'api/UserQuickPost/UploadUserQuickPostDocuments';
  public uploader: FileUploader = new FileUploader({
    url: this._ImageUrlAPI,
    isHTML5: true,
    method: "PUT",
    itemAlias: "MultiFileUploaderQuickPost",
    disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunction: (item: FileItem) => item._file,
    formatDataFunctionIsAsync: false,
    maxFileSize: 1 * 1024 * 1024 * 1024,
    //Notes: do not add headers here.
    authToken: `Bearer ` + this.authenticationService.currentUserValue.Token,
    allowedFileType: ["image", "tiff", "bmp", "png", "jpg", "jpeg", "video", "mp4", "mpeg", "mov", "avi", "ogv", "webm", "flv","Images"] // "video", "mp4", "mpeg", "mov", "avi", "ogv", "webm"
  });
  public quillEditorContent = ``;
  uploaderWarning:boolean=false;
  forgotuploadWarning:boolean=false;
  public loading = false;
  public submitted = false;
  currentAppLanguage: any;
  public coreConfig: any;
  public error = '';
  dialogTitle: string;
  Ratings: any = [];
  MoodItem:any=[];
  _data: number = 0;
  public frmQuickpost: FormGroup;
  public IsForEdit: boolean = false;
  public IsForAbandon: boolean = false;
  public PlaceholderQuickPost;
  // Basic Time Picker
  private needRefresh = false;
  private subDeleteQuickPostAtt$?: Subscription;
  public deleteDocumentsInputModel: Array<DeleteAttachmentInputModel> = [];
  GalleryItems: any = [];
  GalleryItems1: any = [];
  trackerId: number = 0;
  private _unsubscribeAll: Subject<any>;
  tracker: TrackersComponent;
  quickpostLoadDataOutputModel: QuickPostLoadDataOutputModel = new QuickPostLoadDataOutputModel();
  userQuickPosts: QuickPostCreateUpdateInputModel;
  deleteInputModel: DeleteInputModel = new DeleteInputModel();
  btnNameJorD: string;
  public downloadFileInputModel: DownloadFileInputModel;
  private subDownloadQuickpostAtt$?: Subscription;
  public tab = 1;
  galleryRefresh:LightGallery;
  DocumentList: QuickPostDocumentListOutputModel[]= [];
  DocumentList1: QuickPostDocumentListOutputModel[]= [];
  UserQuickpostDocument: number[];
  cache: { [key: string]: string } = {};
  infoTooltip: any;
  timeformat;
  dateformat: string;
  uploadCount=0;
  templcstg = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg'))) 
  friendsList = [];
  familyList = [];
  filteredFriendsList = []; // Filtered list of friends for search
  filteredFamilyList = []; // Filtered list of family members for search
  selectAllFriends: number[] = [];
  selectAllFamily: number[] = [];
  userShareInputModel: QuickPostCreateUpdateSharingInputModel;
  combinedUserDetailsIds: number[] =[];
  public pageAdvancedEllipses = 1;
  public pagesNumber;
  page=0;
  dateformat1: string;
  timeformat1: string;
  constructor(
    private dialogRef: MatDialogRef<QuickPostComponent>,
    private _formBuilder: FormBuilder,
    private _coreTranslationService: CoreTranslationService,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    public authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private _toastrService: CustomToastrService,
    private encrDecr: EncrDecrService,
    private dialog: MatDialog,
    private _QuickPostService: QuickPostService,
    private _translateService: TranslateService,
    private elementRef: ElementRef, 
    private renderer: Renderer2,
    private generalService : GeneralService,
    private _router:Router,
    private timeservice:TimezoneService,
        @Inject(MAT_DIALOG_DATA) public data: any
    // public basicDPdata: NgbDateStruct,
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._translateService.onLangChange.subscribe(lang => {
      setTimeout(() => {
        let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
        if (_LangJsonObj)
          this.currentAppLanguage = _LangJsonObj;
          if(this.templcstg){
            console.log(this.templcstg)
            if(this.templcstg.DiaryOrJournal == '2'){
              this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_JOURNAL;
            }
            else{
              this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_DIARY;
            }
          }
          else if(this.authenticationService.currentUserValue.NoosomTypeId === 2){
            this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_JOURNAL;
           }
           else{
             this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_DIARY;
           }
      }, 100);
    })
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.settings.licenseKey=this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey
    this.dateformat=this.globalService.getDateFormat().toUpperCase();
    this.dateformat1=this.globalService.getDateFormat();
    if(this.globalService.getTimeFormat()=='HH:mm'){
      this.timeformat= false;
      this.timeformat1 = this.globalService.getTimeFormat();
    }else if(this.globalService.getTimeFormat()=='hh:mm a'){
     this.timeformat= true;
     this.timeformat1 = this.globalService.getTimeFormat();
    }
  }
  CurrentDateTime: Date = this.timeservice.GetCurrentLocaleDateTime() ;
  
  public spinnerTPtime = { hour: this.CurrentDateTime ? this.CurrentDateTime.getHours() : 0, minute: this.CurrentDateTime ? this.CurrentDateTime.getMinutes() : 0 };
  public spinnerTP = false;
  public spinnerTftime = { hour: this.CurrentDateTime ? this.CurrentDateTime.getHours() : 0, minute: this.CurrentDateTime ? this.CurrentDateTime.getMinutes() : 0 };
  public spinnerTf = false;
  public customTagselected = [];
  public customTags: any[] = [];
  public customTagNames = ['Noosom'];

  ngAfterViewInit(): void {
    this.txtTitle.textBox.nativeElement.focus();
  }

  dataModelChanged(event) {

  }

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      if (this.lightGallery)
      this.galleryRefresh.refresh();
      this.needRefresh = false;
    }
  }
  onBeforeClose = async (detail) => {
    this.galleryRefresh.refresh();
  }
  onInitGallery = (detail): void => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customButton = `<button id="lg-custom-button-delete" style="padding-top:8px" class="lg-icon"><img style="height:30px;" src="assets/images/icons/trash.png"></button>`;
    const tagButton = `<button id="lg-custom-button-download" class="custombutton12 lg-icon"><img style="height:30px" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    // const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    // <img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
      const $toolbar = this.lightGallery.$toolbar;
      $toolbar.append(customButton);
      $toolbar.append(tagButton);
      $toolbar.append(customHelpButton);
      //$toolbar.append(refreshButton);
      document.getElementById("lg-custom-button-delete").addEventListener("click", async () => {
        const idname = detail.instance.galleryItems[detail.instance.index];
        await this.onDeleteDocument(idname.alt)
        let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
         const updatedGalleryItems = galleryItems1.filter((item, index) => index !== detail.instance.index);
         detail.instance.updateSlides(updatedGalleryItems,Math.max(detail.instance.index - 1, 0));
         detail.instance.slidesUpdated = true
         let slidesUpdated = false;
         //detail.instance.refresh();
      });
      document.getElementById("lg-custom-button-download").addEventListener("click", () => {
        const downloadDocument:QuickPostDocumentListOutputModel = new QuickPostDocumentListOutputModel;
        const idname = detail.instance.galleryItems[detail.instance.index];
        if(idname.src){
          downloadDocument.DocumentPath = idname.src;}
          else if(idname.video){
            downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
          }
        downloadDocument.DocumentId=idname.alt;
        this.onDownloadDocument(downloadDocument)
      });
      document.getElementById("lg-custom-button-help").addEventListener("click", () => {
        this.supportFunction();
      });
      // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
      //   detail.instance.refresh();
      // });
  };


  onInitGallery1 = (detail): void => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customButton = `<button id="lg-custom-button-delete1" style="padding-top:8px" class="lg-icon"><img style="height:30px;" src="assets/images/icons/trash.png"></button>`;
    const tagButton = `<button id="lg-custom-button-download1" class="custombutton12 lg-icon"><img style="height:30px" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help1" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    // const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    // <img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
      const $toolbar = this.lightGallery.$toolbar;
      $toolbar.append(customButton);
      $toolbar.append(tagButton);
      $toolbar.append(customHelpButton);
      //$toolbar.append(refreshButton);
      document.getElementById("lg-custom-button-delete1").addEventListener("click", async () => {
        const idname = detail.instance.galleryItems[detail.instance.index];
        await this.onDeleteDocument(idname.alt)
        let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
         const updatedGalleryItems = galleryItems1.filter((item, index) => index !== detail.instance.index);
         detail.instance.updateSlides(updatedGalleryItems,Math.max(detail.instance.index - 1, 0));
         detail.instance.slidesUpdated = true
         let slidesUpdated = false;
         //detail.instance.refresh();
      });
      document.getElementById("lg-custom-button-download1").addEventListener("click", () => {
        const downloadDocument:QuickPostDocumentListOutputModel = new QuickPostDocumentListOutputModel;
        const idname = detail.instance.galleryItems[detail.instance.index];
        if(idname.src){
          downloadDocument.DocumentPath = idname.src;}
          else if(idname.video){
            downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
          }
        downloadDocument.DocumentId=idname.alt;
        this.onDownloadDocument(downloadDocument)
      });
      document.getElementById("lg-custom-button-help1").addEventListener("click", () => {
        this.supportFunction();
      });
      // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
      //   detail.instance.refresh();
      // });
  };

  supportFunction() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/raw-icon-labels.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })

  }

  showUpgradeAlert() {
    Swal.fire({
      title: 'Upgrade Now and Unlock the Full Experience!',
      html: `
        <p>You're currently using a Free Forever version of Noosom, and you're missing out on powerful features designed to make your experience even better.</p>
        
        <strong>Full Access to all premium features.</strong>
        
        <p><strong>Don’t miss out! Upgrade now</strong> and enjoy our premium features.</p>
      `,
      icon: 'info',
      confirmButtonText: 'Upgrade Now',
      // Optional: Add a cancel button
      showCancelButton: true,
      cancelButtonText: 'Maybe Later',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this._router.navigate(['/pages/pricing'])
      } else {
        // console.log('User chose to wait.');
      }
    });
  }

  ngOnInit(): void {
    this.userQuickPosts = new QuickPostCreateUpdateInputModel();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    if(this.templcstg){
      if(this.templcstg.DiaryOrJournal == '2'){
        this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_JOURNAL;
      }
      else{
        this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_DIARY;
      }
    }
    else if(this.authenticationService.currentUserValue.NoosomTypeId === 2){
      this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_JOURNAL;
     }
     else{
       this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_DIARY;
     }
    this.dialogTitle = this.currentAppLanguage.data.QUICKPOST.HEADING_LABEL_ADD_A_QUICKPOST;
    this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT;
    this.frmQuickpost = this._formBuilder.group({
      Title: ['', Validators.required],
      DiaryDate: [new Date(), Validators.required],
      DiaryTimeFrom: [, Validators.required],
      DiaryTimeTo: [, Validators.required],
      Location: [''],
      TagCategory: [],
      PostContent: ['', Validators.required],
      QuickPostTypeId: [, Validators.required],
      MoodId:[],
      MoodRatingId:[],
    });
    if (this._data == 0) {
      setTimeout(() => {
        this.customTagNames.forEach((c, i) => {
          this.customTags = [...this.customTags, { id: i, name: c, tag: true }];
          this.customTagselected = [...this.customTagselected, { id: i, name: c, tag: true }];
        });
      }, 10);
    }

    this.uploader.onAfterAddingFile = (file: any) => {
      if((this.uploader.queue.length>0) && (this.uploader.isUploading==false)){
        this.forgotuploadWarning=true;}
      file.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      this.loadingScreenService.startLoading();
      this.uploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this.authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserQuickPostID', value: E.ConvertToString(this._data) }]
      });
      return fileItem;
    };

    this.uploader.onWhenAddingFileFailed = (fileItem, filter: any, options: any) => {
      let errorMessage: string;
    
      switch (filter.name) {
        case 'fileSize':
          errorMessage = `File '${fileItem.name}' is too large. Maximum file size is ${this.uploader.options.maxFileSize / (1024 * 1024)} MB.`;
          break;
        case 'fileType':
          errorMessage = `File type of '${fileItem.name}' is not allowed. Allowed types are: ${this.uploader.options.allowedFileType.join(', ')}.`;
          break;
        default:
          errorMessage = `File '${fileItem.name}' could not be added due to an unknown error.`;
      }
    
      console.error('File addition failed:', fileItem, errorMessage);
      // Display the error message to the user (e.g., using a toast notification)
    };
    
    this.uploader.response.subscribe(async result => {
      if (result) {
        this.loadingScreenService.startLoading();
        let _result = JSON.parse(result);
        if(this.uploader.progress!=100){
          this.uploaderWarning=true;
          this.loadingScreenService.startLoading();
      }else if(this.uploader.progress==100){
        this.uploaderWarning=false;
        this.forgotuploadWarning=false;
        //this.uploadCount = 0;
        this.loadingScreenService.stopLoading();
      }
        if (_result.status == Status.Success) {
          this._toastrService.Success("File Uploaded Successfully")
          this.uploadCount = this.uploadCount+1;
          let _QuickPostDocument = <QuickPostDocumentListOutputModel>JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, _result.data));
          if (_QuickPostDocument.DocumentSubType.toLocaleLowerCase() == "documents") {
            this.DocumentList.push(_QuickPostDocument);
            this.DocumentList1.push(_QuickPostDocument);
          }
          else if (_QuickPostDocument.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
           await this.AddItemToGallery(_QuickPostDocument.DocumentId, _QuickPostDocument.DocumentPath, _QuickPostDocument.DocumentOldName,_QuickPostDocument.Size ,_QuickPostDocument.DocumentSubType,_QuickPostDocument.CreatedDate, _QuickPostDocument.CreatedTime);
           await this.AddItemToGallery1(_QuickPostDocument.DocumentId, _QuickPostDocument.DocumentPath, _QuickPostDocument.DocumentOldName,_QuickPostDocument.Size ,_QuickPostDocument.DocumentSubType,_QuickPostDocument.CreatedDate, _QuickPostDocument.CreatedTime);
             this.needRefresh = true;
          }
        }
        else if(_result.status == Status.StorageFull){
          this.loadingScreenService.stopLoading();
           this.uploader.cancelAll();
           // this.uploader.removeFromQueue();
           // this.uploader.cancelItem()
           this.uploader.clearQueue()
           Swal.fire({
             title: "Storage Full",
             text: _result.message,
             icon: 'error',
             showCancelButton: true,
             confirmButtonColor: '#7367F0',
             cancelButtonColor: '#E42728',
             confirmButtonText: "Buy More Storage",
             cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
             customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-danger ml-1'
             }
           })
           .then(
             (async (result) => {
               if (result.value) {
                 //this.uploader.clearQueue();
                 this.dialogRef.close(true);
                 this._router.navigate(['/pages/pricing'])
               }
             })
           );
               
         }
      }
      //this.loadingScreenService.stopLoading();
    });

    if (typeof this._data !== 'undefined') {
      if (this._data > 0) {   //Note: API is being called to load region list in add a new address.
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.QUICKPOST.HEADING_LABEL_EDIT_A_QUICKPOST;
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        const path = 'api/UserQuickPost/GetUserQuickPostLoadData';
        let response: Observable<any>;
        var input = {
          recordId: this._data,
          docYN:false
        }
        response = this.globalService.PostDataById(input, path);
        response.subscribe(
          (result) => {
            this.loadingScreenService.stopLoading();
            if (result.status == Status.Success) {
              this.quickpostLoadDataOutputModel = <QuickPostLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
              if (this.quickpostLoadDataOutputModel.QuickPostDocumentList) {
                this.quickpostLoadDataOutputModel.QuickPostDocumentList.forEach(async(doc) => {
                  // console.log(this.quickpostLoadDataOutputModel.QuickPostDocumentList)
                  if (doc.DocumentSubType.toLocaleLowerCase() == "documents") {
                    this.DocumentList.push(doc);
                  }
                 else if (doc.DocumentSubType.toLocaleLowerCase() == ("images") || ("videos")) {
                    //console.log(doc.FileType.toLocaleLowerCase())
                    await this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName, doc.Size,doc.DocumentSubType,doc.CreatedDate, doc.CreatedTime);
                    this.needRefresh = true;
                  }
                });
              }
                  //  console.log(this.quickpostLoadDataOutputModel.UserQuickPost)
              if (this.quickpostLoadDataOutputModel.UserQuickPost) {
                this.userQuickPosts = this.quickpostLoadDataOutputModel.UserQuickPost;
                let _diaryTimefrom = E.ConvertToDate(this.userQuickPosts.DiaryTimeFrom);
                let _diaryTimeto = E.ConvertToDate(this.userQuickPosts.DiaryTimeTo);
                if (this.quickpostLoadDataOutputModel.MoodRatingList) {
                  this.quickpostLoadDataOutputModel.MoodRatingList.forEach((mood)=>{
                  this.AddMoodRating(mood.IconPath,mood.MoodRatingId,mood.MoodRatingName,mood.OrderSequence);
                  });
               }
            
               if(this.quickpostLoadDataOutputModel.MoodList){
                 this.quickpostLoadDataOutputModel.MoodList.forEach((mood)=>{
                   this.AddMoodList(mood.EmotionPath,mood.MoodId,mood.MoodName,mood.OrderSequence,mood.Rating);
                   });
               }
               if(this.userQuickPosts.TagCategory)
                this.customTags = JSON.parse(E.ConvertToString(this.userQuickPosts.TagCategory));
                //console.log('this.customTags');
                //console.log(this.customTags);
                setTimeout(() => {
                  this.customTagselected = [];
                  this.customTags.forEach(x => {
                    if (typeof x.tag === 'undefined' || x.tag == true) {
                      // this.customTagselected.push({ id: x.id, name: x.name, tag: true });
                      // this.customTagselected = [...this.customTagselected];
                      this.customTagselected = [...this.customTagselected, { id: x.id, name: x.name, tag: true }];
                    }
                  });
                  //console.log('this.customTagselected');
                  //console.log(this.customTagselected);
                }, 10);
                let _postContent = this.userQuickPosts.PostContent;
                let modid= 'rating'+this.userQuickPosts.MoodId;
                // console.log(modid);
                let rateingsid = 'bar'+this.userQuickPosts.MoodRatingId;
                if(this.userQuickPosts.QuickPostTypeId==3){
                  this.userQuickPosts.QuickPostTypeId=2
                }
                this.frmQuickpost.setValue({
                  Title: this.userQuickPosts.Title,
                  DiaryDate: E.ConvertToDate(this.userQuickPosts.DiaryDate),
                  DiaryTimeFrom: { hour: _diaryTimefrom ? _diaryTimefrom.getHours() : 0, minute: _diaryTimefrom ? _diaryTimefrom.getMinutes() : 0 },
                  DiaryTimeTo: { hour: _diaryTimefrom ? _diaryTimeto.getHours() : 0, minute: _diaryTimeto ? _diaryTimeto.getMinutes() : 0 },
                  Location: this.userQuickPosts.Location,
                  PostContent: _postContent,
                  TagCategory: this.userQuickPosts.TagCategory,
                  QuickPostTypeId: this.userQuickPosts.QuickPostTypeId,
                  MoodId:modid,
                  MoodRatingId:rateingsid,
                });
               // console.log(this.MoodId)
            // console.log(E.ConvertToString((this.userQuickPosts.MoodId)))
              }
              if (this.quickpostLoadDataOutputModel.QuickPostTypeList.length > 0) {
               // if(this.authenticationService.currentUserValue.CurrentNoosomPlanId == NoosomPlans.Premium_1 || this.authenticationService.currentUserValue.NoosomCategoryEnumId == NoosomCategoryID.Test)
                this.quickpostLoadDataOutputModel.QuickPostTypeList = this.mergeQuickPostTypes(this.quickpostLoadDataOutputModel.QuickPostTypeList)
                this.quickpostLoadDataOutputModel.QuickPostTypeList = this.quickpostLoadDataOutputModel.QuickPostTypeList.map((p) =>
                  p.QuickPostTypeName.toLocaleLowerCase() == 'private' ? p : { ...p }
                );
                let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
                if (_private && _private[0] && this.frmQuickpost.get('QuickPostTypeId').value == null)
                  this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
              }
              this.getUserQuickPostSharingById();
            }
            else {
            }
          },
          (error) => {
            this._toastrService.Error(error, Constant.ErrorToastrTitle);
            this.error = error;
            this.loadingScreenService.stopLoading();
          }
        );
      }, 500);
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCancelClick();
      }
    });
    let inputModel = {
      UserDetailsId: this.authenticationService.currentUserValue.UserDetailsId
    };
    // this.loadHeic2AnyScript();
   this.GetUserConnectionRequests(inputModel)
  }

  loadPage(event,filetype){
    // this.savePosition();
     this.pageAdvancedEllipses
    this.getAttachmentList(filetype,event)
   // setTimeout(() => this.restorePosition(), 0);
   }

   getAttachmentList(FileType?,page?) {
    this.GalleryItems=[]
    //this.needRefresh = true;
    if(page)
    this.page = page
    var getMyAttachmentsInputModel = {
      RecordId: this._data,
      Page:this.page,
      Pagesize:20,
      FileType:FileType,
    //  QuickPostType:QuickPostType.Public,
      GetMetaDataYN:false
    }
    this.loadingScreenService.startLoading();
    this.generalService.GetUserQuickPostDocumentsById(getMyAttachmentsInputModel).subscribe(async result => {
      if (result) {
        if (result.status == Status.Success) {
          let _attachmentlist = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
          if (_attachmentlist) {
            _attachmentlist.forEach(async(doc) => {
              if (doc.DocumentSubType.toLocaleLowerCase() == "documents") {
                this.DocumentList.push(doc);
              }
              else if (doc.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
                await this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName,doc.Size ,doc.DocumentSubType, doc.CreatedDate, doc.CreatedTime);
                this.needRefresh = true;
              }
              if(_attachmentlist[0]?.TotalNumberOfRecords>0)
                this.pagesNumber=_attachmentlist[0]?.TotalNumberOfRecords;
            });
         } 
        this.loadingScreenService.stopLoading();
        }
        else {
          this.loadingScreenService.stopLoading();
          this._toastrService.Error(result.Message, Constant.ErrorToastrTitle);
        }
      }
    });
  }

  checkQueueLength(queuLength){
   // console.log(queuLength)
    if((queuLength>0) && (this.uploader.isUploading==false)){
      this.forgotuploadWarning=true;}
      else if(queuLength==0)
        {this.forgotuploadWarning=false;
          this.uploaderWarning=false;
        }
  }

  mergeQuickPostTypes(quickPostTypes) {
    return quickPostTypes.map(postType => {
        if (postType.QuickPostTypeId === 2) {
            // Merge "Family" and "Friends" into "Shared"
            return {
                QuickPostTypeId: 2,
                QuickPostTypeName: "Shared",
                OrderSequence: 2
            };
        } else if (postType.QuickPostTypeId === 3) {
            // Exclude the "Friends" entry by returning null
            return null;
        }
        // Return all other post types as they are
        return postType;
    }).filter(postType => postType !== null); // Remove null entries
}

  loadData(){
      const path = 'api/UserQuickPost/GetUserQuickPostLoadData';
      let response: Observable<any>;
      var input = {
        recordId: this._data,
        docYN:false
      }
      response = this.globalService.PostDataById(input, path);
      response.subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          if (result.status == Status.Success) {
            this.quickpostLoadDataOutputModel = <QuickPostLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            if (this.quickpostLoadDataOutputModel.UserQuickPost) {
              if (this.quickpostLoadDataOutputModel.MoodRatingList) {
                this.quickpostLoadDataOutputModel.MoodRatingList.forEach((mood)=>{
                this.AddMoodRating(mood.IconPath,mood.MoodRatingId,mood.MoodRatingName,mood.OrderSequence);
                });
             }
          
             if(this.quickpostLoadDataOutputModel.MoodList){
               this.quickpostLoadDataOutputModel.MoodList.forEach((mood)=>{
                 this.AddMoodList(mood.EmotionPath,mood.MoodId,mood.MoodName,mood.OrderSequence,mood.Rating);
                 });
                 let modid= 'rating'+this.userQuickPosts.MoodId;
                let rateingsid = 'bar'+this.userQuickPosts.MoodRatingId;
                this.frmQuickpost.get('MoodId').setValue(modid)
                this.frmQuickpost.get('MoodRatingId').setValue(rateingsid)
             }}
             if (this.quickpostLoadDataOutputModel.QuickPostTypeList.length > 0) {
              this.quickpostLoadDataOutputModel.QuickPostTypeList = this.mergeQuickPostTypes(this.quickpostLoadDataOutputModel.QuickPostTypeList)
              this.quickpostLoadDataOutputModel.QuickPostTypeList = this.quickpostLoadDataOutputModel.QuickPostTypeList.map((p) =>
                p.QuickPostTypeName.toLocaleLowerCase() == 'private' ? p : { ...p }
              );
              let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
              if (_private && _private[0] && this.frmQuickpost.get('QuickPostTypeId').value == null)
                this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
            }
           // return this.quickpostLoadDataOutputModel;
          }
          else {
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loadingScreenService.stopLoading();
        }
      );
   
  }

  // handleCustomLink() {
  //   const url = prompt('Enter URL:'); // For demonstration purposes
  //   if (url) {
  //     const protocol = /^https?:\/\//i;
  //     const url1 = protocol.test(url) ? url : `http://${url}`;
  //     window.open(url1, '_blank');
  //   }
  // }

  onTabActive(tabNumber:number){
    if(tabNumber==1){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT;}
    else if(tabNumber==2){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT3;}
    else if(tabNumber==3){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT1;
     // this.getAttachmentList('Images');
    }
    else if(tabNumber==4){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT2;
      this.getAttachmentList('Images');
    }
    this.tab=tabNumber;
  }
 
  onSubmit(tabs: boolean) {
    if(this.uploaderWarning){
      Swal.fire({
        title: 'Warning',
        text: 'Your documents or files are still uploading. Please note that any remaining files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploaderWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
     if(this.forgotuploadWarning){
      Swal.fire({
        title: 'Warning',
        text: 'You have some documents or files to upload. Please note that all your files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to submit the form?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.forgotuploadWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
    this.submitted = true;
    if (this.frmQuickpost.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userQuickPosts == null) {
      this.userQuickPosts = new QuickPostCreateUpdateInputModel();
    }
    this.userQuickPosts.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    this.userQuickPosts.Title = this.frmQuickpost.get('Title').value;
    this.userQuickPosts.DiaryDate = E.GetDateStringOnly(this.frmQuickpost.get('DiaryDate').value);
    this.userQuickPosts.DiaryTimeFrom = E.GetMinDateWithTime(this.spinnerTPtime.hour, this.spinnerTPtime.minute).toISOString();// '1900-01-01 10:30:00.000';
    this.userQuickPosts.DiaryTimeTo   = E.GetMinDateWithTime(this.spinnerTftime.hour, this.spinnerTftime.minute).toISOString();// '1900-01-01 10:30:00.000';
    this.userQuickPosts.Location = this.frmQuickpost.get('Location').value;
    this.userQuickPosts.QuickPostTypeId = this.frmQuickpost.get('QuickPostTypeId').value;
  //  console.log( this.userQuickPosts.DiaryTimeFrom)
  if(this.frmQuickpost.get('QuickPostTypeId').value == 1 || this.frmQuickpost.get('QuickPostTypeId').value == 4){
    this.selectAllFamily = [];
    this.selectAllFriends = [];
  }
  this.share() 
    this._QuickPostService.getTracker().subscribe(x => {
      if(x)
      this.userQuickPosts.MoodId = x;
    else
    this.userQuickPosts.MoodId = 1
    });
    
    this._QuickPostService.getRating().subscribe(x => {
      if(x)
      this.userQuickPosts.MoodRatingId = x;
      else
      this.userQuickPosts.MoodRatingId = 3
    });


    let _tags = JSON.stringify(this.customTags);
    this.userQuickPosts.TagCategory = _tags;
    this.userQuickPosts.PostContent = E.ConvertToString(this.frmQuickpost.get('PostContent').value);
    if (E.ConvertToNumber(this.userQuickPosts.UserQuickPostId) == 0 && this.IsForEdit == false) {
      this.userQuickPosts.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userQuickPosts.CreatedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.CreatedTime = E.GetTodayTimeStringOnly();
      this.userQuickPosts.ChangedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userQuickPosts.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userQuickPosts.ChangedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.ChangedTime = E.GetTodayTimeStringOnly();
    }
    if (this.tab <= 2 ||this.IsForEdit) {
    this.loading = true;
    this.loadingScreenService.startLoading();
    const path = 'api/UserQuickPost/CreateUpdateUserQuickPost';
    let response: Observable<any>;
    response = this.globalService.PostDataById(this.userQuickPosts, path);
    response.subscribe(
      (result) => {
        this.loading = false;
        this.loadingScreenService.stopLoading();
        var message = ""; var errormessage = "";
        if (result.status == Status.RecordAdded) {
          message = this.currentAppLanguage.data.QUICKPOST.TOAST_MESSAGE_QUICKPOST_ADDED_SUCCESSFULLY.replace('Quick Post', this.btnNameJorD+' Entry');
          this.tab = this.tab + 1;
            this.nav.select(this.tab)
            const jsonString = this.encrDecr.get(Constant.EncrDecrKey, result.data);
            const parsedObject = JSON.parse(jsonString);
            this._data = parsedObject.Data.UserQuickPostId
            this.userQuickPosts.UserQuickPostId=parsedObject.Data.UserQuickPostId;
            this.loadData();
            this.setCreateUpdateShare();
            this.IsForAbandon=true;
        }
        else if (result.status == Status.RecordUpdated) {
          message = this.currentAppLanguage.data.QUICKPOST.TOAST_MESSAGE_QUICKPOST_UPDATED_SUCCESSFULLY.replace('Quick Post', this.btnNameJorD+' Entry');
          this.setCreateUpdateShare();
          if(!tabs)
          {this.tab=this.tab+1
          this.nav.select(this.tab)
        }
        }
        else {
          errormessage = E.ConvertToString(result.message);
        }
        if (message.length > 0) {
          this._toastrService.Success(message, Constant.SuccessToastrTitle);
          if ((this.tab == 1) || tabs) {
          this.dialogRef.close(true);
          this.frmQuickpost.reset();}
          this.frmQuickpost.patchValue({
            // AddressFromDate: new Date(),
            // AddressToDate: new Date()
          });
          // this.txtTitle.textBox.nativeElement.focus();

        }
        else {
          this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
        }
      },
      (error) => {
        this._toastrService.Error(error, Constant.ErrorToastrTitle);
        this.error = error;
        this.loading = false;
        this.loadingScreenService.stopLoading();
      }
    );
    }
    else if (this.tab >= 2 && this.tab <= 4) {
      this.tab = this.tab + 1;
      this.nav.select(this.tab)
      if(this.tab == 4){
        this.getAttachmentList('Images');
       }

      if (tabs) {
        this.dialogRef.close(true);
        this.frmQuickpost.reset();
      }
    }
  }

  OnQuickPostTimeChangeFrom($event){
    let timefrom = this.frmQuickpost.get('DiaryTimeFrom').value;
    let timeto = this.frmQuickpost.get('DiaryTimeTo').value;
    if (timefrom && timeto) { 
      if(timefrom.hour>=timeto.hour){
      if(timefrom.minute>timeto.minute){
      //this.frmQuickpost.get('DiaryTimeFrom').disable();
      this.frmQuickpost.get('DiaryTimeFrom').setValue(timeto)
      }
    }
    }
  }
 

  OnQuickPostTimeChange(event) {
    let timefrom = this.frmQuickpost.get('DiaryTimeFrom').value;
    let timeto = this.frmQuickpost.get('DiaryTimeTo').value;
    if (timefrom && timeto) { 
    if((timefrom.hour>=timeto.hour)){
         if(timefrom.hour>timeto.hour){  
        timefrom.hour = timeto.hour
         this.frmQuickpost.get('DiaryTimeFrom').setValue(timeto);
      }
        if(timefrom.minute>=timeto.minute)
        this.frmQuickpost.get('DiaryTimeFrom').setValue(timeto);
    }
    }
    if (timeto)
      this.spinnerTftime = timeto;
  }

  onFileSelected(event){
    //console.log(event)
    this.uploader.uploadAll();
    //this.loadingScreenService.startLoading();
  }
   
  async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string, CreatedDate:string, CreatedTime:string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url,filetype),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems1.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url,filetype),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems1.findIndex(item => item.id === _id);
    this.GalleryItems1[index] = galleryItem;
  //  console.log(this.attachmentlist)
  }
   
  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string,CreatedDate:string, CreatedTime:string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url,filetype),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url,filetype),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems.findIndex(item => item.id === _id);
    this.GalleryItems[index] = galleryItem;
    // console.log(this.GalleryItems)
  }
  
  // async loadHeic2AnyScript(): Promise<void> {
  //   if ((window as any).heic2any) {
  //     // heic2any is already loaded
  //     return;
  //   }
  
  //   return new Promise((resolve, reject) => {
  //     const script = document.createElement('script');
  //     script.src = 'https://cdn.jsdelivr.net/npm/heic2any@latest/dist/heic2any.min.js';
  //     script.onload = () => resolve();
  //     script.onerror = () => reject(new Error('Failed to load heic2any script'));
  //     document.body.appendChild(script);
  //   });
  // }
  
  // async convertHeicImages(url: string, type: string): Promise<string> {
  
  //   if (type === 'Images') {
  //     const src = url;
  //     if (src && src.toLowerCase().endsWith(".heic")) {
  //       try {
  //         const response = await fetch(src);
  //         const blob = await response.blob();
  //         const convertedBlob = await (window as any).heic2any({ blob: blob, toType: "image/jpeg" });
  
  //         // Convert the blob to a base64 string
  //         const base64String = await this.blobToBase64(convertedBlob);
  
  //         return base64String; // This will return a data URL instead of a blob URL
  //       } catch (error) {
  //         // console.error("Error converting HEIC image:", error);
  //       }
  //     }
  //   }
  //   return url;
  // }
  
  // // Convert Blob to Base64 string
  // blobToBase64(blob: Blob): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result as string);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob);
  //   });
  // }
  
  
    async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
      if (type === "Videos") {
        return new Promise<string>((resolve, reject) => {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Enable cross-origin for video
          video.src = videoURL;
    
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = 10.5; // Adjust as needed
    
            video.addEventListener('seeked', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              // Draw a play icon in the center of the canvas
              const playIconSize = 250; // Adjust the size as needed
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              ctx.beginPath();
              ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
              ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
              ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
              ctx.closePath();
              ctx.fill();
    
              // Resolve with the data URL of the canvas
              resolve(canvas.toDataURL('image/png'));
    
              // Clean up
              canvas.remove();
              video.remove();
            });
    
            video.addEventListener('error', (error) => {
              resolve("assets/images/pages/Cannot_play.png")
              //  reject(`Error loading video: ${error.message}`);
            });
          });
    
          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video metadata: ${error.message}`);
          });
    
          // Load the video
          video.load();
        });
      }
    }
  
    async generateVideoData(src,filetype): Promise<string> {
      if(filetype === "Videos"){
      const videoData = {
        source: [{ src: src, type: 'video/mp4' }],
        attributes: { preload: true, controls: true }
      };
      return JSON.stringify(videoData);}
    }

  // async onDeleteDocument(userQuickPostDocumentID: number) {
  //   let UserQuickPostDocumentIds: number[] = [];
  //   if (userQuickPostDocumentID) {
  //     UserQuickPostDocumentIds.push(userQuickPostDocumentID);
  //     Swal.fire({
  //       title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TITLE_QUICKPOST_IMAGE.replace('Quick Post', this.btnNameJorD+' Entry'),
  //       text: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TEXT,
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#7367F0',
  //       cancelButtonColor: '#E42728',
  //       confirmButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
  //       cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
  //       customClass: {
  //         confirmButton: 'btn btn-primary',
  //         cancelButton: 'btn btn-danger ml-1'
  //       }
  //     })
  //       .then(
  //         (async (result) => {
  //           if (result.value) {
  //             const a = await this.DeleteQuickPostDocument(UserQuickPostDocumentIds);
  //           }
  //         })
  //       );
  //   }
  // }
  // async DeleteQuickPostDocument(UserQuickPostDocumentIds: Array<number>) {
  //   //console.log('DeleteMyAddressDocument');
  //   this.loadingScreenService.startLoading();
  //   if (UserQuickPostDocumentIds.length < 1) {
  //     return false;
  //   }
  //   this.deleteDocumentsInputModel = [];
  //   UserQuickPostDocumentIds.forEach(userQuickPostDocumentId => {
  //     this.deleteDocumentsInputModel.push({
  //       DeleteID: userQuickPostDocumentId,
  //       UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
  //     });
  //   });
  //   let promise = new Promise((resolve, reject) => {
  //     var Result: boolean;
  //     const path = 'api/UserQuickPost/DeleteUserQuickPostDocuments';
  //     this.subDeleteQuickPostAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
  //       (data) => {
  //         this.loadingScreenService.stopLoading();
  //         //console.log(data);
  //         var result = JSON.parse(JSON.stringify(data));
  //         //console.log(result);
  //         if (result.status == Status.Success) {
  //           Swal.fire({
  //             icon: 'success',
  //             title: 'Deleted!',
  //             text: E.ConvertToString(result.message).replace('Quick post', this.btnNameJorD+' Entry'),
  //             customClass: {
  //               confirmButton: 'btn btn-success'
  //             }
  //           });
  //           const indexToRemove:number = this.GalleryItems.findIndex(item => item.id == UserQuickPostDocumentIds);
  //           if (indexToRemove !== -1) {
  //             this.GalleryItems.splice(indexToRemove, 1);
  //           }    
  //           Result = true;
  //         }
  //         else {
  //           this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  //           Result = false;
  //         }
  //         resolve(Result);
  //       },
  //       (error) => {
  //         this._toastrService.Error(error, Constant.ErrorToastrTitle);
  //         this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  //         this.loadingScreenService.stopLoading();
  //       }
  //     );
  //   });
  //   return await promise;
  // }


  async onDeleteDocument(userQuickPostDocumentID: number) {
    if (!userQuickPostDocumentID) {
      return;
    }
  
    try {
      await this.showConfirmationDialog();
      const result = await this.DeleteQuickPostDocument([userQuickPostDocumentID]);
  
      if (result) {
        // Next line of code after both function1 and function2 have completed
      }
    } catch (error) {
    }
  }
  
  showConfirmationDialog(): Promise<void> {
    return new Promise<void>((resolve) => {
      Swal.fire({
        title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TITLE_QUICKPOST_IMAGE.replace('Quick Post', this.btnNameJorD+' Entry'),
        text: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CONFIRM_BUTTON_TEXT_UNLINK,
        cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          resolve();
        }
      });
    });
  }
  
  async DeleteQuickPostDocument(UserQuickPostDocumentIds: Array<number>): Promise<boolean> {
    this.loadingScreenService.startLoading();
  
    try {
      if (UserQuickPostDocumentIds.length < 1) {
        return false;
      }
  
      this.deleteDocumentsInputModel = [];
       this.UserQuickpostDocument = UserQuickPostDocumentIds
      UserQuickPostDocumentIds.forEach((userQuickPostDocumentId) => {
        this.deleteDocumentsInputModel.push({
          DeleteID: userQuickPostDocumentId,
          UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId,
          RecordID: this._data
        });
      });
  
      const result = await this.postDataAndHandleResponse();
  
      return result;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    } finally {
      this.loadingScreenService.stopLoading();
    }
  }
  
  private postDataAndHandleResponse(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const path = 'api/UserQuickPost/DeleteUserQuickPostDocuments';
      this.subDeleteQuickPostAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
        (data) => {
          const result = JSON.parse(JSON.stringify(data));
          console.log(result)
          if (result.status === Status.Success) {
            // Handle success
            this.handleSuccess(result);
            resolve(true);
          } else {
            // Handle failure
            this.handleFailure(result);
            resolve(false);
          }
        },
        (error) => {
          // Handle network error
          this.handleNetworkError(error);
          resolve(false);
        }
      );
    });
  }
  
  private handleSuccess(result: any): void {
    Swal.fire({
      icon: 'success',
      title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TEXT_DELETED,
      text:this.currentAppLanguage.data.QUICKPOST.TOAST_MESSAGE_QUICKPOST_DELETE_SUCCESSFULLY.replace('Quick Post', this.btnNameJorD+' Entry'),
     // text: E.ConvertToString(result.message).replace('Quick post', this.btnNameJorD+' Entry'),
      confirmButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY,
      customClass: {
        confirmButton: 'btn btn-success',
      },
    });
  
    // Modify this part as needed
    this.DocumentList = this.DocumentList.filter((x) => !this.UserQuickpostDocument.includes(x.DocumentId));
    const indexToRemove: number = this.GalleryItems.findIndex((item) => item.id == this.UserQuickpostDocument);
    if (indexToRemove !== -1) {
      this.GalleryItems.splice(indexToRemove, 1);
    }
    const indexToRemovenew: number = this.GalleryItems1.findIndex((item) => item.id == this.UserQuickpostDocument);
    if (indexToRemovenew !== -1) {
      this.GalleryItems1.splice(indexToRemovenew, 1);
    }
  }
  
  private handleFailure(result: any): void {
    this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  }
  
  private handleNetworkError(error: any): void {
    this._toastrService.Error(error, Constant.ErrorToastrTitle);
    this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  }



  async onDownloadDocument(document: QuickPostDocumentListOutputModel) {
    if (document) {
      await this.DownloadMyAddressDocument(document);
    }
  }
  async DownloadMyAddressDocument(Document: QuickPostDocumentListOutputModel, IsDownloadAll = false) {
    //console.log('DeleteMyAddressDocument');
    this.loadingScreenService.startLoading();
    if (typeof Document === 'undefined' || Document == null) {
      return false;
    }
    this.downloadFileInputModel = {
      DownloadID: Document.DocumentId,
      FilePath: Document.DocumentPath,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    };
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      const path = 'api/UserQuickPost/DownloadUserQuickPostDocuments';
      this.subDownloadQuickpostAtt$ = this.globalService.PostDataById(this.downloadFileInputModel, path).subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          if (result.status == Status.Success) {
            let _downloaded = <DownloadFileOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            var sampleArr = this.globalService.base64ToArrayBuffer(E.ConvertToString(_downloaded.FileBase64String));
            this.globalService.saveByteArray(_downloaded.FileName, sampleArr, _downloaded.FileMimeType);
            if (IsDownloadAll === false)
              this._toastrService.Success('Downloaded Successfully.', Constant.SuccessToastrTitle);
            //console.log(this.DocumentList);
            Result = true;
          }
          else {
            this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
          resolve(Result);
        }
      );
    });
    return await promise;
  }

  AddMoodList(url:string,id:number,name:string,sequence:number,rating:number){
    this.MoodItem= [
      ...this.MoodItem,
      {
        moodpath:url,
        moodid:id,
        moodname:name,
        moodorder:sequence,
        moodrating:rating,
      },
    ];
   }

  AddMoodRating(url:string,id:number,name:string,order:number){
    this.Ratings= [
      ...this.Ratings,
      {
       _url:url,
       _id:id,
       _name:name,
       _order:order,
      }
    ];
  }

  OnClickMood(_moodid){
    this._QuickPostService.setTracker(_moodid);
// console.log(_moodid)
  }
 
   OnClickRating(id){
   this._QuickPostService.setRating(id);
  //  console.log(id)
   }
 

  addTagMethod = (term) => {
    this.customTags = [...this.customTags, { id: this.customTags.length, name: term, tag: true }];
    return { id: term, name: term };
  };

  removeTagMethod(term) {
    this.customTags[term.index].tag = false;
  }

  selectAddTagMethod(obj) {
    if (this.customTags && obj) {
      if (this.customTags.indexOf(obj) > -1) {
        this.customTags[obj.id].tag = true;
      }
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault(); // Prevent scrolling or input field focus
      const inputElement = event.target as HTMLElement;
      const activeItem = document.querySelector('.ng-option-marked');

      if (activeItem) {
        // Simulate a click on the highlighted option to select it
        (activeItem as HTMLElement).click();
      }
    }
  }

  async onDeleteClick() {
    if ((!this.userQuickPosts || !this.userQuickPosts.UserQuickPostId) && !this._data) {
      this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
      return;
    }
    Swal.fire({
      title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TITLE_QUICKPOST_TEXT.replace('Quick Post', this.btnNameJorD+' Entry'),
      text: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
      cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    })
      .then(
        (async (result) => {
          if (result.value) {
            if(this._data)
            var UserQuickPostId = this._data
            else if(this.userQuickPosts.UserQuickPostId)
            UserQuickPostId = this.userQuickPosts.UserQuickPostId
            const a = await this.DeleteThisQuickPost(UserQuickPostId);
          }
        })
      );
  }
  async DeleteThisQuickPost(UserQuickPostId: number) {
    this.loadingScreenService.startLoading();
    this.deleteInputModel = {
      DeleteID: UserQuickPostId,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    }
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      const path = 'api/UserQuickPost/DeleteUserQuickPost';
      let response: Observable<any>;
      response = this.globalService.PostDataById(this.deleteInputModel, path);
      response.subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          var result = JSON.parse(JSON.stringify(data));
          if (result.status == Status.Success) {
            Swal.fire({
              icon: 'success',
              title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_DELETE_TEXT,
              text: E.ConvertToString(result.message).replace('Quick post', this.btnNameJorD+' Entry'),
              customClass: {
                confirmButton: 'btn btn-success'
              }
            });
            this.dialogRef.close(true);
            Result = true;
          }
          else {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    });
    return await promise;
  }

  onQuickPostChange(event) {
    this.PlaceholderQuickPost = (event == null || (event != null && event < 1)) ? this.currentAppLanguage.data.QUICKPOST.PLACEHOLDER_SELECT_POST_TYPE : "";
  }
  onCloseButtonClick() {
    this.closeDialog();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  settings: any = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide:false,
    plugins: [
      lgZoom,
      // pager,
      autoPlay,
      fullScreen,
      rotate,
      thumbnail,
      videoThumbnail,
      vimeoVideo,
      lgVideo,
      lgvideo,
      //share
    ] // comment, share (options are commented for future use)
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };
 

  onSearchOnMapClick() {
    this.openDialogMapAddress();
  }



  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%";
    dialogConfig.height = "55%";
    dialogConfig.position = { bottom: '18%' };
    
      let _model = new MapAddressOutputModel(this.userQuickPosts.Latitude, this.userQuickPosts.Longitude, this.userQuickPosts.Location);
      dialogConfig.data = new MapAddressDataModel(MapModalType.LatLong, _model);
    
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
  
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchTextVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {
            
            this.userQuickPosts.Latitude = result.data.lat;
            this.userQuickPosts.Longitude = result.data.long;
            this.frmQuickpost.get('Location').setValue(E.ConvertToString(result.data.searchstring));
          }
        }
        // this.loadData();
      }
    );
  }
  onCancelClick() {
    this.dialogRef.close(false);
  }

  onPostTypeChange(event: any): void {
    const selectedText = event.target.textContent;
    if (selectedText == 'Shared') { 
      if(this.authenticationService.currentUserValue.CurrentNoosomPlanId == NoosomPlans.Premium_1 || this.authenticationService.currentUserValue.NoosomCategoryEnumId == NoosomCategoryID.Test)
      this.openUploaderModal(this.uploaderContent)
    else{
      this.showUpgradeAlert();
    }
    }
  }


  GetUserConnectionRequests(inputModel) {
    let response: Observable<any>;
    this._QuickPostService.GetUserConnectionRequests(inputModel)
      .subscribe(
        (result) => {
          this.loading = false;
          //this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            var connectionRequestData = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)))
            this.separateLists(connectionRequestData)
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          // this.loadingScreenService.stopLoading();
        }
      );
  }

  openUploaderModal(content) {
    let model = this.modalService.open(content, { centered:true,size:'sm'}); // 'lg' for large size, adjust as needed
    model.result.then(
      (result) => {
        const combinedUserDetailsIds = [...this.selectAllFriends, ...this.selectAllFamily];
        if(combinedUserDetailsIds == null || combinedUserDetailsIds.length == 0){
          let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
          if (_private && _private[0] && this.frmQuickpost.get('QuickPostTypeId').value == null)
            this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
        }
      },
      (reason) => {
        if (reason === ModalDismissReasons.ESC) {
          const combinedUserDetailsIds = [...this.selectAllFriends, ...this.selectAllFamily];
          if(combinedUserDetailsIds == null || combinedUserDetailsIds.length == 0){
            let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
            if (_private && _private[0])
              this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
          }
        }
        else if(reason === ModalDismissReasons.BACKDROP_CLICK){
          const combinedUserDetailsIds = [...this.selectAllFriends, ...this.selectAllFamily];
          if(combinedUserDetailsIds == null || combinedUserDetailsIds.length == 0){
            let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
            if (_private && _private[0])
              this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
          }
        }
        else if(reason == 'Cross click'){
          const combinedUserDetailsIds = [...this.selectAllFriends, ...this.selectAllFamily];
          if(combinedUserDetailsIds == null || combinedUserDetailsIds.length == 0){
            let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
            if (_private && _private[0])
              this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
          }
          }
      }
    );
   }

  separateLists(data) {
    const filteredDatafriend = data.filter(user => user.UserFriendId !== null && user.ConnectionRequestStatusId == 3);

    // Map over the filtered data to add the selected field
    this.friendsList = filteredDatafriend.map(user => ({
      ...user,  // Spread the existing properties
      selected: false  // Add the new selected field with default value
    }));
      const filteredData = data.filter(user => user.UserFamilyMemberId !== null && user.ConnectionRequestStatusId == 3);
      this.familyList = filteredData.map(user => ({
        ...user,  // Spread the existing properties
        selected: false  // Add the new selected field with default value
      }));

      // Initialize search lists
  this.filteredFriendsList = [...this.friendsList];
  this.filteredFamilyList = [...this.familyList];
  }

  searchLists(searchTerm: string) {
    // Convert the search term to lowercase for case-insensitive search
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
  
    // Filter friendsList
    this.filteredFriendsList = this.friendsList.filter(user =>
      user.UserFriendFullName?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  
    // Filter familyList
    this.filteredFamilyList = this.familyList.filter(user =>
      user.UserFamilyMemberFullName?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }

  share(){
    this.combinedUserDetailsIds = Array.from(new Set([...this.selectAllFriends, ...this.selectAllFamily]));
    this.modalService.dismissAll();
    if(this.combinedUserDetailsIds == null || this.combinedUserDetailsIds.length == 0){
      let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
      if (_private && _private[0] && !(this.frmQuickpost.get('QuickPostTypeId').value == this.quickpostLoadDataOutputModel.QuickPostTypeList[2].QuickPostTypeId))
        this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
    }
  }

  onCheckboxChange(checked: boolean, folderId: number, type:string): void {
    if(type=='friend'){
    if (checked) {
      // Add folderId to selectedFolderIds if checkbox is checked
      this.selectAllFriends.push(folderId);
    } else {
      // Remove folderId from selectedFolderIds if checkbox is unchecked
      const index = this.selectAllFriends.indexOf(folderId);
      if (index !== -1) {
        this.selectAllFriends.splice(index, 1);
      }
    }}
    if(type=='family'){
      if (checked) {
        // Add folderId to selectedFolderIds if checkbox is checked
        this.selectAllFamily.push(folderId);
      } else {
        // Remove folderId from selectedFolderIds if checkbox is unchecked
        const index = this.selectAllFamily.indexOf(folderId);
        if (index !== -1) {
          this.selectAllFamily.splice(index, 1);
        }
      }}
  }

  toggleSelectAllFriends() {
    if (this.selectAllFriends.length === this.friendsList.length) {
      // If all folders are already selected, unselect all
      this.selectAllFriends = [];
    } else {
      // If not all folders are selected, select all

      this.selectAllFriends = this.friendsList.map(folder => folder.ReceiverUserDetailsId);
    }

    const checkboxes = document.querySelectorAll('.folder-checkbox') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
      checkbox.checked = this.selectAllFriends.includes(Number(checkbox.value));
    });
  }
  isFolderSelected(type:string,folderId: number): boolean {
    if(type == 'friend')
    return this.selectAllFriends.includes(folderId);
  if(type == 'family')
    return this.selectAllFamily.includes(folderId);
  }

  toggleSelectAllFamily() {
    this.familyList.forEach(family => family.selected = this.selectAllFamily);
    if (this.selectAllFamily.length === this.familyList.length) {
      // If all folders are already selected, unselect all
      this.selectAllFamily = [];
    } else {
      // If not all folders are selected, select all

      this.selectAllFamily = this.familyList.map(folder => folder.ReceiverUserDetailsId);
    }

    const checkboxes = document.querySelectorAll('.folder-checkboxfamily') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
      checkbox.checked = this.selectAllFamily.includes(Number(checkbox.value));
    });
  }

   getUserQuickPostSharingById(){
    let inputModel={
      SharedFromUserDetailsID:this.authenticationService.currentUserValue.UserDetailsId,
      UserQuickPostID:this._data
    }
    let response: Observable<any>;
    this._QuickPostService.GetUserQuickPostSharingById(inputModel)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            var connectionRequestData = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)))
            if(connectionRequestData == null || connectionRequestData.length == 0)
            {
              let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
              if (_private && _private[0] && !(this.frmQuickpost.get('QuickPostTypeId').value == this.quickpostLoadDataOutputModel.QuickPostTypeList[2].QuickPostTypeId) )
                this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId); 
            }
            this.initializeCheckboxesFromSharedData(connectionRequestData);
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  setCreateUpdateShare(){
    this.userShareInputModel={
      UsersQuickPostSharingID: 0,
      SharedFromUserDetailsID: this.authenticationService.currentUserValue.UserDetailsId,
      SharedToUserDetailsIDs: this.combinedUserDetailsIds,
      UserQuickPostID: this._data,
      CreatedDate:  E.GetTodayDateStringOnly(),
      CreatedTime: E.GetTodayTimeStringOnly(),
      CreatedBy:  this.authenticationService.currentUserValue.UserDetailsId,
      ChangedDate:  E.GetTodayDateStringOnly(),
      ChangedTime: E.GetTodayTimeStringOnly(),
      ChangedBy: this.authenticationService.currentUserValue.UserDetailsId,
    }
    let response: Observable<any>;
    this._QuickPostService.CreateUpdateUserQuickPostShare(this.userShareInputModel)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            var connectionRequestData = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)))
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  initializeCheckboxesFromSharedData(sharedData: any[]) {
    // Extract SharedToUserDetailsIDs from the sharedData
    const sharedToUserDetailsIDs = sharedData.map(data => data.SharedToUserDetailsID);

    // Update selectAllFriends to reflect the shared data
    this.selectAllFriends = sharedToUserDetailsIDs;
    this.selectAllFamily = sharedToUserDetailsIDs;

    // Update the checkboxes' checked status
    this.updateCheckboxes();
}

updateCheckboxes() {
    const checkboxes = document.querySelectorAll('.folder-checkbox') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
        checkbox.checked = this.selectAllFriends.includes(Number(checkbox.value));
        checkbox.checked = this.selectAllFamily.includes(Number(checkbox.value));
    });
}

triggerFileUpload() {
  this.fileInput.nativeElement.click();
}

openFilemanager(){
  // using for old filemanager uploads
 // this.openDialog(UploadManagerComponent)
 var matdata = {
  RecordId : this._data,
  RecordType: RecordsType.QuickPost
 }
 this.openDialog(SelectSyncFileManagerComponent,matdata)
}

openDialog(component: any, _data: any = []) {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = false;
  dialogConfig.width = "66%"
  dialogConfig.height="60%"
  dialogConfig.position={
    top:"10%"
  }
  dialogConfig.data = _data;
  const dialogRef = this.dialog.open(component, dialogConfig);
  dialogRef.afterClosed().subscribe(
    data => {
          if(data){
            this.nav.select(4);
            this.getAttachmentList('Images');
          }
    }
  );
}

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmQuickpost.controls;
  }
}
