import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    ADDRESS: {
      ALERT_MESSAGE_REQUIRED_FIELDS: 'आवश्यक फ़ील्ड्स खाली हैं।',
      DIALOG_TITLE_ADD_ADDRESS: 'पता जोड़ें',
      DIALOG_TITLE_EDIT_ADDRESS: 'पता संपादित करें',
      TAB_HEADING_ADDRESS_DETAILS: 'पते का विवरण',
      TAB_HEADING_UPLOAD: 'अपलोड करें',
      TAB_HEADING_GALLERY: 'गेलरी',
      TAB_HEADING_DOCUMENTS: 'दस्तावेज़',
      TAB_HEADING_NOTES: 'टिप्पणियाँ',
      INFO_TEXT: 'उन सभी पतों को जोड़ें जिन पर आप रह चुके हैं या वर्तमान में यहाँ रहते हैं',
      INFO_TEXT1: 'कुछ तस्वीरें, दस्तावेज और वीडियो का चयन करें। आप उन्हें एक बार में अपलोड कर सकते हैं या एक साथ Upload All चुन सकते हैं।',
      INFO_TEXT2: 'आपकी सभी अपलोड की गई तस्वीरें यहां प्रदर्शित की जाएगी।',
      INFO_TEXT3: 'इस पते के लिए आपके पास कोई विशिष्ट नोट्स जोड़ें।',
      INFO_TEXT4: 'आपके सभी दस्तावेज एक सूची में यहां प्रदर्शित किए जाएंगे। आप उन्हें विभिन्नता से डाउनलोड या हटा सकते हैं।',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'बंद करना',
      TOOLTIP_TEXT_BUTTON_CALENDAR: 'कैलेंडर टॉगल करें',
      TOOLTIP_TEXT_BUTTON_ADD_RECORD: 'रिकॉर्ड जोड़ें',
      TOOLTIP_TEXT_BUTTON_SELECT: 'चयन',
      TOOLTIP_TEXT_BUTTON_ADD_ADDRESS: 'एक पता जोड़ें',
      TOOLTIP_TEXT_BUTTON_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      TOOLTIP_TEXT_BUTTON_SEARCH_ON_MAP: 'मानचित्र पर खोजें',
      TOOLTIP_TEXT_BUTTON_CANCEL: 'रद्द करना',
      TOOLTIP_TEXT_BUTTON_UPLOAD: 'अपलोड करें',
      TOOLTIP_TEXT_BUTTON_REMOVE: 'हटाना',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'डाउनलोड',
      TOOLTIP_TEXT_BUTTON_VIEW: 'देखें',
      TOOLTIP_TEXT_BUTTON_DELETE: 'हटाएँ',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD_ALL: 'सभी डाउनलोड करें',
      TOOLTIP_TEXT_BUTTON_UPLOAD_ALL: 'सभी अपलोड करें',
      TOOLTIP_TEXT_BUTTON_REMOVE_ALL: 'सभी हटाएं',
      TOOLTIP_TEXT_ADDRESS_TAG: "पता टैग / कोड आपके अपने विशिष्ट शब्दों का चयन है जिसका आप Noosom वेब सिस्टम में खोज के लिए प्रयोग कर सकते हैं",
      TOOLTIP_TEXT_HOUSE_BUILDING_NAME: "यहाँ आप संपत्ति, घर, इमारत या सोसायटी का नाम दर्ज करें, जैसे 'ग्लोरिया का विला' या 'मन्नत रिज़िडेंसी'",
      TOOLTIP_TEXT_STREET_NAME: "यह आपके स्थान को सटीकता से मैप करने के लिए आवश्यक फ़ील्ड है; कृपया सड़क का नाम सही दर्ज करें",
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: "यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है",
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: "यह और अधिक सटीक स्थान के मैपिंग के लिए एक अतिरिक्त फ़ील्ड है",
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: "पते के गाँव, गाँव या शहर दर्ज करें",
      TOOLTIP_TEXT_POSTAL_ZIPCODE: "यह पते के देश के संबंधित पोस्टल ज़िपकोड है",
      TOOLTIP_TEXT_REGION_CONTINENT: "पते का क्षेत्र या महाद्वीप चुनें; उदाहरण के लिए, संयुक्त राज्य यूनाइटेड किंगडम यूरोप में होगा",
      TOOLTIP_TEXT_COUNTRY: "पते का देश चुनें; उदाहरण के लिए, इंग्लैंड यूरोप में होगा",
      TOOLTIP_TEXT_STATE_PROVINCE: "पते का राज्य या प्रांत चुनें; उदाहरण के लिए, रोटुमा फिजी में एक राज्य या द्वीप है",
      TOOLTIP_TEXT_CURRENT_ADDRESS: "यह वर्तमान पता चेकबॉक्स है; आप इसे चुन सकते हैं या अपनी पसंद के अनुसार खाली छोड़ सकते हैं",
      TOOLTIP_TEXT_STAY_DATE_RANGE_1: "इस फ़ील्ड का उपयोग आपके पते पर रहने की तारीख की श्रेणी दर्ज करने के लिए है",
      TOOLTIP_TEXT_STAY_DATE_RANGE_2: "इस फ़ील्ड का उपयोग आपके पते पर रहने की तारीख की श्रेणी दर्ज करने के लिए है",
      HEADING_TEXT_SELECT_IMAGES: 'छवियों का चयन करें',
      HEADING_TEXT_SELECT_DOCUMENTS: 'दस्तावेज़ों का चयन करें',
      HEADING_TEXT_UPLOAD_QUEUE: 'अपलोड कतार',
      HEADING_TEXT_QUEUE_PROGRESS: 'कतार प्रगति',
      TEXT_FILE_TYPES: 'अनुमत फ़ाइल प्रकार:',
      TEXT_QUEUE_LENGTH: 'कतार की लंबाई:',
      TEXT_MB: 'एमबी',
      CONTROL_LABEL_ADDRESS_TAG: 'पता टैग / कोड',
      CONTROL_LABEL_ADDRESS_NAME: 'नाम पता',
      CONTROL_LABEL_ADDRESS1: 'सड़क का नाम',
      CONTROL_LABEL_ADDRESS2: 'पता २',
      CONTROL_LABEL_ADDRESS3: 'पता ३',
      CONTROL_LABEL_ADDRESS_TOWN: 'पता टाउन/सिटी ',
      CONTROL_LABEL_ADDRESS_STATE: 'पता राज्य',
      CONTROL_LABEL_ADDRESS_POST_CODE: 'पता पोस्ट कोड',
      CONTROL_LABEL_COUNTRY: 'देश',
      CONTROL_LABEL_DATE_FROM: 'तारीख से',
      CONTROL_LABEL_DATE_TO: 'तारीख तक',
      CONTROL_LABEL_REGION: 'क्षेत्र',
      CONTROL_LABEL_MULTIPLE: 'विभिन्न',
      CONTROL_LABEL_NOTES: 'टिप्पणियाँ',
      LABEL_CURRENT_ADDRESS: 'वर्तमान पता',
      PLACEHOLDER_STATE: 'राज्य का चयन करें',
      PLACEHOLDER_COUNTRY: 'देश का चयन करें',
      PLACEHOLDER_REGION: 'क्षेत्र का चयन करें',
      PLACEHOLDER_CHOOSE_FILES: 'फ़ाइलों का चयन करें',
      BUTTON_TEXT_ADD_RECORD: 'रिकॉर्ड जोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करना',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      BUTTON_TEXT_UPLOAD_ALL: 'सभी को अपलोड करें',
      BUTTON_TEXT_PAUSE_ALL: 'सभी रोकें',
      BUTTON_TEXT_REMOVE_ALL: 'सभी हटाएं',
      BUTTON_TEXT_SEARCH_ON_MAP: 'मानचित्र पर खोजें',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'सहेजें और बंद करें',
      BUTTON_TEXT_SAVE_AND_NEXT: 'सहेजें और अगला',
      BUTTON_TEXT_ABANDON: 'छोड़ देना',
      BUTTON_TEXT_DOWNLOAD_ALL: 'सभी डाउनलोड करें',
      BUTTON_TEXT_SELECT: 'चयन',
      COLUMN_HEADING_NAME: 'नाम',
      COLUMN_HEADING_SIZE: 'परिमाण',
      COLUMN_HEADING_PROGRESS: 'प्रगति',
      COLUMN_HEADING_STATUS: 'स्थति',
      COLUMN_HEADING_ACTIONS: 'कार्रवाई',
      COLUMN_HEADING_DATE_CREATED: 'निर्माण की तिथि',
      COLUMN_HEADING_TYPE: 'प्रकार',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'क्या आप सुनिश्चित हैं, आप इस पता दस्तावेज़ को अनलिंक करना चाहते हैं?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'क्या आप सुनिश्चित हैं, आप इन सभी पता दस्तावेज़ों को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ADDRESS_TEXT: 'क्या आप सुनिश्चित हैं कि आप इस पते और इससे जुड़े सभी अनुलग्नकों को हटाना चाहते हैं?',
      SWEET_ALERT_TEXT: "आप इसे पूर्ववत नहीं कर पाएंगे!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'हाँ, मिटा दो!',
      SWEET_ALERT_CONFIRM_UNLINK_BUTTON_TEXT: 'हाँ, इसे अनलिंक करें!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'रद्द करना',
      SWEET_ALERT_TEXT_DELETED: 'हटा दिया!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'ओके',
      TOAST_MESSAGE_ADDRESS_ADDED_SUCCESSFULLY: 'पता सफलतापूर्वक जोड़ा गया।',
      TOAST_MESSAGE_ADDRESS_UPDATED_SUCCESSFULLY: 'पता सफलतापूर्वक अपडेट किया गया।',
      INFO_MESSAGE_COMING_SOON: 'जल्द आ रहा है',
      WARNING_MESSAGE_SELECT_LOCATION: 'कृपया मानचित्र पर स्थान का चयन करें।',
      ERROR_MESSAGE_REQUIRED_ADDRESS_TAG: 'पता टैग आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_NAME: 'पता नाम आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS1: 'सड़क आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS2: 'पता २ आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS3: 'पता ३ आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_TOWN: 'शहर का पता आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_STATE: 'राज्य का पता आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_POST_CODE: 'पोस्ट कोड आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_COUNTRY: 'देश आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_REGION: 'क्षेत्र आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_DATE_FROM: 'से दिनांक आवश्यक है!',
      ERROR_MESSAGE_REQUIRED_DATE_TO: 'तक दिनांक आवश्यक है!',
      ERROR_MESSAGE_MAP_ZERO_RESULTS: 'स्थान नहीं मिला!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमत 50 है',
    },
    MAP: {
      DIALOG_TITLE_SEARCH_ON_MAP: 'मानचित्र पर खोजें',
      DIALOG_TITLE_VIEW_ON_MAP: 'मेरे पते को एक मानचित्र पर देखें।',
      DIALOG_TITLE_VIEW_ADDRESS_ON_MAP: 'मानचित्र पर स्थान देखें',
      DIALOG_TITLE_VIEW_SCHOOL_ON_MAP: 'मेरे स्कूल के पते को मानचित्र पर देखें',
      PLACEHOLDER_SEARCH: 'खोजें...',
    }
  }
};
