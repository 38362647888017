import { Component, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { locale as english } from 'app/layout/components/menu/i18n/en';
import { locale as french } from 'app/layout/components/menu/i18n/fr';
import { locale as german } from 'app/layout/components/menu/i18n/de';
import { locale as portuguese } from 'app/layout/components/menu/i18n/pt';
import { locale as gujarati } from 'app/layout/components/menu/i18n/gj';
import { locale as hindi } from 'app/layout/components/menu/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { EcommerceService } from 'app/main/apps/ecommerce/ecommerce.service';
import { GeneralService } from 'app/Services/general.service';
import { AuthenticationService } from 'app/auth/service';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
interface CardItem {
  value: number;
  titlename: string;
}
@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html',
  styleUrls: ['./navbar-cart.scss'],
})
export class NavbarCartComponent implements OnInit {
  // Public
  @ViewChild('dropdown') dropdown: NgbDropdown;
  public products: any[] = []; // Define products as an array
  public cartList = [];
  public cartListLength = 0;
  currentAppLanguage: any;
  cartItems: any[] = [];
  currencySymbol;
  // Private
  private _unsubscribeAll: Subject<any>;
  totalAmount: any;
  storagePlan
  noosomPlan

  /**
   *
   * @param {EcommerceService} _ecommerceService
   */
  constructor(public _ecommerceService: EcommerceService, private generalService:GeneralService, 
    private authenticationService:AuthenticationService,private router: Router,private encrDecr: EncrDecrService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,)
   {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
      this._translateService.onLangChange.subscribe(lang => {
        // console.log('lang');
        // console.log(lang.lang);
        setTimeout(() => {
          let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
          if (_LangJsonObj)
            this.currentAppLanguage = _LangJsonObj;
        }, 100);
      })
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Remove From Cart
   *
   * @param product
   */
  removeFromCart(product) {
    if (product.isInCart === true) {
      this._ecommerceService.removeFromCart(product.id).then(res => {
        product.isInCart = false;
      });
    }
  }

  removeItemFromCart(item: any): void {
    // const itemIndex = this.products.findIndex((cartItem) => {
    //   if (cartItem.brand === 'PLANS' ) {
    //     this.generalService.removeItem(item.price);
    //     return cartItem.price === item.price;
    //   } else if (cartItem.brand === 'STORAGE') {
    //     const items:CardItem[]=[
    //       {
    //         value :cartItem.price,
    //         titlename:cartItem.name
    //       }];
    //     // items:[
    //     //   {
    //     //     value :cartItem.price,
    //     //     titlename:cartItem.name
    //     //   }]
        
    //     // console.log(items)
    //     this.generalService.removeItem(items);
    //     return cartItem.name === item.titlename && cartItem.price === item.value;
    //   }
    //   return false;
    // });
    this.generalService.removefromCart(item);
  }

  callData(){
    console.log(this.products)
  }

  // upgradePlan(NoosomPlanId, NoosomPricingPlanId) {

  //   var data = {
  //     NoosomPlanId: NoosomPlanId,
  //     NoosomPricingPlanId: NoosomPricingPlanId
  //   }
  //   var Param = this.encrDecr.set(Constant.EncrDecrKey, JSON.stringify(data));
  //   this.router.navigate(['/apps/invoice/InvoiceAddComponent'], { queryParams: { data: Param } });
  // }

  upgradePlan() {
    this.dropdown.close();
    const queryParams: any = { };
  
    // Add storage plan data to query parameters if storage plans exist
    if (this.storagePlan && this.storagePlan.length > 0) {
      const storagePlanData = this.storagePlan.map(plan => ({
        NoosomPlanId: plan.NoosomPlanId,
        NoosomPricingPlanId: plan.NoosomPricingPlanId
      }));
      const storagePlanParam = this.encrDecr.set(Constant.EncrDecrKey, JSON.stringify(storagePlanData));
      queryParams.storageData = storagePlanParam; // Differentiating with 'storageData'
    }
  
    // Add Noosom plan data to query parameters if Noosom plans exist
    if (this.noosomPlan && this.noosomPlan.length > 0) {
      const noosomPlanData = this.noosomPlan.map(plan => ({
        NoosomPlanId: plan.NoosomPlanId,
        NoosomPricingPlanId: plan.NoosomPricingPlanId
      }));
      const noosomPlanParam = this.encrDecr.set(Constant.EncrDecrKey, JSON.stringify(noosomPlanData));
      queryParams.planData = noosomPlanParam; // Differentiating with 'planData'
    }
  
    // Navigate with the prepared query parameters
    this.router.navigate(['/apps/invoice/InvoiceAddComponent'], { queryParams: queryParams });
  }
  
  
  

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
  
    this._ecommerceService.getProducts();

    this._ecommerceService.getCartList();

    // this.generalService.selectedItems$.subscribe((items) => {
    //   this.products=[];
    //      if(items.length>0)
    //      this.products=items;
    // });
    this.generalService.selectedItems$.subscribe((items) => {
      this.products = [];
      this.storagePlan = [];
      this.noosomPlan = [];
      this.totalAmount = 0; // Initialize the totalAmount
      
      if (items.length > 0) {
        this.products = items;
        this.totalAmount = items.reduce((total, item) => {
          return total + (item.BasePricePerYear || 0);
        }, 0);
    
        // Separate the items based on the NoosomPlanId
        items.forEach(item => {
          if (item.NoosomPlanId === 1) {
            this.storagePlan.push(item); // Add to storagePlans if NoosomPlanId is 1
          } else {
            this.noosomPlan.push(item); // Add to noosomPlans otherwise
          }
        });
      }
    });
    
    

    if(this.authenticationService.currentUserValue){
      this.currencySymbol  = this.authenticationService.currentUserValue.CurrencySymbol
    }
    
    // this._ecommerceService.onCartListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //   this.cartList = res;
    //   this.cartListLength = this.cartList.length;
    // });


    // this._ecommerceService.onProductListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //   this.products = res;

    //   if (this.products.length) {
    //     this.products.forEach(product => {
    //       product.isInCart = this.cartList.findIndex(p => p.productId === product.id) > -1;
    //     });
    //   }
    // });
  }

  // callData() {
   
  // }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
}
