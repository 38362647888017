<!-- <p>my-medication works!</p> -->
<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
    <span data-feather="info"></span>
  </span><button class="close-button close" type="button"
      (click)="onCloseButtonClick()" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" placement="right">&times;</button>
  </h5>
  <div class="card-body card-body-dialog">
    <!-- <div class="row py-3 pl-3">
      <div class="col-sm">
        <i>{{'MY_MEDICATION_FORM.SECTION_TEXT'|translate}}</i>
      </div>
    </div> -->
    <form id="frmMedication" [formGroup]="frmMedication" (ngSubmit)="onSubmit()">
      <!-- <form >  -->
      <div class="row scroll-area">
        <div class="col-lg-6 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" formControlName="MedicationName" #medicationname [showInfoIcon]="true" [position]="'bottom'"
            [label]="'MY_MEDICATION_FORM.CONTROL_LABEL_MEDICATION_NAME'| translate" [tooltipInfo]="'MY_MEDICATION_FORM.TOOLTIP_TEXT_MEDICATION_NAME'| translate"
            [maxlength]="50" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby=""
            [classes]="{ 'is-invalid': submitted && f.MedicationName.errors }" for="MedicationName" [showRequiredSign]="true" >
          </app-text-box>
          <span *ngIf="submitted && f.MedicationName.errors">
            <small *ngIf="f.MedicationName.errors.required"
              class="text-danger">{{'MY_MEDICATION_FORM.ERROR_MESSAGE_REQUIRED_MEDICATION_NAME'|translate}}</small>
            <small *ngIf="f.MedicationName.errors.maxlength"
              class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-6 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" formControlName="ConditionFor" [maxlength]="100"
            [label]="'MY_MEDICATION_FORM.CONTROL_LABEL_CONDITION_FOR'| translate" [showRequiredSign]="true" [position]="'bottom'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" [showInfoIcon]="true" [tooltipInfo]="'MY_MEDICATION_FORM.TOOLTIP_TEXT_MEDICAL_CONDITION'| translate"
            [classes]="{ 'is-invalid': submitted && f.ConditionFor.errors }" for="ConditionFor">
          </app-text-box>
          <span *ngIf="submitted && f.ConditionFor.errors">
            <small *ngIf="f.ConditionFor.errors.required"
              class="text-danger">{{'MY_MEDICATION_FORM.ERROR_MESSAGE_REQUIRED_CONDITION_FOR'|translate}}</small>
            <small *ngIf="f.ConditionFor.errors.maxlength"
              class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_100'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-6 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" formControlName="Dosage"
            [classes]="{ 'is-invalid': submitted && f.Dosage.errors }" [showInfoIcon]="true" [tooltipInfo]="'MY_MEDICATION_FORM.TOOLTIP_TEXT_AMOUNT_OF_MEDICATION'| translate"
            [label]="'MY_MEDICATION_FORM.CONTROL_LABEL_DOSAGE'| translate" [position]="'right'"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" [maxlength]="50"
            for="Dosage">
          </app-text-box>
          <span *ngIf="submitted && f.Dosage.errors">
            <small *ngIf="f.Dosage.errors.required"
              class="text-danger">{{'MY_MEDICATION_FORM.ERROR_MESSAGE_REQUIRED_DOSAGE'|translate}}</small>
            <small *ngIf="f.Dosage.errors.maxlength"
              class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-6 col-sm-12 form-group mb-0">
          <app-text-box [type]="'text'" formControlName="Frequency"
            [classes]="{ 'is-invalid': submitted && f.Frequency.errors }" [showRequiredSign]="true" [position]="'right'"
            [label]="'MY_MEDICATION_FORM.CONTROL_LABEL_FREQUENCY'| translate" [maxlength]="100" [showInfoIcon]="true" [tooltipInfo]="'MY_MEDICATION_FORM.TOOLTIP_TEXT_MEDICATION_FREQUENCY'| translate"
            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" for="Frequency" >
          </app-text-box>
          <span *ngIf="submitted && f.Frequency.errors">
            <small *ngIf="f.Frequency.errors.required"
              class="text-danger">{{'MY_MEDICATION_FORM.ERROR_MESSAGE_REQUIRED_FREQUENCY'|translate}}</small>
            <small *ngIf="f.Frequency.errors.maxlength"
              class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-6 col-sm-12 pb-2">
          <label class="form-label" for="PrescribedBy">{{'MY_MEDICATION_FORM.CONTROL_LABEL_PRESCRIBED_BY' |translate}}
            <span ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TEXT_PRESCRIBING_DOCTOR'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <ng-select [items]="medicationLoadDataOutputModel ?. PrescribedByList " bindLabel="ProfessionalName"
            selectOnTab="true" bindValue="UserHealthProviderId" id="PrescribedBy" name="ProfessionalName"
            placeholder="{{'MY_MEDICATION_FORM.PLACEHOLDER_SELECT_PROFESSIONAL_NAME'|translate}}"
            formControlName="PrescribedBy" [ngClass]="{ 'is-invalid': submitted && f.PrescribedBy.errors }"></ng-select>
          <span *ngIf="submitted && f.PrescribedBy.errors">
            <small *ngIf="f.PrescribedBy.errors.required"
              class="text-danger">{{'MY_MEDICATION_FORM.ERROR_MESSAGE_REQUIRED_PRESCRIBED_BY'|translate}}</small>
          </span>
        </div>
        <div class="col-lg-6 col-sm-12 pb-2">
          <label class="form-label" for="RefillDurationId">{{'MY_MEDICATION_FORM.CONTROL_LABEL_REFILL_DURATION' |translate}}
            <span ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TEXT_REFILL_SUPPLY_DURATION'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <ng-select [items]="medicationLoadDataOutputModel ?. RefillDurationList" bindLabel="RefillDurationName"
            selectOnTab="true" bindValue="RefillDurationId" id="RefillDurationId" name="RefillDurationId"
            placeholder="{{'MY_MEDICATION_FORM.PLACEHOLDER_SELECT_REFILL_DURATION'|translate}}"
            formControlName="RefillDurationId" 
            [ngClass]="{ 'is-invalid': submitted && f.RefillDurationId.errors }"></ng-select>
          <span *ngIf="submitted && f.RefillDurationId.errors">
            <small *ngIf="f.RefillDurationId.errors.required"
              class="text-danger">{{'MY_MEDICATION_FORM.ERROR_MESSAGE_REQUIRED_REFILL_DURATION'|translate}}</small>
          </span>
        </div>

        <!-- <div class="row padding-left"> -->
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label" for="LastRefillDate">{{'MY_MEDICATION_FORM.CONTROL_LABEL_LAST_REFILL_DATE' |translate}}
              <span ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TEXT_LAST_SUPPLY_DATE'|translate}}" placement="right">
                <span data-feather="help-circle"
                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>  
            </label>
            <div class="input-group border-color">
              <input id="LastRefillDate" name="LastRefillDate" formControlName="LastRefillDate" [maxDate]="maxFromDate"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}" [placeholder]="dateformat"
                [class]="{ 'is-invalid': submitted && f.LastRefillDate.errors }" required bsDatepicker
                #dpLast="bsDatepicker" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpLast.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.LastRefillDate.errors">
              <small class="text-danger"
                *ngIf="f.LastRefillDate.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_LAST_REFILL_DATE_REQUIRED'
                | translate}}</small>
            </span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="">
            <label class="form-label" for="NextRefillDate">{{'MY_MEDICATION_FORM.CONTROL_LABEL_NEXT_REFILL_DATE' |translate}}
              <span ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TEXT_NEXT_SUPPLY_DATE'|translate}}" placement="right">
                <span data-feather="help-circle"
                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
              </label>
            <div class="input-group">
              <input id="NextRefillDate" name="NextRefillDate" formControlName="NextRefillDate"
                (ngModelChange)="OnNextRefillDateChange($event)"
                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                [class]="{ 'is-invalid': submitted && f.NextRefillDate.errors }" required bsDatepicker [placeholder]="dateformat"
                #dpNext="bsDatepicker" class="form-control">
              <div class="input-group-append">
                <button class="btn btn-outline-primary feather icon-calendar" (click)="dpNext.toggle()" type="button"
                  rippleEffect placement="top"
                  ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
              </div>
            </div>
            <span *ngIf="submitted && f.NextRefillDate.errors">
              <small class="text-danger"
                *ngIf="f.NextRefillDate.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_NEXT_REFILL_DATE_REQUIRED'
                | translate}}</small>
            </span>
          </div>
        </div>
        <!-- </div> -->
        <div class="col-sm-12 pt-1">
          <label class="form-label" for="Notes">{{'MY_MEDICATION_FORM.CONTROL_LABEL_NOTES' | translate}}
            <span ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TEXT_MEDICATION_NOTES'|translate}}" placement="right">
              <span data-feather="help-circle"
                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
          </label>
          <fieldset>
            <textarea class="col-sm-12 form-control" rows="4" formControlName="Notes"
              placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
          </fieldset>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card-footer">
  <div class="align">
    <button type="submit" *ngIf="!IsForEdit" class="btn btn-primary" placement="top" (click)="onSubmit()"
      ngbTooltip="{{'MY_MEDICATION_FORM.BUTTON_TEXT_ADD_A_MEDICATION'|translate}}">{{'MY_MEDICATION_FORM.BUTTON_TEXT_ADD_A_MEDICATION'|translate}}</button>

    <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
      ngbTooltip="{{'COMMON.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>{{'COMMON.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>

    <button type="button" class="btn btn-outline-secondary ml-2" placement="top" (click)="onCloseButtonClick()"
      ngbTooltip="{{'MY_MEDICATION_FORM.BUTTON_TEXT_CANCEL'|translate}}">{{'MY_MEDICATION_FORM.BUTTON_TEXT_CANCEL'|translate}}</button>
  </div>
</div>