import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User, Role, UserSettingsModel } from 'app/auth/models';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { Constant } from 'app/Classes/constant';
import { Status } from 'app/enums';

import { locale as english } from 'app/Logins/i18n/en';
import { locale as french } from 'app/Logins/i18n/fr';
import { locale as german } from 'app/Logins/i18n/de';
import { locale as portuguese } from 'app/Logins/i18n/pt';
import { locale as gujarati } from 'app/Logins/i18n/gj';
import { locale as hindi } from 'app/Logins/i18n/hn';

import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { SignOutInputModel } from 'app/Logins/account-model';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { E } from 'assets/extensions/extensions';
import { InactivityService } from 'app/Services/inactivity-service.service';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  public coreConfig: any;
  public currentAppLanguage: any;
  jsonLanguageObject: any;
  public error: string = "";

  SignOutInputModel: SignOutInputModel = new SignOutInputModel();
  //private
  private currentUserSubject: BehaviorSubject<User>;
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {HttpClient} _http
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(
    private _http: HttpClient,
    private EncrDecr: EncrDecrService,
    private _toastrService: CustomToastrService,
    private _coreConfigService: CoreConfigService,
    private _coreTranslationService: CoreTranslationService,
    private inactivityService: InactivityService,
    private _router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.EncrDecr.get(Constant.EncrDecrKey, localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.coreConfig.app.appLanguage;
    this.jsonLanguageObject = this.GetCurrentLanguageJsonObject(this.currentAppLanguage);
    this.initializeInactivityLogout();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    const user = this.currentUserSubject.value;
    const currentAPIURL = this.getAPIURL();
    
    if (user) {
      if (!user.baseURL || user.baseURL === currentAPIURL) {
        return user;
      } else {
        sessionStorage.clear();
        localStorage.clear();
        return null;
      }
    }
    
    return null;
  }

   getAPIURL(): string {
    const currentURL = window.location.href;
    const sitename = currentURL.split('/').slice(0, 3).join('/');
    return sitename;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.Role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.Role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */

  // login(email: string, password: string) {
  //   return this._http
  //     .post<any>(`${environment.apiUrl}/users/authenticate`, { email, password })
  //     .pipe(
  //       map(user => {
  //         // login successful if there's a jwt token in the response
  //         if (user && user.token) {
  //           // store user details and jwt token in local storage to keep user logged in between page refreshes
  //           localStorage.setItem('currentUser', JSON.stringify(user));

  //           // Display welcome toast!
  //           setTimeout(() => {
  //             this._toastrService.success(
  //               'You have successfully logged in as an ' +
  //                 user.role +
  //                 ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
  //               '👋 Welcome, ' + user.firstName + '!',
  //               { toastClass: 'toast ngx-toastr', closeButton: true }
  //             );
  //           }, 2500);

  //           // notify
  //           this.currentUserSubject.next(user);
  //         }

  //         return user;
  //       })
  //     );
  // }

  // login(body: any, path: string) {
  //   this.jsonLanguageObject = this.GetCurrentLanguageJsonObject(this.currentAppLanguage);
  //   var encrypted = { RequestMessage: this.EncrDecr.set(Constant.EncrDecrKey, JSON.stringify(body)) };
  //   return this._http
  //     .post<any>(Constant.API + path, encrypted)
  //     .pipe(
  //       map(result => {
  //         let decrypted = this.EncrDecr.get(Constant.EncrDecrKey, result.data);
  //         let user: User = JSON.parse(decrypted);
  //         user.role = this.GetRole(user.roleTitle);
  //         user.avatar = 'avatar-s-11.jpg';
  //         // login successful if there's a jwt token in the response
  //         if (user && user.token && user.loginstatus == Status.LoginSuccessful) {
  //         }
  //         return user;
  //       })
  //     );
  // }

  private initializeInactivityLogout() {
    this.inactivityService.activityObservable.subscribe(() => {
      if(JSON.parse(this.EncrDecr.get(Constant.EncrDecrKey, localStorage.getItem("currentUser")))){
        //console.log(JSON.parse(this.EncrDecr.get(Constant.EncrDecrKey, localStorage.getItem("currentUser"))))
      this.logout();
      this._router.navigate(['/pages/authentication/login-v2']);
      setTimeout(() => {
      window.location.reload();
    }, 100);}
    });
  }

  // modalOpenWarning(modalWarning) {
  //   this.modalService.open(modalWarning, {
  //     centered: true,
  //     windowClass: 'modal modal-warning'
  //   });
  //   }

  /**
   * User logout
   *
   */
  async logout() {
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
     // console.log(JSON.parse(this.EncrDecr.get(Constant.EncrDecrKey, localStorage.getItem("tkn"))))
      const path = 'api/Account/SignOut';
      let response: Observable<any>;
      this.SignOutInputModel = {
        UserId: JSON.parse(this.EncrDecr.get(Constant.EncrDecrKey, localStorage.getItem("currentUser"))).Id,  //this._encryptDecryptService.get(Constant.EncrDecrKey, 
        UserSettings: this.GetUserSettings(JSON.parse(this.EncrDecr.get(Constant.EncrDecrKey, localStorage.getItem("currentUser"))).UserDetailsId),
        UserDetailId: this.currentUserValue.UserDetailsId,
        NotificationToken: this.EncrDecr.get(Constant.EncrDecrKey, localStorage.getItem("tkn"))
      }
      let encrypted = { RequestMessage: this.EncrDecr.set(Constant.EncrDecrKey, JSON.stringify(this.SignOutInputModel)) };
      //console.log(encrypted);
      this._http.post(Constant.API + 'api/Account/SignOut', encrypted, { headers: this.SetHeaders() }).subscribe((data: any) => {
        //console.log(data);
        if (data) {
          if (Number(data.status) == Status.Success) {
            //console.log(true);
            // remove user from local storage to log user out
            sessionStorage.clear();
            localStorage.clear();
            sessionStorage.removeItem("NoosomPlan")
            sessionStorage.removeItem('userCategory')
            localStorage.removeItem('currentUser');
            localStorage.removeItem('tkn');
            // notify
            this.currentUserSubject.next(null);
            Result = true;
            resolve(Result)
          }
          else {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
          }
        }
        else {
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
        }
      },
        error => {
          //this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
        }
      );
    });
    return await promise;
  }

  SetHeaders() {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ` + this.currentUserValue.Token
    });
    return headers;
  }

  GetUserSettings(userDetailsId: number): UserSettingsModel {
    let _settings: UserSettingsModel = new UserSettingsModel();
    if (typeof (_settings) !== 'undefined') {
      this._coreConfigService
        .getConfig()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(config => {
          _settings.Theme = config.layout.skin;
          _settings.MenuLayout = config.layout.type;
          _settings.RouteTransition = config.layout.animation;
          _settings.MenuCollapsedYn = config.layout.menu.collapsed;
          _settings.MenuHiddenYn = config.layout.menu.hidden;
          _settings.NavbarColor = config.layout.navbar.backgroundColor;
          _settings.NavbarType = config.layout.navbar.type;
          _settings.FooterType = config.layout.footer.type;
          _settings.UserDetailsId = userDetailsId;
        });
      //console.log(_settings);
    }
    return _settings;
  }

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  SetUserSession(user: User, DisplayToastrMsg = true) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', this.EncrDecr.set(Constant.EncrDecrKey, JSON.stringify(user)));
    // Display welcome toast!
    if (DisplayToastrMsg === true) {
      var title = this.jsonLanguageObject.data.SIGNIN.TOAST_MESSAGE_SUCCESSFULLOGINWELCOMETEXT.replace("{0}", user.FirstName);
      //var msg = this.jsonLanguageObject.data.SIGNIN.TOAST_MESSAGE_SUCCESSFULLOGINMESSAGE.replace("{0}", this.jsonLanguageObject.data.SIGNIN.ROLES.filter(r => r.title == user.RoleTitle)[0].value);
      setTimeout(() => {
       // this._toastrService.Success(msg, title);
       this._toastrService.Success(title);
      }, 2500);
    }
    //        { toastClass: 'toast ngx-toastr', closeButton: true, positionClass: 'toast-top-right' }
    // notify
    this.currentUserSubject.next(user);
  }
  GetRole(RoleTitle: string): Role {
    let Result: Role;
    switch (RoleTitle) {
      case "Admin":
        Result = Role.Admin
        break;
      case "Client":
        Result = Role.Client
        break
      case "User":
        Result = Role.User
        break;
    }
    return Result;
  }
}
