import { Component, ElementRef, OnInit, Pipe, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal, NgbNav, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { Constant } from 'app/Classes/constant';
import { DashboardService } from 'app/dashboard/dashboard.service';
import { FileType, MapModalType, QuickPostType, SharedType, Status } from 'app/enums';
import { GetMyAttachmentsInputModel, GetMyBlogAboutMeDataModel, GetMyBlogAboutMeInputModel, GetMyBlogAboutMeOutputModel, GetOnThisDayInputModel, GetQuickPostListInputModel, MyBlogAboutMeCreateUpdateInputModel, UserQuickPostInputModelShared } from 'app/home/home-model.model';
import { ProfileService } from 'app/main/pages/profile/profile.service';
import { locale as english } from 'app/filemanager/i18n/en';
import { locale as french } from 'app/filemanager/i18n/fr';
import { locale as german } from 'app/filemanager/i18n/de';
import { locale as portuguese } from 'app/filemanager/i18n/pt';
import { locale as gujarati } from 'app/filemanager/i18n/gj';
import { locale as hindi } from 'app/filemanager/i18n/hn';
import { QuickPostCreateUpdateInputModel, QuickPostLoadDataOutputModel, QuickPostModel, TblUserQuickPosts } from 'app/quick-post/quick-post-model.model';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { E } from 'assets/extensions/extensions';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { QuickpostlistService } from 'app/quickpost-list/quickpostlist.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadComponent } from 'app/quick-post/upload/upload.component';
import { QuickPostComponent } from 'app/quick-post/quick-post/quick-post.component';
import { FileUploader, FileItem } from 'ng2-file-upload';
import Swal from 'sweetalert2';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UserProfileService } from 'app/user-profile/user-profile.service';
import { UpdateProfilePictureInputModel } from 'app/about-me/about-me-model.model';
import { LightGallery } from 'lightgallery/lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbnail from 'lightgallery/plugins/thumbnail';
import pager from 'lightgallery/plugins/pager';
import { Clipboard } from '@angular/cdk/clipboard';
import autoPlay from 'lightgallery/plugins/autoplay';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import vimeoVideo from 'lightgallery/plugins/video';
import videoThumbnail from 'lightgallery/plugins/video';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'app/Services/global.service';
import { TrackersComponent } from 'app/quick-post/trackers/trackers.component';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreConfigService } from '@core/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuickPostService } from 'app/quick-post/quick-post/quick-post.service';
import { TabStateServiceService } from 'app/Services/tab-state-service.service';
import { MapAddressDataModel } from 'app/address/map-address/map-address-data-model.model';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import { UserQuickPostsViewsInputModel } from 'app/quick-post/quickpost.model';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  @ViewChild('nav') nav: NgbNav;
  @ViewChild('uploaderContent') uploaderContent: TemplateRef<any>;
  @ViewChild('coverContent') coverContent: TemplateRef<any>;
  @ViewChild('AddressGallery') lightGallery!: LightGallery;
  @ViewChild('pagination', { static: false }) pagination: ElementRef;
  @ViewChild('myPopover', { static: false }) public myPopover: NgbPopover;
  public contentHeader: object;
  public data: any;
  public toggleMenu = true;
  public Monthly = false;
  public toggleNavbarRef = false;
  public loadMoreRef = false;
  public currentUser: User;
  active: string = 'sharedBy';
  _ImageUrlAPI: string
  _ProfilePictureUrl: string = Constant.DefaultProfilePictureURL;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperSettings: any;
  cropuploader: boolean = false; // Add the uploader variable if neede
  cropuploader2: boolean = false; // Add the uploader variable if neede
  imageCroppedEvent: any;
  blob: Blob;
  postId
  friendsList = [];
  familyList = [];
  friendsMainList = [];
  familyMainList = [];
  savedPosition: { x: number; y: number } = { x: 0, y: 0 };
  addQuickPostId
  addQuickpostAttachment
  addQuickpostAttachmenthide
  updateProfilePictureInputModel: UpdateProfilePictureInputModel;
  settings: any = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    pager: false,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide: false,
    plugins: [
      lgZoom,
      // pager,
      autoPlay,
      fullScreen,
      rotate,
      thumbnail,
      videoThumbnail,
      vimeoVideo,
      lgVideo,
      lgvideo,
      //share
    ] // comment, share (options are commented for future use)
  };
  private needRefresh = false;
  galleryRefresh: LightGallery;
  cache: { [key: string]: string } = {};
  public attachmentlist: any = [];
  public attachmentlistimage: any = [];
  // private
  private _unsubscribeAll: Subject<any>;
  tabState;
  /**
     * Constructor
     *
     * @param {PricingService} _pricingService
     */
  tabs = [
    // { id: 'diary', searchParameter:'0', title: 'Diary Entries', icon: 'book' },
    { id: 'sharedBy', title: 'Shared By Me', icon: 'rss' },
    { id: 'sharedWith', title: 'Shared With Me', icon: 'rss' },
    { id: 'public', searchParameter: '4', title: 'My Public Posts', icon: 'globe' },
    // { id: 'photos', title: 'My Public Photos', icon: 'image' },
    { id: 'aboutme', title: 'About Me', icon: 'users' }
  ];
  items = [
    { id: 1, content: "Item 1" },
    { id: 2, content: "Item 2" },
    { id: 3, content: "Item 3" },
    { id: 4, content: "Item 4" },
    { id: 5, content: "Item 5" },
    { id: 6, content: "Item 6" },
    { id: 7, content: "Item 7" },
    { id: 8, content: "Item 8" },
  ];
  public swiperFadeEffect: SwiperConfigInterface = {
    spaceBetween: 30,
    effect: 'cube',
    cubeEffect: {
      slideShadows: false,
      shadow: false
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };
  latestQuickPost: any;
  loading: boolean = false;
  dayFilterOptions
  monthList;
  selectedDateRange
  selectedDateRangeSharedWithMe
  selectedDateRangePublicPost
  selectedMonth: string;
  selectedMonthSharedWithMe: string;
  selectedMonthPublicPost: string;
  fromDate: Date;
  fromDateSharedWithMe: Date;
  fromDatePublicPost: Date;
  toDate: Date;
  toDateSharedWithMe: Date;
  toDatePublicPost: Date;
  onThisday: QuickPostModel[];
  getOnThisDayInputModel: GetOnThisDayInputModel;
  public error = '';
  filteredImage: any;
  names1: any;
  userQuickPosts: QuickPostCreateUpdateInputModel;
  getMyAttachmentsInputModel: GetMyAttachmentsInputModel;
  public pageAdvancedEllipses = 1;
  public pageAdvancedEllipsesSharedWithMe = 1;
  public pageAdvancedEllipsesPublicPost = 1;
  public pagesNumber;
  public frmAboutMe: FormGroup;
  blogAboutMeCreateInputModel: MyBlogAboutMeCreateUpdateInputModel;
  blogAboutMeLoadDataInpurModel: GetMyBlogAboutMeInputModel;
  userBlogs: GetMyBlogAboutMeDataModel;
  blogAboutMeLoadDataOutputModel: GetMyBlogAboutMeOutputModel;
  submitted: boolean = false;
  dateformat: string;
  quickPostType: QuickPostType;
  public coreConfig: any;
  currentAppLanguage: any;
  dateformat2: string;
  shared: SharedType = SharedType.SharedByMe;
  fullName: any;
  activeTab: any;
  constructor(private _pricingService: ProfileService, private _title: Title, private _encryptDecryptService: EncrDecrService, private _authenticationService: AuthenticationService,
    private _toastrService: CustomToastrService,
    private loadingScreenService: LoadingScreenService,
    private quickpostlistService: QuickpostlistService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private _translateService: TranslateService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userProfileService: UserProfileService,
    private globalService: GlobalService,
    private clipboard: Clipboard,
    private _router: Router,
    private _QuickPostService: QuickPostService,
    private tabStateService: TabStateServiceService,
    private route: ActivatedRoute,
  ) {
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._translateService.onLangChange.subscribe(lang => {
      setTimeout(() => {
        let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
        if (_LangJsonObj)
          this.currentAppLanguage = _LangJsonObj;
      }, 100);
    })
    this._title.setTitle("Blog");
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.dayFilterOptions = [
      { value: 0, label: 'Today' },
      { value: 1, label: 'Tomorrow' },
      { value: 2, label: 'Yesterday' },
      { value: 3, label: 'This Week' },
      { value: 4, label: 'Next Week' },
      { value: 5, label: 'Previous Week' },
      { value: 6, label: 'This Month' },
      { value: 7, label: 'Next Month' },
      { value: 8, label: 'Previous Month' },
      { value: 9, label: 'This Year' },
      { value: 10, label: 'Next Year' },
      { value: 11, label: 'Previous Year' }
    ];
    this.dateformat = this.globalService.getDateFormat()
    this.dateformat2 = this.globalService.getDateFormat().toUpperCase();
  }



  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Load More
   */


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this._pricingService.onPricingChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
    //   this.data = response;
    // });
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.currentUser = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, localStorage.getItem('currentUser')));
    this.monthList = this.generateMonthList();
    // this.GetOnThisDayQuickPosts();
    this.userBlogs = new GetMyBlogAboutMeDataModel
    this.frmAboutMe = this._formBuilder.group({
      AboutMeDescription: [''],
      StayAnonymousYn: [''],
      JoinedDate: [''],
      EmailAddress: [''],
      PersonalWebUrl: [''],
      CurrentUserAddress: ['']
    });
    let inputModel = {
      UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId
    };

    this.GetUserConnectionRequests(inputModel)
    //  this.route.queryParams.subscribe(params => {
    //   const tabIndex = params['tabIndex'] ? +params['tabIndex'] : 0; // Default to 0 if no tabIndex provided
    //   this.activeTab = tabIndex; // Set the active tab based on the query parameter
    // });
    this.tabState = 'sharedBy'
    this.initialValues();

  }

  initialValues() {
    var CurrentDate = new Date();
    this.selectedDateRange = 'This Month';
    this.selectedDateRangeSharedWithMe = 'This Month';
    this.selectedDateRangePublicPost = 'This Month';
    const currentDate = new Date();
    const currentMonthYear = currentDate.toLocaleString('en', { month: 'long' }) + ' ' + currentDate.getFullYear();
    this.selectedMonth = currentMonthYear;
    this.selectedMonthSharedWithMe = currentMonthYear;
    this.selectedMonthPublicPost = currentMonthYear;
    this.fromDate = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth(), 1);
    this.fromDateSharedWithMe = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth(), 1);
    this.fromDatePublicPost = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth(), 1);
    this.toDate = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth() + 1, 0);
    this.toDateSharedWithMe = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth() + 1, 0);
    this.toDatePublicPost = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth() + 1, 0);
    this.latestQuickPost = []
    // let getQuickPostListInputModel={
    //   UserDetailsId: this.currentUser.UserDetailsId,
    // StartDate: E.GetDateStringOnly(this.fromDate),
    // EndDate: E.GetDateStringOnly(this.toDate),
    // DocLoadYN: true,
    // GetMetaDataYN:false,
    // NumberOfAttachments:4,
    // FileType:FileType.Images
    // }
    this.onSearchSharedByMe(SharedType.SharedByMe);
    //this.LoadQuickpostList(getQuickPostListInputModel)
  }

  openPopup(data) {
    this.postId = data;
    this.friendsList = this.friendsMainList;
    this.familyList = this.familyMainList;
    this.getUserQuickPostSharingById();
    //this.myPopover.toggle();
  }

  GetUserConnectionRequests(inputModel) {
    let response: Observable<any>;
    this._QuickPostService.GetUserConnectionRequests(inputModel)
      .subscribe(
        (result) => {
          this.loading = false;
          //this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            var connectionRequestData = JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, result.data)))
            this.separateLists(connectionRequestData)
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          // this.loadingScreenService.stopLoading();
        }
      );
  }

  separateLists(data) {
    const filteredDatafriend = data.filter(user => user.UserFriendId !== null && user.ConnectionRequestStatusId == 3);
    this.friendsMainList = filteredDatafriend;
    const filteredData = data.filter(user => user.UserFamilyMemberId !== null && user.ConnectionRequestStatusId == 3);
    this.familyMainList = filteredData;

    
  }

  getUserQuickPostSharingById() {
    let inputModel = {
      SharedFromUserDetailsID: this._authenticationService.currentUserValue.UserDetailsId,
      UserQuickPostID: this.postId
    }
  
    let response: Observable<any>;
    this._QuickPostService.GetUserQuickPostSharingById(inputModel)
      .subscribe(
        (result) => {
        
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            var connectionRequestData = JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, result.data)))
          
            if (connectionRequestData) {
              // Assuming connectionRequestData is an array of objects as shown in the example
              const sharedToUserIds = connectionRequestData.map(data => data.SharedToUserDetailsID);

              this.friendsList = this.friendsList.filter(user =>
                sharedToUserIds.some(id => id === user.ReceiverUserDetailsId)
              );

              this.familyList = this.familyList.filter(user =>
                sharedToUserIds.some(id => id === user.ReceiverUserDetailsId)
              );

           
            }
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  onProfilePhotoReset(type) {
    this.loadingScreenService.startLoading();
    this.updateProfilePictureInputModel = {
      ProfileImageSizeInBytes: 0,
      ProfilePicturePath: "",
      UserDetailsID: this._authenticationService.currentUserValue.UserDetailsId
    }
   
    let api
    if (type == "cover")
      api = this.userProfileService.ResetCoverPicture(this.updateProfilePictureInputModel)
    else if (type == "profile")
      api = this.userProfileService.ResetProfile(this.updateProfilePictureInputModel)

    api.subscribe(
      (result) => {
        this.loadingScreenService.stopLoading();
        var errormessage = "";
        if (result.status == Status.Success) {
          let _profilepath = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, result.data));
        
          let _user = this._authenticationService.currentUserValue;
          if (E.ConvertToString(_profilepath) != "") {
            if (type == "cover")
              _user.CoverImagePath = _profilepath;
            else if (type == "profile") {
              this._ProfilePictureUrl = _profilepath;
              _user.ProfileImagePath = _profilepath;
            }
            this._authenticationService.SetUserSession(_user, false);
            let msg;
            if (type == "cover")
              msg = 'Cover picture reseted successfully.';
            else if (type == "profile")
              msg = 'Profile picture reseted successfully.';
            this._toastrService.Success(msg, Constant.SuccessToastrTitle);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
        else {

          errormessage = E.ConvertToString(result.message);
          this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
        }
      },
      (error) => {
        this.error = error;
        this.loadingScreenService.stopLoading();
      }
    );
  }

  ngAfterViewChecked(): void {
    if (this.quickpostlistService.getGridRefresh()) {
      // let getQuickPostListInputModel = {
      //   UserDetailsId: this.currentUser.UserDetailsId,
      //   StartDate: E.GetDateStringOnly(this.fromDate),
      //   EndDate: E.GetDateStringOnly(this.toDate),
      //   DocLoadYN: true,
      //   GetMetaDataYN: false,
      //   NumberOfAttachments: 4,
      //   FileType: FileType.Images
      // }
      // this.LoadQuickpostList(getQuickPostListInputModel)
      if ((this.tabState == 'diary') || (this.tabState == 'public')) {
        var dataq: GetQuickPostListInputModel = {
          UserDetailsId: this.currentUser.UserDetailsId,
          StartDate: E.GetDateStringOnly(this.fromDate),
          EndDate: E.GetDateStringOnly(this.toDate),
          DocLoadYN: true,
          GetMetaDataYN: false,
          NumberOfAttachments: 4,
          FileType: FileType.Images,
          Page: this.pageAdvancedEllipsesPublicPost,
          PageSize: 20
        }
        if (this.quickPostType != undefined) {
          dataq.QuickPostType = this.quickPostType;
        }
        else {
          dataq.QuickPostType = QuickPostType.All
        }
        this.LoadQuickpostList(dataq);
      }
      else if (this.tabState == 'sharedBy') {
        this.shared = SharedType.SharedByMe
        this.onSearchSharedByMe(this.shared, this.pageAdvancedEllipses);
      }
      else if (this.tabState == 'sharedWith') {
        this.shared = SharedType.SharedWithMe
        this.onSearchSharedByMe(this.shared, this.pageAdvancedEllipsesSharedWithMe);
      }
      this.quickpostlistService.setGridRefresh(false)
    }
    if (this.needRefresh) {
      if (this.lightGallery)
        this.galleryRefresh.refresh();
      this.needRefresh = false;
    }
  }

  generateMonthList(): string[] {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const months = [];

    for (let month = 0; month < 12; month++) {
      const monthDate = new Date(currentYear, month, 1);
      const monthLabel = monthDate.toLocaleString('en', { month: 'long' }) + ' ' + currentYear;
      months.push(monthLabel);
    }

    return months;
  }

  onMainTabActive(tabid, data) {
    this.tabState = tabid

    if ((tabid == 'diary') || (tabid == 'public')) {
      this.quickPostType = data
      this.onSearch(data)

    }
    else if (tabid == 'sharedBy') {
      this.shared = SharedType.SharedByMe
      this.onSearchSharedByMe(this.shared);
      this.fullName = null;
 
    }
    else if (tabid == 'sharedWith') {
      this.shared = SharedType.SharedWithMe
      this.onSearchSharedByMe(this.shared);
 
    }
    // else if (tabid == 'photos') {
    //   this.getAttachmentList('Images')
    // }
    else if (tabid == 'aboutme') {
      this.loadAboutMeData()
    }
  }

  onSearchSharedByMe(data?: SharedType, page?: number): void {
    this.loadingScreenService.startLoading();
    let getQuickPostListInputModel
    var Shared = data ? data : this.shared
    if(Shared == SharedType.SharedByMe){
      getQuickPostListInputModel = {
        UserDetailsId: this.currentUser.UserDetailsId,
        Shared: Shared,
        StartDate: E.GetDateStringOnly(this.fromDate),
        EndDate: E.GetDateStringOnly(this.toDate),
        NumberOfAttachments: 0,
        Page: page ? page : 1,
        PageSize: 20
      }
    }
    else if(Shared == SharedType.SharedWithMe){
      getQuickPostListInputModel = {
        UserDetailsId: this.currentUser.UserDetailsId,
        Shared: Shared,
        StartDate: E.GetDateStringOnly(this.fromDateSharedWithMe),
        EndDate: E.GetDateStringOnly(this.toDateSharedWithMe),
        NumberOfAttachments: 0,
        Page: page ? page : 1,
        PageSize: 20
      }
    }
    if ((getQuickPostListInputModel.Shared != SharedType.SharedWithMe) && (getQuickPostListInputModel.Shared != SharedType.SharedByMe)) {
      this._toastrService.Error("Invalid Shared Type", Constant.ErrorToastrTitle);
    }
    else if (getQuickPostListInputModel.UserDetailsId < 0) {
      this._toastrService.Error("Invalid UserDetails Id", Constant.ErrorToastrTitle);
    }
    else if (getQuickPostListInputModel.StartDate == null || getQuickPostListInputModel.StartDate == undefined || getQuickPostListInputModel.StartDate == '') {
      this._toastrService.Error("Invalid Start Date", Constant.ErrorToastrTitle);
    }
    else if (getQuickPostListInputModel.EndDate == null || getQuickPostListInputModel.EndDate == undefined || getQuickPostListInputModel.EndDate == '') {
      this._toastrService.Error("Invalid End Date", Constant.ErrorToastrTitle);
    }
    else {
      this.loadingScreenService.stopLoading();
      var comp
      if(Shared == 1){comp='sharedWith'}
      else if(Shared == 2){comp='sharedBy'}
     
      if(this.tabState == comp){
        this.latestQuickPost = [];
      this.LoadQuickpostListSharedByMe(getQuickPostListInputModel);}
    }
  }

  onSearch(data?: QuickPostType, page?: number): void {
    this.loadingScreenService.startLoading();
    var filetype;
    if (data != undefined) {
      filetype = data;
    }
    else {
      filetype = QuickPostType.All
    }

    this.latestQuickPost = []
    // this.GetOnThisDayQuickPosts();
    let getQuickPostListInputModel = {
      UserDetailsId: this.currentUser.UserDetailsId,
      StartDate: E.GetDateStringOnly(this.fromDatePublicPost),
      EndDate: E.GetDateStringOnly(this.toDatePublicPost),
      DocLoadYN: true,
      GetMetaDataYN: false,
      NumberOfAttachments: 4,
      FileType: FileType.Images,
      QuickPostType: filetype,
      Page: page ? page : 1,
      PageSize: 20
    }
    this.loadingScreenService.stopLoading();
    this.LoadQuickpostList(getQuickPostListInputModel)
  }

  onDateRangeChange(value: any) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    if (this.tabState == 'sharedBy') {
      this.selectedDateRange = value.label
    }
    else if (this.tabState == 'sharedWith') {
      this.selectedDateRangeSharedWithMe = value.label
    }
    else if (this.tabState == 'public') {
      this.selectedDateRangePublicPost = value.label
    }
    switch (value.value) {
      case 0: // Today
        const today = new Date();
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(today.getMonth(), today.getFullYear());
          this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          this.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(today.getMonth(), today.getFullYear());
          this.fromDateSharedWithMe = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          this.toDateSharedWithMe = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(today.getMonth(), today.getFullYear());
          this.fromDatePublicPost = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          this.toDatePublicPost = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        }
        break;
      case 1: // Tomorrow
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        // this.selectedMonth = this.getMonthLabel(tomorrow.getMonth(), tomorrow.getFullYear());
        // this.fromDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
        // this.toDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(tomorrow.getMonth(), tomorrow.getFullYear());
          this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          this.toDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(tomorrow.getMonth(), tomorrow.getFullYear());
          this.fromDateSharedWithMe = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
          this.toDateSharedWithMe = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(tomorrow.getMonth(), tomorrow.getFullYear());
          this.fromDatePublicPost = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
          this.toDatePublicPost = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
        }
        break;
      case 2: // Yesterday
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(yesterday.getMonth(), yesterday.getFullYear());
          this.fromDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
          this.toDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(yesterday.getMonth(), yesterday.getFullYear());
          this.fromDateSharedWithMe = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
          this.toDateSharedWithMe = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(yesterday.getMonth(), yesterday.getFullYear());
          this.fromDatePublicPost = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
          this.toDatePublicPost = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
        }
        break;
      case 3: // This Week
        const today1 = new Date();
        const dayOfWeek = today1.getDay();
        const firstDayOfWeek = new Date(today1);
        const lastDayOfWeek = new Date(today1);

        // Set the first day of the week to Monday
        firstDayOfWeek.setDate(today1.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

        // Set the last day of the week to Sunday
        lastDayOfWeek.setDate(today1.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : 7));
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(today1.getMonth(), today1.getFullYear());
          this.fromDate = firstDayOfWeek;
          this.toDate = lastDayOfWeek;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(today1.getMonth(), today1.getFullYear());
          this.fromDateSharedWithMe = firstDayOfWeek;
          this.toDateSharedWithMe = lastDayOfWeek;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(today1.getMonth(), today1.getFullYear());
          this.fromDatePublicPost = firstDayOfWeek;
          this.toDatePublicPost = lastDayOfWeek;
        }
        break;

      case 4: // Next Week
        //const today = new Date();
        const nextWeekStart = new Date(currentDate);
        nextWeekStart.setDate(currentDate.getDate() + (1 - currentDate.getDay()) + 7); // Add 7 days for next week
        const nextWeekEnd = new Date(nextWeekStart);
        nextWeekEnd.setDate(nextWeekStart.getDate() + 6);


        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(nextWeekStart.getMonth(), nextWeekStart.getFullYear());
          this.fromDate = nextWeekStart;
          this.toDate = nextWeekEnd;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(nextWeekStart.getMonth(), nextWeekStart.getFullYear());
          this.fromDateSharedWithMe = nextWeekStart;
          this.toDateSharedWithMe = nextWeekEnd;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(nextWeekStart.getMonth(), nextWeekStart.getFullYear());
          this.fromDatePublicPost = nextWeekStart;
          this.toDatePublicPost = nextWeekEnd;
        }
        break;


      case 5: // Previous Week
        const previousWeekStart = new Date();
        previousWeekStart.setDate(previousWeekStart.getDate() - (previousWeekStart.getDay() === 0 ? 6 : previousWeekStart.getDay() - 1) - 7);
        const previousWeekEnd = new Date(previousWeekStart);
        previousWeekEnd.setDate(previousWeekEnd.getDate() + 6);

        
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(previousWeekStart.getMonth(), previousWeekStart.getFullYear());
        this.fromDate = previousWeekStart;
        this.toDate = previousWeekEnd;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(previousWeekStart.getMonth(), previousWeekStart.getFullYear());
          this.fromDateSharedWithMe = previousWeekStart;
          this.toDateSharedWithMe = previousWeekEnd;;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(previousWeekStart.getMonth(), previousWeekStart.getFullYear());
          this.fromDatePublicPost = previousWeekStart;
          this.toDatePublicPost = previousWeekEnd;;
        }
        break;

      case 6: // This Month
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
      
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(currentMonth, currentYear);
          this.fromDate = firstDayOfMonth;
          this.toDate = lastDayOfMonth;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(currentMonth, currentYear);
          this.fromDateSharedWithMe = firstDayOfMonth;
          this.toDateSharedWithMe = lastDayOfMonth;;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(currentMonth, currentYear);
          this.fromDatePublicPost = firstDayOfMonth;
          this.toDatePublicPost = lastDayOfMonth;;
        }
        break;
      case 7: // Next Month
        const nextMonth = new Date(currentYear, currentMonth + 1, 1);
        const firstDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1);
        const lastDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0);
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(nextMonth.getMonth(), nextMonth.getFullYear());
          this.fromDate = firstDayOfNextMonth;
          this.toDate = lastDayOfNextMonth;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(nextMonth.getMonth(), nextMonth.getFullYear());
          this.fromDateSharedWithMe = firstDayOfNextMonth;
          this.toDateSharedWithMe = lastDayOfNextMonth;;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(nextMonth.getMonth(), nextMonth.getFullYear());
          this.fromDatePublicPost = firstDayOfNextMonth;
          this.toDatePublicPost = lastDayOfNextMonth;;
        }
        break;
      case 8: // Previous Month
        const previousMonth = new Date(currentYear, currentMonth - 1, 1);
        const firstDayOfPreviousMonth = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
        const lastDayOfPreviousMonth = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0);
       
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(previousMonth.getMonth(), previousMonth.getFullYear());
        this.fromDate = firstDayOfPreviousMonth;
        this.toDate = lastDayOfPreviousMonth;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(previousMonth.getMonth(), previousMonth.getFullYear());
          this.fromDateSharedWithMe = firstDayOfPreviousMonth;
          this.toDateSharedWithMe = lastDayOfPreviousMonth;;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(previousMonth.getMonth(), previousMonth.getFullYear());
          this.fromDatePublicPost = firstDayOfPreviousMonth;
          this.toDatePublicPost = lastDayOfPreviousMonth;;
        }
        break;
      case 9: // This Year
        const firstDayOfYear = new Date(currentYear, 0, 1);
        const lastDayOfYear = new Date(currentYear, 11, 31);
        
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(currentMonth, currentYear);
        this.fromDate = firstDayOfYear;
        this.toDate = lastDayOfYear;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(currentMonth, currentYear);
          this.fromDateSharedWithMe = firstDayOfYear;
          this.toDateSharedWithMe = lastDayOfYear;;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(currentMonth, currentYear);
          this.fromDatePublicPost = firstDayOfYear;
          this.toDatePublicPost = lastDayOfYear;;
        }
        break;
      case 10: // Next Year
        const nextYear = new Date(currentYear + 1, 0, 1);
        const firstDayOfNextYear = new Date(nextYear.getFullYear(), 0, 1);
        const lastDayOfNextYear = new Date(nextYear.getFullYear(), 11, 31);
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(nextYear.getMonth(), nextYear.getFullYear());
          this.fromDate = firstDayOfNextYear;
          this.toDate = lastDayOfNextYear;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(nextYear.getMonth(), nextYear.getFullYear());
          this.fromDateSharedWithMe = firstDayOfNextYear;
          this.toDateSharedWithMe = lastDayOfNextYear;;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(nextYear.getMonth(), nextYear.getFullYear());
          this.fromDatePublicPost = firstDayOfNextYear;
          this.toDatePublicPost = lastDayOfNextYear;;
        }
        break;
      case 11: // Previous Year
        const previousYear = new Date(currentYear - 1, 0, 1);
        const firstDayOfPreviousYear = new Date(previousYear.getFullYear(), 0, 1);
        const lastDayOfPreviousYear = new Date(previousYear.getFullYear(), 11, 31);
    
        if (this.tabState == 'sharedBy') {
          this.selectedMonth = this.getMonthLabel(previousYear.getMonth(), previousYear.getFullYear());
          this.fromDate = firstDayOfPreviousYear;
          this.toDate = lastDayOfPreviousYear;
        }
        else if (this.tabState == 'sharedWith') {
          this.selectedMonthSharedWithMe = this.getMonthLabel(previousYear.getMonth(), previousYear.getFullYear());
          this.fromDateSharedWithMe = firstDayOfPreviousYear;
          this.toDateSharedWithMe = lastDayOfPreviousYear;;
        }
        else if (this.tabState == 'public') {
          this.selectedMonthPublicPost = this.getMonthLabel(previousYear.getMonth(), previousYear.getFullYear());
          this.fromDatePublicPost = firstDayOfPreviousYear;
          this.toDatePublicPost = lastDayOfPreviousYear;;
        }
        break;

    }
  }

  onMonthChange(selectedMonth: string): void {
    // Parse the selected month to extract month and year
    const [month, year] = selectedMonth.split(' ');

    // Calculate the first and last day of the selected month
    const firstDayOfMonth = new Date(`${month} 1, ${year}`);
    const lastDayOfMonth = new Date(new Date(firstDayOfMonth).setMonth(firstDayOfMonth.getMonth() + 1) - 1);

    // Update fromDate and toDate
   
    if (this.tabState == 'sharedBy') {
      this.fromDate = firstDayOfMonth;
      this.toDate = lastDayOfMonth;
    }
    else if (this.tabState == 'sharedWith') {
      this.fromDateSharedWithMe = firstDayOfMonth;
      this.toDateSharedWithMe = lastDayOfMonth;;
    }
    else if (this.tabState == 'public') {
      this.fromDatePublicPost = firstDayOfMonth;
      this.toDatePublicPost = lastDayOfMonth;;
    }
  }

  validateDates(name, event) {
    // Parse the selected dates
    if (name == 'fromDate') {
      if (event == null) {
        //  this.fromDate = new Date(this.toDate);
      }
      else { 
        if (this.tabState == 'sharedBy') {
          this.fromDate = new Date(event)
        }
        else if (this.tabState == 'sharedWith') {
          this.fromDateSharedWithMe = new Date(event)
        }
        else if (this.tabState == 'public') {
          this.fromDatePublicPost = new Date(event)
        }
       }

    }
    if (name == 'toDate') {
      if (event == null) {
        // this.toDate = new Date(this.fromDate);
      }
      else {
       
        if (this.tabState == 'sharedBy') {
          this.toDate = new Date(event)
        }
        else if (this.tabState == 'sharedWith') {
          this.toDateSharedWithMe = new Date(event)
        }
        else if (this.tabState == 'public') {
          this.toDatePublicPost = new Date(event)
        }
      }
    }
    if (this.tabState == 'sharedBy') {
      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);
      if (fromDate > toDate) {
        const temp = this.fromDate;
        this.fromDate = this.toDate;
        this.toDate = temp;
      }
    }
    else if (this.tabState == 'sharedWith') {
      const fromDate = new Date(this.fromDateSharedWithMe);
      const toDate = new Date(this.toDateSharedWithMe);
      if (fromDate > toDate) {
        const temp = this.fromDateSharedWithMe;
        this.fromDateSharedWithMe = this.toDateSharedWithMe;
        this.toDateSharedWithMe = temp;
      }
    }
    else if (this.tabState == 'public') {
      const fromDate = new Date(this.fromDatePublicPost);
      const toDate = new Date(this.toDatePublicPost);
      if (fromDate > toDate) {
        const temp = this.fromDatePublicPost;
        this.fromDatePublicPost = this.toDatePublicPost;
        this.toDatePublicPost = temp;
      }
    }
  }


  getMonthLabel(month: number, year: number): string {
    const monthDate = new Date(year, month, 1);
    return monthDate.toLocaleString('en', { month: 'long' }) + ' ' + year;
  }

  formatDate(date: Date): string {
    return (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  GetOnThisDayQuickPosts(date?) {
    let response: Observable<any>;
    this.loading = false;
    this.loadingScreenService.startLoading();


    const todayDate = date ? date : E.GetTodayDateStringOnly();
    //  const dateStr = "2024-03-03";
    //  const todayDate =  E.GetDateStringOnly(new Date(dateStr));

    this.getOnThisDayInputModel = {
      TodayDate: todayDate,
      UserDetailsId: this.currentUser.UserDetailsId,
      GetMetaDataYN: false,
      AttachmentCount: 5,
    };

    this.quickpostlistService.GetOnThisDayQuickPosts(this.getOnThisDayInputModel)
      .subscribe(
        (data) => {
          if (data.status == Status.Success) {
            const _data = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, data.data));
            this.onThisday = _data;
            if (_data.length == 0) {
              let onThisday;
              const currentDate = new Date();
              // Initialize onThisday with sample data
              onThisday = [
                {
                  "UserQuickPostId": 0,
                  "UserDetailsId": 180,
                  "QuickPostTypeId": 1,
                  "MoodId": null,
                  "MoodRatingId": null,
                  "Title": "Memory of this day",
                  "DiaryDate": new Date(currentDate),
                  "DiaryTimeFrom": new Date("1900-01-01T00:00:00"),
                  "DiaryTimeTo": new Date("1900-01-01T23:59:00"),
                  "PostContent": "This is a memory of this day.",
                  "Location": "Location",
                  "Latitude": 0,
                  "Longitude": 0,
                  "TagCategory": "[{\"id\":0,\"name\":\"Memories\",\"tag\":true},{\"id\":1,\"name\":\"On This Day\",\"tag\":true}]",
                  "QuickPostDocumentList": []
                },
                {
                  "UserQuickPostId": 0,
                  "UserDetailsId": 180,
                  "QuickPostTypeId": 1,
                  "MoodId": null,
                  "MoodRatingId": null,
                  "Title": "Memory of this day",
                  "DiaryDate": new Date(currentDate),
                  "DiaryTimeFrom": new Date("1900-01-01T00:00:00"),
                  "DiaryTimeTo": new Date("1900-01-01T23:59:00"),
                  "PostContent": "This is a memory of this day.",
                  "Location": "Location",
                  "Latitude": 0,
                  "Longitude": 0,
                  "TagCategory": "[{\"id\":0,\"name\":\"Memories\",\"tag\":true},{\"id\":1,\"name\":\"On This Day\",\"tag\":true}]",
                  "QuickPostDocumentList": []
                }
              ];
              this.onThisday = onThisday
            }
          
            if (this.loading)
              this.loadingScreenService.stopLoading();
          } else {
            this.loadingScreenService.stopLoading();
            if (this.loading)
              this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loadingScreenService.stopLoading();
        }
      );
  }


  getYearsAgo(dateString: string): number {
    const currentDate = new Date();
    const diaryDate = new Date(dateString);
    const yearsDiff = currentDate.getFullYear() - diaryDate.getFullYear();
    return yearsDiff;
  }

  LoadQuickpostListSharedByMe(data: UserQuickPostInputModelShared) {
  
    this.loadingScreenService.startLoading();
    this.quickpostlistService.GetUserQuickPostsCommonSharingControl(data).subscribe((result) => {
      if (result.status == Status.Success) {
        let _data = JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, result.data)))
        if (this.fullName) {
          let filteredData = _data.filter(item =>
            item.FullName.toLocaleLowerCase().includes(this.fullName.toLocaleLowerCase())
          );
          this.latestQuickPost = filteredData;
          if (this.latestQuickPost[0]?.TotalNumberOfRecords > 0)
            this.pagesNumber = this.latestQuickPost[0]?.TotalNumberOfRecords;
        }
        else {
          this.latestQuickPost = _data
          if (this.latestQuickPost[0]?.TotalNumberOfRecords > 0)
            this.pagesNumber = this.latestQuickPost[0]?.TotalNumberOfRecords;
        }
        
        this.loading = true;
        this.loadingScreenService.stopLoading();
      }
      this.loadingScreenService.stopLoading();
    },
      (error) => {
        // this.error = error;
        this.loading = false;
        this.loadingScreenService.stopLoading();
      }
    );
  }

  LoadQuickpostList(data: GetQuickPostListInputModel) {
    this.loadingScreenService.startLoading();
    this.quickpostlistService.GetUserQuickPostList(data).subscribe((result) => {
      if (result.status == Status.Success) {
        let _data = JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, result.data)))
        this.latestQuickPost = _data
        if (this.latestQuickPost[0]?.TotalNumberOfRecords > 0)
          this.pagesNumber = this.latestQuickPost[0]?.TotalNumberOfRecords;
        this.loading = true;
        this.loadingScreenService.stopLoading();
      }
    },
      (error) => {
        // this.error = error;
        this.loading = false;
        this.loadingScreenService.stopLoading();
      }
    );
  }
  get imageArray(): { id: number; name: string; tag: boolean }[][] {
    return this.filteredImage.map(post => this.imagetag(post));
  }

  imagetag(imagefun) {
    let jsonArrayImage = imagefun
    const filteredImage = jsonArrayImage.filter(item => item.DocumentSubType === "Images").map(item => item.DocumentPath)

    return filteredImage
  }

  get tagsArray(): { id: number; name: string; tag: boolean }[][] {
    return this.names1.map(post => this.parseTags(post.TagCategory));
  }
  // let jsonString = _data[0].TagCategory;
  // let jsonArray = JSON.parse(jsonString);

  // this.names1 = jsonArray.filter(item => item.tag).map(item => item.name);

  parseTags(tagCategory: string): { id: number; name: string; tag: boolean }[] {
    const tags = JSON.parse(tagCategory);
    return tags.filter(tag => tag.tag);
  }

  openDialog(component: any, _data: any = []) {
    this.loadingScreenService.startLoading();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "66%"
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterOpened().subscribe(() => {
      this.loadingScreenService.stopLoading();
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          if ((this.tabState == 'diary') || (this.tabState == 'public')) {
            var dataq: GetQuickPostListInputModel = {
              UserDetailsId: this.currentUser.UserDetailsId,
              StartDate: E.GetDateStringOnly(this.fromDatePublicPost),
              EndDate: E.GetDateStringOnly(this.toDatePublicPost),
              DocLoadYN: true,
              GetMetaDataYN: false,
              NumberOfAttachments: 4,
              FileType: FileType.Images,
              Page: this.pageAdvancedEllipsesPublicPost,
              PageSize: 20
            }
            if (this.quickPostType != undefined) {
              dataq.QuickPostType = this.quickPostType;
            }
            else {
              dataq.QuickPostType = QuickPostType.All
            }
            this.LoadQuickpostList(dataq);
          }
          else if (this.tabState == 'sharedBy') {
            this.shared = SharedType.SharedByMe
            this.onSearchSharedByMe(this.shared, this.pageAdvancedEllipses);
          }
          else if (this.tabState == 'sharedWith') {
            this.shared = SharedType.SharedWithMe
            this.onSearchSharedByMe(this.shared, this.pageAdvancedEllipsesSharedWithMe);
          }
        }
      }
    );
  }
  EditQuickPost(id) {
    if (id == 0) {
      this.openDialog(QuickPostComponent, id)
    }
    else {
      this.openDialog(UploadComponent, id);
    }
  }

  onImageUpload(event: any): void {
    this.cropuploader = true;
    setTimeout(() => {
      const file: File = event.target.files[0];
      if (!file.type.startsWith('image/')) {
        this._toastrService.Error('Select only Images.', Constant.ErrorToastrTitle);
        return;
      }
      this.openUploaderModal(this.uploaderContent)
      this.imageCroppedEvent = event;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.croppedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }, 200);
  }

  onCoverImageUpload(event: any): void {
    this.cropuploader2 = true;
    setTimeout(() => {
      const file: File = event.target.files[0];
      if (!file.type.startsWith('image/')) {
        this._toastrService.Error('Select only Images.', Constant.ErrorToastrTitle);
        return;
      }
      this.openUploaderModal(this.coverContent)
      this.imageCroppedEvent = event;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.croppedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }, 200);
  }

  supportFunction(url) {
    if (this.currentUser) {
  
      this._ProfilePictureUrl = E.ConvertToString(this.currentUser.ProfileImagePath);
      if (this._ProfilePictureUrl == "") {
        this._ProfilePictureUrl = Constant.DefaultProfilePictureURL;
      }
    }

    Swal.fire({
      imageUrl: url,
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      customClass: {
        confirmButton: 'btn btn-primary',
      }

    })

  }
  onImageCropped(event: ImageCroppedEvent): void {
   
    this.blob = event.blob;
    if (!this.blob) {
      // console.error('No blob data found');
      return;
    }



  }

  openUploaderModal(content) {

    this.modalService.open(content, { centered: true }); // 'lg' for large size, adjust as needed
  }

  openUploaderModal1(content) {
   
    this.modalService.open(content, { centered: true, size: 'sm' }); // 'lg' for large size, adjust as needed
  }


  base64ToFile(dataURL: string, filename: string): File {
   
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  cropUpload(data) {
    const file = new File([this.blob], 'cropped_image.png', { type: this.blob.type });
    if (data == 'profilepicture') {
      this._ImageUrlAPI = Constant.API + 'api/AboutMe/UpdateProfilePicture';
    } else if (data == 'coverpicture') {
      this._ImageUrlAPI = Constant.API + 'api/AboutMe/UpdateCoverPicture';
    }
    this.cropuploader = false;
    this.cropuploader2 = false;
    this.modalService.dismissAll();
    const croppedUploader = new FileUploader({
      url: this._ImageUrlAPI,
      isHTML5: true,
      autoUpload: true,
      removeAfterUpload: true,
      method: "PUT",
      itemAlias: "MultiFileUploaderProfilePicture",
      disableMultipart: false,
      formatDataFunction: (item: FileItem) => item._file,
      formatDataFunctionIsAsync: false,
      maxFileSize: 50 * 1024 * 1024,
      authToken: `Bearer ` + this._authenticationService.currentUserValue.Token,
      allowedFileType: ["image", "tiff", "bmp", "png", "jpg", "jpeg"]
    });
    croppedUploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
   
    };
    croppedUploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
 
    };
    croppedUploader.onBeforeUploadItem = (fileItem) => {

      croppedUploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this._authenticationService.currentUserValue.UserDetailsId) }]
      });
 
      return fileItem;
    };
    let _user = this._authenticationService.currentUserValue;
    croppedUploader.response.subscribe(result => {
      if (result) {
        let _result = JSON.parse(result);

        if (_result.status == Status.Success) {
          let _profilepath = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, _result.data));
          if (E.ConvertToString(_profilepath) != "") {
            if (data == 'profilepicture') {
              this._ProfilePictureUrl = _profilepath;
              _user.ProfileImagePath = _profilepath;
            }
            else if (data == 'coverpicture') {
              _user.CoverImagePath = _profilepath;
            }
            this._authenticationService.SetUserSession(_user, false);
            //this.reloadComponent(false,'');
            let msg = 'Profile picture updated successfully.';
            this._toastrService.Success(msg, Constant.SuccessToastrTitle);
            this.cropuploader = false;
            this.cropuploader2 = false;
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          else {
            this._toastrService.Error('Profile picture could not be updated.', Constant.ErrorToastrTitle);
          }
        }
        else if (_result.status == Status.StorageFull) {
          this._toastrService.Warning(_result.message, Constant.WarningToastrTitle);
        }
        else {
          this._toastrService.Error(_result.message, Constant.ErrorToastrTitle);
        }
      }
    });
    
    croppedUploader.addToQueue([file]);
    croppedUploader.uploadAll();
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    this._toastrService.Success('Copied.', Constant.SuccessToastrTitle);
  }

  openURL(url) {
    // window.open(url, "_blank","noopener");
    const protocol = /^https?:\/\//i;
    const url1 = protocol.test(url) ? url : `http://${url}`;
    window.open(url1, '_blank');
  }


  onInitGallery = (detail) => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    this.galleryRefresh.settings.licenseKey = this._authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey;
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
  <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    const $toolbar = this.lightGallery.$toolbar;

    $toolbar.append(customHelpButton);



    document.getElementById("lg-custom-button-help").addEventListener("click", () => {
      this.supportFunctionGallery();
    });

  };
  supportFunctionGallery() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/raw-icon-labels.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: "cancel",
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })

  }

  onTabActive(data) {
    if (data == 'Images') {
      this.getAttachmentList('Images')
    }
    else if (data == 'Videos') {
      this.getAttachmentList('Videos')
    }
  }

  // savePosition() {
  //   const { left, top } = this.pagination.nativeElement.getBoundingClientRect();
  //   this.savedPosition = { x: left, y: top };
  // }

  // restorePosition() {
  //   if (this.pagination) {
  //     this.pagination.nativeElement.style.transform = `translate(${this.savedPosition.x}px, ${this.savedPosition.y}px)`;
  //   }
  // }

  loadPage(event, filetype) {
    // this.savePosition();
    this.pageAdvancedEllipses
    this.getAttachmentList(filetype, event)
    // setTimeout(() => this.restorePosition(), 0);
  }

  getAttachmentList(FileType?, page?) {
    this.attachmentlistimage = []
    this.getMyAttachmentsInputModel = {
      UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
      Page: page,
      Pagesize: 20,
      FileType: FileType,
      QuickPostType: QuickPostType.Public,
      GetMetaDataYN: false
    }
    this.loadingScreenService.startLoading();
    this.quickpostlistService.GetMyAttachments(this.getMyAttachmentsInputModel).subscribe(async result => {
      if (result) {
        if (result.status == Status.Success) {
          let _attachmentlist = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, result.data));
        
          if (_attachmentlist) {
            const imageDocs = _attachmentlist.filter(doc => doc.FileType.toLocaleLowerCase() === "images")
            const videoDocs = _attachmentlist.filter(doc => doc.FileType.toLocaleLowerCase() === "videos")
            
            if (_attachmentlist[0]?.TotalNumberOfRecords > 0)
              this.pagesNumber = _attachmentlist[0]?.TotalNumberOfRecords;
            const imagePromises = imageDocs.map(doc =>
              this.AddItemToGallery1(doc.AttachmentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType)
            );

            const videoPromises = videoDocs.map(doc =>
              this.AddItemToGallery(doc.AttachmentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType)
            );

            await Promise.all(imagePromises);
            await Promise.all(videoPromises);
            this.needRefresh = true;
          }
          this.loadingScreenService.stopLoading();
        }
        else {
          this.loadingScreenService.stopLoading();
          this._toastrService.Error(result.Message, Constant.ErrorToastrTitle);
        }
      }
    });
  }

  async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string): Promise<any> {
    this.attachmentlistimage = [
      ...this.attachmentlistimage,
      {
        id: _id,
        size: '280-100',
        src: _url,
        data: documentname,
        thumb: _url,
        subHtml: documentname, documentsize,
        type: filetype,
      }
    ];
    this.needRefresh = true;
  }


  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
    };

    this.attachmentlistimage.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache

    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }

    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
    };
    const index = this.attachmentlistimage.findIndex(item => item.id === _id);
    this.attachmentlistimage[index] = galleryItem;
    
  }


  async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
    if (type === "Videos") {
      return new Promise<string>((resolve, reject) => {
        const video = document.createElement('video');
        video.crossOrigin = 'anonymous'; // Enable cross-origin for video
        video.src = videoURL;

        video.addEventListener('loadedmetadata', () => {
          video.currentTime = 10.5; // Adjust as needed

          video.addEventListener('seeked', () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Draw a play icon in the center of the canvas
            const playIconSize = 250; // Adjust the size as needed
            ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
            ctx.beginPath();
            ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
            ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
            ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
            ctx.closePath();
            ctx.fill();

            // Resolve with the data URL of the canvas
            resolve(canvas.toDataURL('image/png'));

            // Clean up
            canvas.remove();
            video.remove();
          });

          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video: ${error.message}`);
          });
        });

        video.addEventListener('error', (error) => {
          resolve("assets/images/pages/Cannot_play.png")
          //  reject(`Error loading video metadata: ${error.message}`);
        });

        // Load the video
        video.load();
      });
    }
  }

  async generateVideoData(src): Promise<string> {
    const videoData = {
      source: [{ src: src, type: 'video/mp4' }],
      attributes: { preload: true, controls: true }
    };
    return JSON.stringify(videoData);
  }


  loadAboutMeData() {
    setTimeout(() => {
      this.loadingScreenService.startLoading();
      this.blogAboutMeLoadDataInpurModel = {
        UserDetailsId: this.currentUser.UserDetailsId
      }
      this.quickpostlistService.GetAboutMeData(this.blogAboutMeLoadDataInpurModel)
        .subscribe(
          (data) => {

           
            if (data.status == Status.Success) {
              let aboutmeData = JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, data.data)));
              this.userBlogs = aboutmeData?.AboutMeData
  
              if (this.userBlogs) {
                //this.userAwards = this.awardLoadDataOutputModel.UserAward;
                this.frmAboutMe.patchValue({
                  StayAnonymousYn: this.userBlogs.StayAnonymousYn,
                  AboutMeDescription: this.userBlogs.AboutMeDescription,
                  JoinedDate: this.userBlogs.JoinedDate,
                  EmailAddress: this.userBlogs.EmailAddress,
                  PersonalWebUrl: this.userBlogs.PersonalWebUrl,
                  CurrentUserAddress: this.userBlogs.CurrentUserAddress,
                });
              }
            }
            else {
              this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
            }
            this.loadingScreenService.stopLoading();
          },
          (error) => {
            this._toastrService.Error(error, Constant.ErrorToastrTitle);
            this.error = error;
            this.loadingScreenService.stopLoading();
          }
        );

    }, 500);
  }

  addToMyDiary(latestQuickPost) {
    this.loadData(latestQuickPost.UserQuickPostId)

  }
  loadData(data) {
    const path = 'api/UserQuickPost/GetUserQuickPostLoadData';
    let response: Observable<any>;
    var input = {
      recordId: data,
      docYN: true
    }
    response = this.globalService.PostDataById(input, path);
    response.subscribe(
      (result) => {
        this.loadingScreenService.stopLoading();
        if (result.status == Status.Success) {

          var quickpost = <QuickPostLoadDataOutputModel>JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, result.data)));
          this.addQuickpost(quickpost.UserQuickPost);
          this.addQuickpostAttachment = quickpost.QuickPostDocumentList.map(item => item.DocumentPath);
        }
        else {
        }
      },
      (error) => {
        this._toastrService.Error(error, Constant.ErrorToastrTitle);
        this.error = error;
        this.loadingScreenService.stopLoading();
      }
    );

  }


  addQuickpost(quickPost: TblUserQuickPosts) {

    if (this.userQuickPosts == null) {
      this.userQuickPosts = new QuickPostCreateUpdateInputModel();
    }
    this.userQuickPosts.UserDetailsId = this._authenticationService.currentUserValue.UserDetailsId;
    this.userQuickPosts.Title = quickPost.Title
    this.userQuickPosts.DiaryDate = quickPost.DiaryDate;
    this.userQuickPosts.DiaryTimeFrom = quickPost.DiaryTimeFrom;// '1900-01-01 10:30:00.000';
    this.userQuickPosts.DiaryTimeTo = quickPost.DiaryTimeTo;// '1900-01-01 10:30:00.000';
    this.userQuickPosts.Location = quickPost.Location;
    this.userQuickPosts.QuickPostTypeId = quickPost.QuickPostTypeId;
    this.userQuickPosts.MoodId = quickPost.MoodId
    this.userQuickPosts.MoodRatingId = quickPost.MoodRatingId
    this.userQuickPosts.TagCategory = quickPost.TagCategory;
    this.userQuickPosts.PostContent = quickPost.PostContent;
    this.userQuickPosts.Latitude = quickPost.Latitude;
    this.userQuickPosts.Longitude = quickPost.Longitude;
    if (E.ConvertToNumber(this.userQuickPosts.UserQuickPostId) == 0) {
      this.userQuickPosts.CreatedBy = this._authenticationService.currentUserValue.UserDetailsId;
      this.userQuickPosts.CreatedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.CreatedTime = E.GetTodayTimeStringOnly();
      this.userQuickPosts.ChangedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.ChangedTime = E.GetTodayTimeStringOnly();
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
    const path = 'api/UserQuickPost/CreateUpdateUserQuickPost';
    let response: Observable<any>;
    response = this.globalService.PostDataById(this.userQuickPosts, path);
    response.subscribe(
      (result) => {
        this.loading = false;
        this.loadingScreenService.stopLoading();
        var message = ""; var errormessage = "";
        if (result.status == Status.RecordAdded) {
          message = 'Post added to your diary';
          const jsonString = this._encryptDecryptService.get(Constant.EncrDecrKey, result.data);
          const parsedObject = JSON.parse(jsonString);
          this.addQuickPostId = parsedObject.Data.UserQuickPostId
          if (this.addQuickPostId && this.addQuickpostAttachment)
            this.newImagestoUpload(this.addQuickpostAttachment)

        }
        else if (result.status == Status.RecordUpdated) {
          message = 'Updated';
        }
        else {
          errormessage = E.ConvertToString(result.message);
        }
        if (message.length > 0) {
          this._toastrService.Success(message, Constant.SuccessToastrTitle);

        }
        else {
          this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
        }
      },
      (error) => {
        this._toastrService.Error(error, Constant.ErrorToastrTitle);
        this.error = error;
        this.loading = false;
        this.loadingScreenService.stopLoading();
      }
    );
  }

  // 
  fetchDocuments(cdnUrls: string[]): Promise<File[]> {
    return Promise.all(cdnUrls.map(cdnUrl => this.fetchDocument(cdnUrl)));
  }

  fetchDocument(cdnUrl: string): Promise<File> {
    return fetch(cdnUrl)
      .then(response => response.blob())
      .then(blob => {
        const contentType = blob.type;
        const fileName = cdnUrl.split('/').pop();
        return new File([blob], fileName, { type: contentType });
      });
  }



  newImagestoUpload(file) {
    const croppedUploader = new FileUploader({
      url: Constant.API + 'api/UserQuickPost/UploadUserQuickPostDocuments',
      isHTML5: true,
      autoUpload: true,
      removeAfterUpload: true,
      method: "PUT",
      itemAlias: "MultiFileUploaderProfilePicture",
      disableMultipart: false,
      formatDataFunction: (item: FileItem) => item._file,
      formatDataFunctionIsAsync: false,
      maxFileSize: 50 * 1024 * 1024,
      authToken: `Bearer ` + this._authenticationService.currentUserValue.Token,
      //  allowedFileType: ["image", "tiff", "bmp", "png", "jpg", "jpeg", "video", "mp4", "mpeg", "mov", "avi", "ogv", "webm", "flv","Images"]
    });

    this.fetchDocuments(file).then(files => {

      this.loadingScreenService.startLoading();
      if (files && files.length > 0) {
        // this.uploader.clearQueue();
        croppedUploader.addToQueue(files);
      }
      this.loadingScreenService.stopLoading();
    }).catch(error => {
      console.error('Error fetching documents from CDN:', error);
    });
    croppedUploader.uploadAll();

    croppedUploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
   
    };
    croppedUploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
   
    };
    croppedUploader.onBeforeUploadItem = (fileItem) => {

      croppedUploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this._authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserQuickPostID', value: E.ConvertToString(this.addQuickPostId) }]
      });
  
      return fileItem;
    };

    let _user = this._authenticationService.currentUserValue;
    croppedUploader.response.subscribe(result => {
      if (result) {
        let _result = JSON.parse(result);

        if (_result.status == Status.Success) {
          let _profilepath = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, _result.data));
       
        }
        else if (_result.status == Status.StorageFull) {
          this._toastrService.Warning(_result.message, Constant.WarningToastrTitle);
        }
        else {
          this._toastrService.Error(_result.message, Constant.ErrorToastrTitle);
        }
      }
    });


  }

  onSubmit() {

    this.submitted = true;
    if (this.frmAboutMe.invalid) {
      return;
    }
    this.submitted = false;
    if (this.blogAboutMeCreateInputModel == null) {
      this.blogAboutMeCreateInputModel = new MyBlogAboutMeCreateUpdateInputModel;
    }

    this.blogAboutMeCreateInputModel.UserDetailsId = this.currentUser.UserDetailsId;
    this.blogAboutMeCreateInputModel.StayAnonymousYn = this.frmAboutMe.get('StayAnonymousYn').value;
    this.blogAboutMeCreateInputModel.AboutMeDescription = this.frmAboutMe.get('AboutMeDescription').value;


    this.loading = true;
    this.loadingScreenService.startLoading();
    let response: Observable<any>;
    this.quickpostlistService.CreateUpdateAboutMeData(this.blogAboutMeCreateInputModel)
      .subscribe(
        (result) => {
         
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            this._toastrService.Success(result.message);
          }
          else if (result.status == Status.RecordUpdated) {
            this._toastrService.Success(result.message);
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }

  EditQuickPostRead(data) {
   
    let queryParameter = {
      UserName: data?.UserName,
      GetMetaDataYN: false,
      UserQuickPostId: data?.UserQuickPostId,
      Profile: data?.ProfileImagePath,
      Name: data?.FullName
    }
    //this.queryParameter= this.encrDecr.set(Constant.EncrDecrKey, JSON.stringify(queryParameter))
    //  const activeTabIndex = this.activeTab;
    if (data) {
      this.createUpdateView(data?.UserQuickPostId);
    }
    setTimeout(() => {
      const url = this._router.createUrlTree(
        ['/diary'],
        { queryParams: { data: this._encryptDecryptService.set(Constant.EncrDecrKey, JSON.stringify(queryParameter)) } }
      ).toString();

      window.open(url, '_blank');
    }, 1000);
  }

  createUpdateView(data) {
    var input: UserQuickPostsViewsInputModel = {
      UserQuickPostsViewId: 0,
      UserQuickPostId: data,
      UserDetailsId: this.currentUser.UserDetailsId,
      CreatedDate: E.GetTodayDateStringOnly(),
      CreatedTime: E.GetTodayTimeStringOnly()
    }
    this.loading = true;
    this.loadingScreenService.startLoading();
    let response: Observable<any>;
    this.quickpostlistService.CreateUpdatePostView(input)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            this._toastrService.Success(result.message);
          }
          else if (result.status == Status.RecordUpdated) {
            this._toastrService.Success(result.message);
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }


  onViewAddressesMapClick(addressId) {
    let _zoomInViewAll = 3;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "69%";
    dialogConfig.position = { left: '18%' };
    dialogConfig.data = new MapAddressDataModel(MapModalType.QuickPostModel, [addressId]);
    const dialogRef = this.dialog.open(MapAddressComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {


      }
    );
  }

}
@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (value) {
      value = value.replace(/<br\s*\/?>/gi, ' '); // replace <br> with space
      value = value.replace(/<img[^>]*>/gi, '');
      value = value.trim().replace(/\s+/g, ' '); // remove extra whitespace
      if (value.length > limit) {
        value = value.substring(0, limit) + '...';
      }
    }
    return value;
  }
}
@Pipe({ name: 'truncate1' })
export class TruncatePipe1 implements PipeTransform {
  transform(value: string, limit: number): string {
    if (value) {
      // Replace line breaks and consecutive whitespaces with a space
      value = value.replace(/(\r\n|\n|\r|\s\s+)/gm, ' ');

      // Replace <br> tags with a space
      value = value.replace(/<br\s*\/?>/gi, ' ');

      value = value.replace(/<img[^>]*>/gi, '');

      // Replace <p> tags with a space
      value = value.replace(/<p[^>]*>/g, ' ').replace(/<\/p>/g, ' ');

      // Remove leading and trailing whitespaces
      value = value.trim();

      // Truncate if needed
      if (value.length > limit) {
        value = value.substring(0, limit) + '...';
      }
    }
    return value;
  }
}

@Pipe({ name: 'replaceUnderscore' })
export class ReplaceUnderscorePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    return value.replace(/_/g, ' ');
  }
}
@Pipe({ name: 'rotate' })
export class RotatePipe implements PipeTransform {
  transform(array: any[]): any {
    const currentDay = new Date().getDay(); // Get the current day (0: Sunday, 1: Monday, etc.)
    const index = currentDay % array.length;
    return array[index];
  }
}

@Pipe({
  name: 'removeHtmlWhitespace'
})
export class RemoveHtmlWhitespacePipe implements PipeTransform {
  transform(value: string): string {
    // Remove HTML tags using a regular expression
    const noHtmlTags = value.replace(/<[^>]*>/g, '');

    // Remove extra white spaces
    const trimmed = noHtmlTags.trim();
    const collapsedWhitespace = trimmed.replace(/\s+/g, ' ');

    return collapsedWhitespace;
  }
}

@Pipe({
  name: 'boldify'
})
export class BoldifyPipe implements PipeTransform {
  transform(value: string): string {
    // Wrap the replaced value in <strong> tags
    return `<strong>${value}</strong>`;
  }
}

@Pipe({
  name: 'jsonArrayFilter'
})
export class JsonArrayFilterPipe implements PipeTransform {
  transform(jsonString: string, filterKey: string): string[] {
    if (!jsonString || !filterKey) return [];

    try {
      const jsonArray = JSON.parse(jsonString);
      return jsonArray.filter(item => item[filterKey]).map(item => item.name);
    } catch (error) {
      //  console.error('Error parsing JSON:', error);
      return [];
    }
  }
}